import { REFUND_VERIFICATION_CHARGE } from '../../types/types';

export const startRefundVerificationCharge = payload => ({
    type: REFUND_VERIFICATION_CHARGE.SEND_REQUEST,
    payload,
    error: null
});


export const refundVerificationChargeSuccess = response => ({
    type: REFUND_VERIFICATION_CHARGE.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setRefundVerificationChargeSentStatus = isSent => ({
    type: REFUND_VERIFICATION_CHARGE.RESPONSE_STATUS,
    payload: isSent,
});


export const refundVerificationChargeFailure = error => ({
    type: REFUND_VERIFICATION_CHARGE.REQUEST_FAILURE,
    error
});