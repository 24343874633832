import { READ_E_STATEMENT } from "../../types/types";

const initialState = {
    loading: false,
    responseStatus: false,
    data: null,
    clicked_id: null,
    error: null
}

const readEStatementReducer = (state = initialState, action) => {
    switch (action.type) {
        case READ_E_STATEMENT.SEND_REQUEST:
            return {
                ...state,
                responseStatus: false,
                error: null,
                data: null,
                loading: true
            }


        case READ_E_STATEMENT.RESPONSE_RECEIVED:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
                responseStatus: true
            }

        case READ_E_STATEMENT.RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload
            }

        case READ_E_STATEMENT.CLICKED_ID:
            return {
                ...state,
                clicked_id: action.payload
            }

        case READ_E_STATEMENT.REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.error
            }

        default: return state;
    }
}

export default readEStatementReducer;