import React from 'react'
import CustomersComponent from '../../components/CustomersComponent/CustomersComponent'
// import CustomerTabView from '../../components/CustomersComponent/CustomerTabView'
import SideBarComponent from '../../components/SidebarComponent/SideBarComponent'

function CustomersPage() {
    return (
        <div style={{ display: 'flex' }}>
            <SideBarComponent />
            <CustomersComponent />
        </div>
    )
}

export default CustomersPage
