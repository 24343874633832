import React, { useState } from 'react'
import { CheckboxComponent } from '../SharedComponents/SharedComponent'
import { TableComponent } from './CustomersComponentElement'
import Moment from 'react-moment';
import { Link, PinIcon } from '../SharedComponents/SharedComponentElement';
import CurrencyFormat from 'react-currency-format';
import { PIN_TO_TOP } from '../../redux/types/types';


function CustomersTable({ addToCheckedList, checkedList, removeFromCheckedList, isCheckAll, customers }) {

    // console.log(checkedList.checked.size);
    const [values] = useState({
        message: '',
        notification: '',
        charCount: 0,
        charLimit: 160,
        messageLimit: 1,
        NotificationCharCount: 0,
        count: ''
    });


    values.charCount = values.message.length;
    if (values.charCount > values.charLimit) {
        values.count = Math.trunc(values.charCount / values.charLimit);

    } else {
        values.count = 1
    }
    values.notificationCharCount = values.notification.length;
    if (values.notificationCharCount > values.charLimit) {
        values.count = Math.trunc(values.charCount / values.charLimit);

    } else {
        values.count = 1
    }
    return (
        <TableComponent className="table">

            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Business Name</th>
                    <th scope="col">Account Created</th>
                    <th scope="col">Total Loan(GHS)</th>
                    <th scope="col">Loan Balance(GHS)</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>

                    {customers.pinned.size > 0 && (
                        <th scope="col"></th>
                    )}

                </tr>
            </thead>
            <tbody>


                {customers.data && customers.data.customers.data.map((item, index) => (

                    <tr key={item.customer_id}>
                        <td>
                            {/* setCheckedList(checkedList => value ? new Set(checkedList.add(index)) : new Set([...checkedList].filter(x => x !== index))) */}
                            <CheckboxComponent checked={isCheckAll} onChange={(value) => value ? addToCheckedList(index, PIN_TO_TOP.ADD_CHECK_CUSTOMER) : removeFromCheckedList(index, PIN_TO_TOP.REMOVE_CHECK_CUSTOMER)} />
                        </td>

                        <td>
                            <div className='ellipses-overflow'>
                                {item.company !== null ? item.company.name : "No company"}
                            </div>
                        </td>

                        <td>
                            <Moment format="D MMM YYYY, hh:mm">
                                {item.created_at}
                            </Moment>
                        </td>
                        <td>
                            {console.log()}
                            <CurrencyFormat decimalScale={2} fixedDecimalScale value={item.company !== null ? item.company.total_loans : "0.00"} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td>
                            <CurrencyFormat decimalScale={2} fixedDecimalScale value={item.company !== null ? item.company.loan_balance : "0.00"} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td>
                            <div className='ellipses-overflow'>
                                <span style={{ color: item.is_valid_id ? 'rgba(21,149,141,1)' : "rgb(247, 74, 101)" }}>
                                    {item.is_valid_id ? "Verified" : "Unverified"}
                                </span>
                            </div>
                        </td>
                        <td>
                            <span><Link to={`/customers/${item.customer_id}`}>View</Link></span>
                        </td>


                        {customers.pinned.size > 0 && (
                            <td >
                                {index === [...Array(customers.pinned.size).keys()][index] && (
                                    <PinIcon />
                                )}
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </TableComponent>
    )
};

export default CustomersTable
