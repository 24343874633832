import styled from 'styled-components';

export const CustomerDetailTabFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 30px;

    & div.customer-detail-tab-flex-child:first-child {
        width: 55%;
    }

    & div.customer-detail-tab-flex-child:last-child {
        width: 35%;
    }
`

export const TabLabel = styled.h4`
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    color: ${({ color }) => color};
`;


export const TabFlexWrapper = styled.div`
    &>div, &>section>div{
        border-bottom: 2px solid rgba(0,0,0,0.05);
        padding: 10px;
        color: rgb(113, 115, 147)
    }

    &>section{
      margin-bottom: 30px;
    }
`;


export const DataRowFlex = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  & > div.label {
    color: rgb(66, 69, 111);
    font-size: 14px;
    width: 30%;
    margin-right: 10%;
  }

  & > div.value {
    font-size: 14px;
    width: 70%;
    display: flex;
    justify-content: space-between;

    &>p{
      color: rgba(51,96,255,1);
      margin: 0;
      cursor: pointer;
      padding: 0;
    }
  }
`

export const Month = styled.p`
  margin: 0;
  padding: 0;
  color: rgba(146,147,172,1);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  margin-bottom: 25px;
`

export const CustomerDetailFlexChildRight = styled.div`
display: flex;
justify-content: space-around;

  &>div{
    color: blue;
    font-size: 13px;

  }
  
`;