import styled from 'styled-components';
import { IoTriangleSharp as Triangle } from 'react-icons/io5';


export const AdminHomeComponentWrapper = styled.div`
    background-color: rgb(247, 250, 254);
    padding: 20px 20px 10px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;


export const FilterComponent = styled.div`
    margin: 20px 0 20px 0;
    height: 30px;
    width: 190px;
    color: rgba(67,69,111,1);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;

`;

export const CardSectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;


// ADMIN SECTION CARD

export const CardComponentWrapper = styled.div`
    min-height: 130px;
    min-width: 30%;
    background-color: white;
    box-shadow: 0 0 2px -1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 25px 20px 25px;
`;

export const CardTitle = styled.h6`
    opacity: 0.7;
    font-size: 13px;
    font-weight: normal;
`;

export const CardContent = styled.h1`
    color: rgb(66, 69, 111);
    font-weight: bold;
    font-size: 22px;

`;

export const CardFooter = styled.div`
    opacity: 0.5;
    font-size: 10px;
`;


export const MetricTriangle = styled(Triangle)`
    /* color: red; */
    color: ${({ icon_color }) => (icon_color === 'red' ? "rgb(247, 74, 101)" : "rgb(71, 227, 216)")};
    font-size: 1.3em;
    margin-top: -3px;
    margin-right: 5px;
    transform: ${({ icon_color }) => (icon_color === 'red' && "rotate(180deg)")};
`;