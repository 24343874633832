import React from 'react'
import SideBarComponent from '../../components/SidebarComponent/SideBarComponent'
import UserManagementComponent from '../../components/UserManagementComponent/UserManagementComponent'

function UserManagementPage() {
    return (
        <div style={{ display: 'flex' }}>
            <SideBarComponent />
            <UserManagementComponent />
        </div>
    )
}

export default UserManagementPage
