
import ConnectivityException from '../../exceptions/ConnectivityException';
import TimeOutException from '../../exceptions/TimeOutException';
import FetchErrorException from '../../exceptions/FetchErrorException';
import { axiosInstance } from '../axios_config';




const readEstatementRequest = async (file_url) => {

    axiosInstance.interceptors.request.use(function (config) {
        const token = localStorage.getItem('token');
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
    });

    return axiosInstance.get(`/read-file?file_url=${file_url}`).then(res => {
        if (res.status === 200) {
            return res;
        }
    }).catch(error => {

        if (error.response) {
            console.log("RESPONSE ERROR ", error.response)
            throw new Error(error.response.data.error.description);
        } else if (error.request) {
            console.log("REQUEST ERROR");

            switch (error.toJSON().message) {
                case 'Network Error':
                    throw new ConnectivityException("please connect to an internet");
                case 'Request timeout':
                    throw new TimeOutException("please ensure you have a stable internet and try again");
                case 'Failed to fetch':
                    throw new FetchErrorException("your connection is weak");
                default:
                    throw new Error(error.toJSON().message);
            }

        } else {
            throw new Error(error.toJSON().message);
        }

    })
}

export { readEstatementRequest };