import { SEND_MAGIC_LINK } from '../../types/types';

export const startSendMagicLink = payload => ({
    type: SEND_MAGIC_LINK.SEND_REQUEST,
    payload,
    error: null
});


export const sendMagicLinkSuccess = response => ({
    type: SEND_MAGIC_LINK.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setSendMagicLinkSentStatus = isSent => ({
    type: SEND_MAGIC_LINK.RESPONSE_STATUS,
    payload: isSent,
});


export const sendMagicLinkFailure = error => ({
    type: SEND_MAGIC_LINK.REQUEST_FAILURE,
    error
});