export default function TimeOutException(message) {
    this.message = message;
    // Use V8's native method if available, otherwise fallback
    if ("captureStackTrace" in Error)
        Error.captureStackTrace(this, TimeOutException);
    else
        this.stack = (new Error()).stack;
}

TimeOutException.prototype = Object.create(Error.prototype);
TimeOutException.prototype.name = "TimeOutException";
TimeOutException.prototype.constructor = TimeOutException;