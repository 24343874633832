import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { LoanApplicationDetailContext } from '../../../Contexts/AppContext';
import { startApproveDisbursement } from '../../../redux/actions/loanApplications/approveDisbursementAction';
import { startGetLoanApplicationDetail } from '../../../redux/actions/loanApplications/loanApplicationDetailAction';
import { startReadEstatement } from '../../../redux/actions/loanApplications/readEstatementAction';
import NavHeaderComponent from '../../NavHeaderComponent/NavHeaderComponent'
import { ButtonComponent, CloseButton, MessageCardComponent, PageBgWrapperComponent, PageCardLargeComponent } from '../../SharedComponents/SharedComponent'
import { CreateMarginBottom, Link } from '../../SharedComponents/SharedComponentElement';
import { OpenBankStatementText } from '../LoanApplicationsElement';
import { DataRowFlex, Header, LoanApplicationDetailFlex, NorthEastArrow } from './LoanApplicationDetailElement'
import LoanDetailModal from './LoanDetailModal';

function LoanApplicationDetailComponent({ getLoanApplicationDetail, startGetLoanApplicationDetail, startApproveDisbursement, startReadEstatement }) {


    const [showLoanDetailsModal, setShowLoanDetailsModal] = useState(false);


    const handleCloseLoanDetailsModal = () => setShowLoanDetailsModal(false);
    const handleShowLoanDetailsModal = () => setShowLoanDetailsModal(true);


    const params = useContext(LoanApplicationDetailContext);

    const loanStatusColor = (status) => {
        if (status === 'pending') return null;
        if (status === 'approved') return 'rgb(16, 237, 104)';
        if (status === 'declined') return 'rgb(160, 45, 36)';
    }

    useEffect(() => {
        startGetLoanApplicationDetail(params.loan_id)
    }, [params.loan_id, startGetLoanApplicationDetail])


    const checkAccountType = (account, type) => {
        switch (account.type) {
            case 'card':
                return {
                    account_id: type === 'repayment' ? account.repayment_account_id : account.disbursement_account_id,
                    account_name: account.type_detail.name,
                    account_number: `**** **** **** ${account.type_detail.card_last_four}`,
                    provider: account.type_detail.card_brand.replace(/^\w/, c => c.toUpperCase()),
                    status: account.is_primary
                };
            case 'mobile-money':
                return {
                    account_id: type === 'repayment' ? account.repayment_account_id : account.disbursement_account_id,
                    account_name: account.type_detail.name,
                    account_number: account.type_detail.phone_number,
                    provider: account.type_detail.provider.replace(/^\w/, c => c.toUpperCase()),
                    status: account.is_primary,
                };
            default:
                return {
                    account_id: type === 'repayment' ? account.repayment_account_id : account.disbursement_account_id,
                    account_name: account.type_detail.name,
                    account_number: account.type_detail.account_number,
                    provider: account.type_detail.provider.replace(/^\w/, c => c.toUpperCase()),
                    status: account.is_primary,
                };
        }
    }

    return (
        <PageBgWrapperComponent>
            <NavHeaderComponent pageTitle={'Loan Applications'} />
            <PageCardLargeComponent>


                <LoanApplicationDetailFlex>

                    <div className='loan-detail-flex-child'>


                        {getLoanApplicationDetail.loading ? (<>
                            <>
                                <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                                    <Skeleton count={1} width={200} height={15} />
                                </SkeletonTheme>
                                <CreateMarginBottom marginBottom='35px' />


                                {[...Array(6)].map((_, i) =>
                                    <div key={`loading1-${i}`}>
                                        <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                                            <p>
                                                <Skeleton count={1} height={20} />
                                            </p>
                                        </SkeletonTheme>
                                    </div>
                                )}
                            </>
                        </>) : (<>

                            {getLoanApplicationDetail.error !== null ? (
                                <>
                                    ERROR
                                </>) : (<>

                                    <>
                                        {getLoanApplicationDetail.data && (<>


                                            <Header>
                                                <h6>
                                                    {getLoanApplicationDetail.data.loans[0].company.name}
                                                </h6>
                                                <NorthEastArrow onClick={handleShowLoanDetailsModal} />
                                            </Header>

                                            <DataRowFlex>
                                                <div className='label'>
                                                    Loan Amount
                                                </div>
                                                <div className='value'>
                                                    <CurrencyFormat decimalScale={2} fixedDecimalScale
                                                        value={getLoanApplicationDetail.data.loans[0].amount}
                                                        displayType={'text'} thousandSeparator={true} prefix='GHC ' />
                                                </div>
                                            </DataRowFlex>


                                            <DataRowFlex>
                                                <div className='label'>
                                                    Term
                                                </div>
                                                <div className='value'>
                                                    {getLoanApplicationDetail.data.loans[0].term}
                                                </div>
                                            </DataRowFlex>



                                            <DataRowFlex>
                                                <div className='label'>
                                                    Total Repayment
                                                </div>
                                                <div className='value'>
                                                    N/A
                                                </div>
                                            </DataRowFlex>



                                            <DataRowFlex>
                                                <div className='label'>
                                                    Date Created
                                                </div>
                                                <div className='value'>
                                                    <Moment format="D MMM YYYY, hh:mm">
                                                        {getLoanApplicationDetail.data.loans[0].created_at}
                                                    </Moment>
                                                </div>
                                            </DataRowFlex>


                                            <DataRowFlex>
                                                <div className='label'>
                                                    Status
                                                </div>
                                                <div className='value'>
                                                    <span style={{ color: loanStatusColor(getLoanApplicationDetail.data.loans[0].status) }}>
                                                        {getLoanApplicationDetail.data.loans[0].status}
                                                    </span>
                                                </div>
                                            </DataRowFlex>


                                            <DataRowFlex>
                                                <div className='label'>
                                                    E-Statement
                                                </div>
                                                <div className='value'>
                                                        <OpenBankStatementText onClick={() => { startReadEstatement(getLoanApplicationDetail.data.loans[0].statement_file_path) }}>statement.pdf</OpenBankStatementText>
                                                </div>
                                            </DataRowFlex>


                                            <DataRowFlex>
                                                <div className='label'>
                                                    Disbursement Account
                                                </div>
                                                <div className='value'>
                                                    {checkAccountType(getLoanApplicationDetail.data.loans[0].disbursement_account_id, 'disbursement').account_number} {checkAccountType(getLoanApplicationDetail.data.loans[0].disbursement_account_id, 'disbursement').provider}
                                                </div>
                                            </DataRowFlex>

                                            <DataRowFlex>
                                                <div className='label'>
                                                    Repayment Account
                                                </div>
                                                <div className='value'>
                                                    {checkAccountType(getLoanApplicationDetail.data.loans[0].repayment_account_id, 'disbursement').provider} {checkAccountType(getLoanApplicationDetail.data.loans[0].repayment_account_id, 'repayment').account_number}
                                                </div>
                                            </DataRowFlex>

                                            <DataRowFlex style={{ marginTop: '20px' }}>
                                                <div className='label'>
                                                    <ButtonComponent
                                                        type='submit'
                                                        width='200px' height='33px'
                                                        hoverColor={'rgb(31, 173, 163)'}
                                                        name='submit_btn' id='submit_btn'
                                                        marginTop='10px'
                                                        disabled={getLoanApplicationDetail.data && getLoanApplicationDetail.data.loans[0].status !== 'pending'}
                                                        onClick={() => startApproveDisbursement(getLoanApplicationDetail.data.loans[0].loan_id)}
                                                        bgColor={'rgb(63, 206, 197)'}>
                                                        Approve Disbursement
                                                    </ButtonComponent>
                                                </div>
                                                <div className='value'>
                                                    <ButtonComponent
                                                        type='submit'
                                                        width='130px' height='33px'
                                                        disabled={getLoanApplicationDetail.data && getLoanApplicationDetail.data.loans[0].status !== 'pending'}
                                                        hoverColor={'rgb(214, 77, 97)'}
                                                        name='submit_btn' id='submit_btn'
                                                        marginTop='10px'
                                                        onClick={() => console.log('Declined')}
                                                        bgColor={'rgb(247, 74, 101)'}>
                                                        Decline
                                                    </ButtonComponent>
                                                </div>
                                            </DataRowFlex>

                                        </>)}
                                    </>
                                </>)}
                        </>)}
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className='loan-detail-flex-child'>
                        <div className='upper-buttons-flex'>

                            {(!getLoanApplicationDetail.loading && getLoanApplicationDetail.error === null) && (
                                <>

                                    {getLoanApplicationDetail.data && getLoanApplicationDetail.data.loans[0].status === 'approved' ? (
                                        <MessageCardComponent messageType={'success'}>
                                            Loan disbursed
                                        </MessageCardComponent>
                                    ) : (
                                        <>
                                            {getLoanApplicationDetail.data && getLoanApplicationDetail.data.loans[0].status === 'declined' && (
                                                <MessageCardComponent messageType={'error'}>
                                                    Loan declined
                                                </MessageCardComponent>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                        </div>

                        <div className='message-section'>
                            <Link style={{ color: 'rgba(0,0,0,0.5)', position: 'relative', top: '-20px' }} to='/loan-applications' ><CloseButton /></Link>
                        </div>
                    </div>

                </LoanApplicationDetailFlex>

                <LoanDetailModal show={showLoanDetailsModal} handleClose={handleCloseLoanDetailsModal} loanData={getLoanApplicationDetail.data && getLoanApplicationDetail.data.loans[0]} />



            </PageCardLargeComponent>
        </PageBgWrapperComponent>
    )
}


const mapStateToProps = (state) => ({
    getLoanApplicationDetail: state.getLoanApplicationDetail,
});
const mapDispatchToProps = dispatch => ({
    startGetLoanApplicationDetail: (data) => dispatch(startGetLoanApplicationDetail(data)),
    startApproveDisbursement: (data) => dispatch(startApproveDisbursement(data)),
    startReadEstatement: (data) => dispatch(startReadEstatement(data)),

});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoanApplicationDetailComponent);

// export default LoanApplicationDetailComponent
