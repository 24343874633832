import { MANAGE_ADMINS_ACCOUNT } from '../../types/types';

export const startManageAdminsAccount = payload => ({
    type: MANAGE_ADMINS_ACCOUNT.SEND_REQUEST,
    payload,
    error: null
});


export const manageAdminsAccountSuccess = response => ({
    type: MANAGE_ADMINS_ACCOUNT.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setManageAdminsAccountSentStatus = isSent => ({
    type: MANAGE_ADMINS_ACCOUNT.RESPONSE_STATUS,
    payload: isSent,
});


export const manageAdminsAccountFailure = error => ({
    type: MANAGE_ADMINS_ACCOUNT.REQUEST_FAILURE,
    error
});