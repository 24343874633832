import React from 'react'
import { CheckboxComponent, InputFieldOutlineComponent } from '../../SharedComponents/SharedComponent';
import { ChevronDown, ChevronLeft, ChevronRight, UpperToolsSection } from '../SMSandNotificationElement';
import SMSandNotificationTable from '../SMSandNotificationTable';
import { IoChevronDownOutline as ArrowDown } from 'react-icons/io5';
import { MdRefresh as Refresh } from 'react-icons/md';

function SMSTab() {

    return (
        <div style={{ marginTop: '40px' }}>
            
            <UpperToolsSection>
                <div>
                    <div style={{ display: 'flex' }}>
                        <CheckboxComponent borderColor='#888' />
                        <ArrowDown style={{ fontSize: '13px', margin: '2px 4px' }} />
                    </div>
                    <Refresh onClick={()=>window.location.reload()} style={{ fontSize: '20px', margin: '0 15px', transform: 'scale(1, -1)' }} />
                </div>

                <div>
                    <InputFieldOutlineComponent width='100%' />
                </div>

                <div style={{ fontSize: '14px' }}>
                    <div><span>Rows per page</span></div>
                    <div>
                        <span style={{ color: 'rgb(35, 120, 250)', marginRight: '5px' }}>25</span>
                        <ChevronDown />
                    </div>

                    <div><span>1-25 of 100</span></div>
                    <div>
                        <span><ChevronLeft /></span>
                        <span><ChevronRight /></span>
                    </div>
                </div>
            </UpperToolsSection>

            <SMSandNotificationTable />
        </div>
    )
}

export default SMSTab
