import React from 'react'
import { CardContent, CardComponentWrapper, CardFooter, CardTitle } from './HomeComponentElement'

function CardComponent({ cardTitle, cardContent, cardFooter, metricIcon }) {
    return (
        <CardComponentWrapper>
            <CardTitle style={{color: 'rgba(67,69,111,1)'}}>{cardTitle}</CardTitle>
            <CardContent>{cardContent}</CardContent>
            <CardFooter> {metricIcon} {cardFooter}</CardFooter>
        </CardComponentWrapper>
    )
}

export default CardComponent
