import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { GET_CUSTOMER_DETAIL } from '../../types/types';
import { getCustomerDetailFailure, getCustomerDetailSuccess } from '../../actions/customers/getCustomerDetailAction';
import { getCustomerDetailRequest } from '../../../requests/customers/getCustomerDetailRequest';



function* getCustomerDetailSaga({payload}) {

    try {        
        const response = yield call(getCustomerDetailRequest, payload);
        console.log("CUSTOMER DETAIL DATA \n ", response.data.data);
        yield put(getCustomerDetailSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(getCustomerDetailFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(getCustomerDetailFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(getCustomerDetailFailure(error.message));
        }
        else {
            yield put(getCustomerDetailFailure(error.message));
        }
    }
}

export default function* watchGetCustomerDetailSagaSaga() {
    yield takeEvery(GET_CUSTOMER_DETAIL.SEND_REQUEST, getCustomerDetailSaga);
};