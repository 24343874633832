import { takeEvery, put, call, all } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { MANAGE_ADMINS_ACCOUNT } from '../../types/types';
import { manageAdminsAccountRequest } from '../../../requests/userManagement/manageAdminsAccountRequest';
import { manageAdminsAccountFailure, manageAdminsAccountSuccess } from '../../actions/userManagement/manageAdminsAccountAction';
import { toast } from 'react-toastify';



function* manageAdminsAccountSaga({ payload }) {
    try {
        let response;
        if (Array.isArray(payload.admin_id)) {
            if (payload.admin_id?.length)
                yield all(payload.admin_id.map(admin_id => call(manageAdminsAccountRequest, { data: payload.data, admin_id })));
            else
                throw new Error("Select a user to remove");
        } else {
            console.log(payload);
            response = yield call(manageAdminsAccountRequest, payload);
        }

        console.log("ADMIN MANAGE DATA \n ", response.data.data);
        yield put(manageAdminsAccountSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(manageAdminsAccountFailure({ message: error.message, position: toast.POSITION.TOP_RIGHT, message_type: 'warning' }));
        } else if (error instanceof TimeOutException) {
            yield put(manageAdminsAccountFailure({ message: error.message, position: toast.POSITION.TOP_RIGHT, message_type: 'warning' }));
        }
        else if (error instanceof FetchErrorException) {
            yield put(manageAdminsAccountFailure({ message: error.message, position: toast.POSITION.TOP_RIGHT, message_type: 'warning' }));
        }
        else {
            yield put(manageAdminsAccountFailure({ message: error.message, position: toast.POSITION.TOP_CENTER, message_type: 'error' }));
        }
    }
}

export default function* watchManageAdminsAccountSaga() {
    yield takeEvery(MANAGE_ADMINS_ACCOUNT.SEND_REQUEST, manageAdminsAccountSaga);
};