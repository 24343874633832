import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { loanStatsRequest } from '../../../requests/dashboard/loanStatsRequest';
import { LOAN_STATS } from '../../types/types';
import { getLoanStatsFailure, getLoanStatsSuccess } from '../../actions/dashboard/loanStatsAction';



function* getLoanStatsSaga() {
    try {
        const response = yield call(loanStatsRequest);
        console.log("LOAN STATS \n ", response.data.data);
        yield put(getLoanStatsSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(getLoanStatsFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(getLoanStatsFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(getLoanStatsFailure(error.message));
        }
        else {
            yield put(getLoanStatsFailure(error.message));
        }
    }
}

export default function* watchGetLoanStatsSaga() {
    yield takeEvery(LOAN_STATS.SEND_REQUEST, getLoanStatsSaga);
};