import { motion } from 'framer-motion'
import React from 'react'
import EmailVerify from '../../components/AdminAccountComponent/EmailVerify'

function VerifyEmailPage() {

    const pageTransition = {
        in: {
            opacity: 1,
            x: 0
        },

        out: {
            opacity: 0,
            x: "-100vw"
        }
    }
    return (
        <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
            <>
                <EmailVerify />
            </>

        </motion.div>

    )
}

export default VerifyEmailPage
