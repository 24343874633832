import styled from 'styled-components';
import { IoChevronForwardOutline, IoChevronBackOutline, IoChevronDownOutline, IoCalendarOutline, IoTimeOutline } from 'react-icons/io5';
import { RiPushpin2Line as Pin } from 'react-icons/ri'
import { Link as LinkRouter } from "react-router-dom";
import DatePicker from 'react-datepicker';
import { TimePicker } from 'antd';
import 'react-datepicker/dist/react-datepicker.css';



export const Link = styled(LinkRouter)`
  cursor: pointer;
  color: rgb(66, 121, 250);
  &:hover {
    transition: 0.2s ease-in-out;
  }
`


export const UpperToolsSection = styled.div`
    padding: 10px 15px 10px 15px;
    display: flex;
    justify-content: space-between;

    &>div{
        display: flex;
        justify-content: space-between;        
    }

    &>div:nth-child(2){
        width: 45%;      
        position: relative;
        top: -5px;
    }
    &>div:first-child{
        width: 10%;      
    }

    &>div:last-child{
        width: 35%;      
    }
`;


export const TabLabel = styled.h4`
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    color: rgb(46, 124, 249);
`;


// TABLE DESIGN
export const TableComponent = styled.table`
    width: 100%;
    /* table-layout: fixed; */

    & th{
        border: none !important;
        color: rgb(66, 69, 111);
        /* color: red; */
        padding-bottom: 20px;
        font-size: 15px;
    }

    & tr{
        font-size: 13.5px;
        color: rgb(114, 116, 147);

         div.ellipses-overflow{
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 250px;
        }
    }

    & tr > td:nth-child(6), & tr > td:nth-child(7){
        color: rgb(66, 121, 250);
        >span{
            cursor: pointer;

        }
    }

`;

export const ChevronRight = styled(IoChevronForwardOutline)`
  font-size: 15px;
  margin-left: 10px;
  color: rgb(35, 120, 250);
`;

export const PinIcon = styled(Pin)`
    color: #bbb;
    font-size: 16px;
`;

export const ChevronLeft = styled(IoChevronBackOutline)`
  font-size: 15px;
  color: rgb(35, 120, 250);
`;

export const ChevronDown = styled(IoChevronDownOutline)`
  font-size: 15px;
  color: rgb(35, 120, 250);
`;

export const BodyWrapper = styled.div`
margin-top: 30px;
margin-left: 25px;
margin-right: 25px;
`

export const MessageArea = styled.textarea`
  box-sizing: border-box;
  height: 130px;
  width: 650px;
  border-radius: 2px;
  background-color: rgba(255,255,255,1);
  border: 1px solid rgba(211,215,230,1);

  opacity: 0.7;
  color: rgba(67,69,111,1);
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
`

export const DataRowFlex = styled.div`
display: flex;
flex-direction: row;
white-space: nowrap;
margin-top: 15px;
justify-content: space-around;

& > div:first-child {
  color: rgb(66, 69, 111);
  font-size: 14px;
  width: 30%;
  background-color: cyan;
}

& > div:last-child {
  font-size: 14px;
  width: 60%;
  background-color: grey;
  display: flex;
  justify-content: space-between;

  &>p{
    color: rgba(51,96,255,1);
    margin: 0;
    cursor: pointer;
    padding: 0;
  }
}
`




export const Query = styled.p`
height: 16px;
  width: 164px;
  color: #43456F;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  float: left !important;
  margin-right: 30px;
  

`
export const MessageCount = styled.div`
 box-sizing: border-box;
  height: 50px;
  width: 650px;
  border-radius: 2px;
  background-color: rgba(241,244,255,1);
  border: 1px solid rgba(211,215,230,1);
  color: rgba(67,69,111,1);
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
`
export const MessageContainer = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 40px;
`

export const RadioLabel = styled.span`
  height: 16px;
  width: 58px;
  color: rgba(67,69,111,1);
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`
export const BoxDiv = styled.div`
  box-sizing: border-box;
  height: 40px;
  width: 220px;
  border-radius: 5px;
  background-color: rgba(255,255,255,1);
  border: 1px solid rgba(211,215,230,1);
  margin-right: 30px;
`

export const CalendarIcon = styled(IoCalendarOutline)`
  font-size: 20px;
  color: #c1c4cf;
  float: right;
  margin-top: 8px;
  margin-right: 10px;

`
export const TimeIcon = styled(IoTimeOutline)`
font-size: 20px;
color: #c1c4cf;
float: right;
margin-top: 8px;
margin-right: 10px;
`
export const PickTime = styled(TimePicker)`
float: left;
  height: 39px;
  padding-left: 10px;
  font-size: 13px !important;
  font-weight: 600;
  line-height: 16px;
  color: rgba(67,69,111,1);
  font-family: "Inter", sans-serif;
  border: none;
  border-bottom: 1px solid  rgba(211,215,230,1);;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.2);
  }
`

export const PickDate = styled(DatePicker)`
  float: left;
  height: 39px;
  padding-left: 10px;
  font-size: 13px !important;
  font-weight: 600;
  line-height: 16px;
  color: rgba(67,69,111,1);
  font-family: "Inter", sans-serif;
  border: none;
  border-bottom: 1px solid  rgba(211,215,230,1);;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.2);
}
`

export const SelectedRecipients = styled.div`
height: 40px;
width: 150px;
border-radius: 3px;
  background-color: rgba(241,244,255,1);
  border: 1px solid rgba(211,215,230,1);
  color: rgba(67,69,111,1);
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 10px 10px 10px;
  text-align: center;


`