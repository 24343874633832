import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { RETRY_COLLECTION } from '../../types/types';
import { retryCollectionRequest } from '../../../requests/customers/retryCollectionRequest';
import { retryCollectionFailure, retryCollectionSuccess } from '../../actions/customers/retryCollectionAction';



function* retryCollectionSaga({payload}) {
    
    try {        
        const response = yield call(retryCollectionRequest, payload);
        // console.log("CUSTOMERS ALL DATA \n ", response.data.data);
        yield put(retryCollectionSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(retryCollectionFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(retryCollectionFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(retryCollectionFailure(error.message));
        }
        else {
            yield put(retryCollectionFailure(error.message));
        }
    }
}

export default function* watchRetryCollectionSaga() {
    yield takeEvery(RETRY_COLLECTION.SEND_REQUEST, retryCollectionSaga);
};