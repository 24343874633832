import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import { sendMagicLinkRequest } from '../../../requests/magicLinkRequest';
import { sendMagicLinkFailure, sendMagicLinkSuccess } from '../../actions/auth/magicLinkAction';
import { SEND_MAGIC_LINK } from '../../types/types';



function* sendMagicLinkSaga({ payload }) {
    // console.log({...payload});

    try {
        const response = yield call(sendMagicLinkRequest, {...payload});
        console.log("MAGIC LINK RESPONSE: ", response.data.data);
        yield put(sendMagicLinkSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            // console.log(error.message);
            yield put(sendMagicLinkFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(sendMagicLinkFailure(error.message));
        }
        else {
            yield put(sendMagicLinkFailure(error.message));
        }
    }
}

export default function* watchSendMagicLink() {
    yield takeEvery(SEND_MAGIC_LINK.SEND_REQUEST, sendMagicLinkSaga);
};