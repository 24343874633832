import React, { useEffect, useRef, useState } from 'react'
import NavHeaderComponent from '../NavHeaderComponent/NavHeaderComponent';
import { IoChevronDownOutline as ArrowDown } from 'react-icons/io5';
import { MdRefresh as Refresh } from 'react-icons/md';
import { CheckboxComponent, FormikErrorComponent, InputFieldComponent, InputFieldOutlineComponent, notifyToast, PageBgWrapperComponent, PageCardLargeComponent } from '../SharedComponents/SharedComponent'
import { AddUserIcon, RemoveUserIcon, RightIcons, UpperToolsSection, UserManagementContainer } from './UserManagementElement'
import UserManagementTable from './UserManagementTable';
import { connect } from 'react-redux';
import { startGetAllAdmins } from '../../redux/actions/userManagement/getAllAdminsAction';
import TableLoadingComponent from '../SharedComponents/TableLoadingComponent';
import connError from '../../assets/no-conn.png'
import { CreateMarginBottom, ErrorDisplay, GoNext, GoPrev, RetryLoading } from '../SharedComponents/SharedComponentElement';
import CustomModalComponent from '../SharedComponents/Modal/ModalComponent';
import { startManageAdminsAccount } from '../../redux/actions/userManagement/manageAdminsAccountAction';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import CustomDropDownComponent from '../SharedComponents/DropDownComponent/CustomDropDownComponent';
import { addToCheckedList, clearCheckedList, removeFromCheckedList } from '../../redux/actions/pinToTopAction/pinToTopAction';
import { PIN_TO_TOP } from '../../redux/types/types';




toast.configure();
function UserManagementComponent({ startGetAllAdmins, getAllAdmins, manageAdminsAccount, startManageAdminsAccount, ...rest }) {
    const formRef = useRef();
    // const [checkedList, setCheckedList] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);


    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [removeUserModal, setRemoveUserModal] = useState(false);

    // console.log(checkedList);




    const handleCloseAddUserModal = () => setShowAddUserModal(false);
    const handleShowAddUserModal = () => setShowAddUserModal(true);

    const handleCloseRemoveUserModal = () => setRemoveUserModal(false);
    const handleRemoveUserModal = () => setRemoveUserModal(true);

    const addUserHandler = () => {
        formRef.current.submitForm();
    }


    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Please enter a valid email'),
    });


    useEffect(() => {
        if (manageAdminsAccount.error !== null) {
            notifyToast(manageAdminsAccount.error);
        }
    }, [manageAdminsAccount.error]);

    useEffect(() => {
        startGetAllAdmins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const options = [
        { value: "admin", label: "Admin" },
        { value: "super-admin", label: "Super-Admin" },
    ];


    const selectAll = (value) => {
        if (value) {
            setIsCheckAll(true);
            getAllAdmins.data.admins.data.forEach((_, index) => {
                rest.addToCheckedList(index, PIN_TO_TOP.ADD_CHECK_USER)
            })
        } else {
            setIsCheckAll(false);
            rest.clearCheckedList(PIN_TO_TOP.CLEAR_CHECK_USER);
        }
    }


    const removeSelectedUsers = () => {
        // console.log(rest.pinToTopList.checked)
        rest.pinToTopList.checked.forEach((index)=>{
            
            console.log(getAllAdmins.data.admins.data[index])
        });
        // startManageAdminsAccount({ data: 0, admin_id: checkedList })
    }

    return (
        <PageBgWrapperComponent>
            <NavHeaderComponent pageTitle='User Management' />

            <PageCardLargeComponent>
                <UpperToolsSection>
                    <div>
                        <div style={{ display: 'flex' }}>
                            {/* Array.from([1, 2, 3], x => x + x) */}
                            <CheckboxComponent onChange={(value) => selectAll(value)} borderColor='#888' />
                            <ArrowDown style={{ fontSize: '13px', margin: '2px 4px' }} />
                        </div>
                        <Refresh onClick={() => window.location.reload()} style={{ fontSize: '20px', margin: '0 15px', transform: 'scale(1, -1)' }} />
                    </div>

                    <div>
                        <InputFieldOutlineComponent width='100%' />
                    </div>
                    <RightIcons>
                        <UserManagementContainer onClick={handleShowAddUserModal}>
                            <AddUserIcon />
                            Add
                        </UserManagementContainer>

                        <UserManagementContainer style={{ color: 'rgba(67,69,111,1)', marginRight: '50px' }} onClick={handleRemoveUserModal}>
                            <RemoveUserIcon />
                            Remove
                        </UserManagementContainer>

                        <div><span>1-{getAllAdmins.data && getAllAdmins.data.admins.data.length} of {getAllAdmins.data && getAllAdmins.data.admins.meta.total}</span></div>

                        <div style={{ marginLeft: '20px' }}>
                            <span>
                                {getAllAdmins.data && getAllAdmins.data.admins.meta.previous ? (
                                    <GoPrev onClick={() => getAllAdmins.data && startGetAllAdmins({ previous: getAllAdmins.data.admins.meta.previous })} />
                                ) : (
                                    <GoPrev style={{ color: '#ddd', pointerEvents: 'none' }} />
                                )}
                            </span>
                            <span>
                                {getAllAdmins.data && getAllAdmins.data.admins.meta.next ? (
                                    <GoNext onClick={() => getAllAdmins.data && startGetAllAdmins({ next: getAllAdmins.data.admins.meta.next })} />
                                ) : (
                                    <GoNext style={{ color: '#ddd', pointerEvents: 'none' }} />
                                )}
                            </span>


                        </div>
                    </RightIcons>

                </UpperToolsSection>


                {(getAllAdmins.loading) ? (
                    <>
                        <TableLoadingComponent />
                    </>) : (
                    <>
                        {getAllAdmins.error !== null ? (
                            <>
                                <ErrorDisplay>
                                    <div>
                                        <img src={connError} alt="Error" />
                                        <h1>{getAllAdmins.error}</h1>
                                        <RetryLoading onClick={() => startGetAllAdmins()}>Retry</RetryLoading>
                                    </div>
                                </ErrorDisplay>
                            </>) : (
                            <>
                                {/* {console.log(rest.pinToTopList.checked)} */}
                                <UserManagementTable checkedList={rest.pinToTopList} isCheckAll={isCheckAll} addToCheckedList={rest.addToCheckedList} removeFromCheckedList={rest.removeFromCheckedList} admins={getAllAdmins} />
                            </>)}
                    </>)}

            </PageCardLargeComponent>



            <Formik
                innerRef={formRef}
                validateOnChange
                initialValues={{ email: '', role: options[0].value }}
                onSubmit={
                    data => {
                        console.log("hello")
                        // const newData = { ...data };
                        // if (newData.email === "") delete newData.email;
                        // console.log(checkedList);
                        // startManageAdminsAccount({ data: newData, admin_id: selectedUser });
                    }}
                validationSchema={validationSchema}>
                {formik => (
                    <>
                        <CustomModalComponent
                            actionHandler={addUserHandler}
                            show={showAddUserModal} handleClose={handleCloseAddUserModal}
                            actionBtnText="Add"
                            titleText={"Add user"}
                            isLoading={manageAdminsAccount.loading}
                        >

                            <div style={{ width: '70%' }}>
                                <Form>
                                    <Field name='email' id='email' onBlur={formik.handleBlur} type='email' as={InputFieldComponent}
                                        bgColor={'white'}
                                        height={'40px'}
                                        placeholder={'Email address'}
                                        border={"1.5px solid rgb(233, 235, 243)"}
                                        padding={"0 0 0 15px"}
                                        fontSize={'13px'}
                                        placeholderColor={'rgba(0,0,0,0.4)'}
                                        color={"rgb(76, 79, 114)"} />
                                    {(formik.errors.email && formik.touched['email']) && (<FormikErrorComponent style={{ marginTop: '6px' }}>{formik.errors.email}</FormikErrorComponent>)}

                                    <CreateMarginBottom marginBottom={'25px'} />

                                    <Field name='role'
                                        placeholder={'Select role'}
                                        value={options ? options.find(option => option.value === formik.values.role) : ''}
                                        onChange={value => formik.setFieldValue('role', value.value)}
                                        options={options}
                                        onBlur={(e) => { formik.setFieldTouched('role'); formik.handleBlur(e) }}
                                        as={CustomDropDownComponent} />

                                </Form>
                            </div>
                        </CustomModalComponent>
                    </>
                )}</Formik>




            <CustomModalComponent
                show={removeUserModal} handleClose={handleCloseRemoveUserModal}
                actionBtnText="Yes"
                actionHandler={() => removeSelectedUsers()}
                footermargintop='0'
                isLoading={manageAdminsAccount.loading}
                titleText={(isCheckAll && rest.pinToTopList.checked.size === (getAllAdmins.data && getAllAdmins.data.admins.data.length)) ? "This action cannot be reversed, are your sure you want to remove all users?" :
                    (rest.pinToTopList.checked.size > 0 && rest.pinToTopList.checked.size > 1) ? "Are you sure you want to remove these users?" : "Are you sure you want to remove this user?"} />

        </PageBgWrapperComponent>
    )
}



const mapStateToProps = (state) => ({
    getAllAdmins: state.getAllAdmins,
    manageAdminsAccount: state.manageAdminsAccount,
    pinToTopList: state.pinToTopList.pinUsers
});

const mapDispatchToProps = dispatch => ({
    startGetAllAdmins: (data) => dispatch(startGetAllAdmins(data)),
    startManageAdminsAccount: (data) => dispatch(startManageAdminsAccount(data)),
    addToCheckedList: (data, type) => dispatch(addToCheckedList(data, type)),
    removeFromCheckedList: (data, type) => dispatch(removeFromCheckedList(data, type)),
    clearCheckedList: (type) => dispatch(clearCheckedList(type)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserManagementComponent);



// export default UserManagementComponent
