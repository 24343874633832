import React from 'react'
import SideBarComponent from '../../components/SidebarComponent/SideBarComponent'
import NewMessageComponent from '../../components/SMSandNotificationComponent/NewMessageComponent/NewMessageComponent'

function NewMessagePage() {
    return (
        <div style={{ display: 'flex' }}>
            <SideBarComponent />
            <NewMessageComponent />
        </div>
    )
}

export default NewMessagePage
