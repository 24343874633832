
import axios from "axios";
import axiosRetry from "axios-retry";
import localforage from 'localforage'
import memoryDriver from 'localforage-memoryStorageDriver'
import { setup } from 'axios-cache-adapter'


export const forageCacheStore = localforage.createInstance({
    driver: [
        localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
        memoryDriver._driver
    ],
    name: 'cl-cache'
});

export const BASE_URL = "https://creditlocus-apigateway-stag.herokuapp.com/api/v1"


// `async` wrapper to configure `localforage` and instantiate `axios` with `axios-cache-adapter`

export async function configure() {
    // Register the custom `memoryDriver` to `localforage`
    await localforage.defineDriver(memoryDriver)

    // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
    return setup({
        // `axios` options
        baseURL: BASE_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',

        },
        // retryTimes: 3,
        cache: {
            maxAge: 15 * 60 * 1000,
            store: forageCacheStore,
            exclude: { query: false }
        }
    })
}




export const axiosInstanceWithToken = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});
axiosRetry(axiosInstanceWithToken, { retries: 3, retryCondition: axiosRetry.isRetryableError });


export const axiosInstanceForFormWithToken = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
    }
});



export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        'cache-control': "no-cache, private",
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    }
});

axiosRetry(axiosInstance, { retries: 3, retryCondition: axiosRetry.isRetryableError });


