import { GET_ALL_LOANS } from '../../types/types';

export const startGetAllLoans = payload => ({
    type: GET_ALL_LOANS.SEND_REQUEST,
    payload,
    error: null
});


export const getAllLoansSuccess = response => ({
    type: GET_ALL_LOANS.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setGetAllLoansSentStatus = isSent => ({
    type: GET_ALL_LOANS.RESPONSE_STATUS,
    payload: isSent,
});


export const getAllLoansFailure = error => ({
    type: GET_ALL_LOANS.REQUEST_FAILURE,
    error
});