import styled from 'styled-components';
import { IoChevronDownOutline, IoChatboxOutline, IoNotificationsOutline, IoCloseOutline } from 'react-icons/io5';
import { RiPushpin2Line as Pin } from 'react-icons/ri';
import Modal from 'react-modal';

export const UpperToolsSection = styled.div`
    padding: 20px 15px 10px 15px;
    display: flex;
    justify-content: space-between;

    &>div{
        display: flex;
        justify-content: space-between;        
    }

    &>div:nth-child(2){
        width: 45%;      
        position: relative;
        top: -5px;
    }
    &>div:first-child{
        width: 10%;      
    }

    &>div:last-child{
        width: 35%;      
    }
`;


// TABLE DESIGN
export const TableComponent = styled.table`
    width: 100%;

    & th{
        border: none !important;
        color: rgb(66, 69, 111);
        font-size: 15px;
    }

    & tr{
        font-size: 13.5px;
        color: rgb(114, 116, 147);

         div.ellipses-overflow{
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100px;
        }
    }

    & tr > td:nth-child(6), & tr > td:nth-child(7){
        color: rgb(66, 121, 250);
        >span{
            cursor: pointer;

        }
    }

`;


export const PinIcon = styled(Pin)`
    color: #bbb;
    font-size: 16px;
`;



export const ChevronDown = styled(IoChevronDownOutline)`
  font-size: 15px;
  cursor: pointer;
  color: rgb(35, 120, 250);
`;


export const MessageDiv = styled.div`
  height: 100px;
  overflow-wrap: break-word;
  width: 630px;
  color: rgba(67,69,111,1);
  font-family: "Inter Bold", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px
`;


export const RightIcons = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`

export const SendMessageContainer = styled.div`
  height: 14px;
  float: left;
  color: rgba(51,96,255,1);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
`
export const MessageIcon = styled(IoChatboxOutline)`
height: 15px;
  width: 15px;
  color: rgba(51,96,255,1);
  cursor: pointer;
  margin-right: 5px;
`
export const BellIcon = styled(IoNotificationsOutline)`
height: 17px;
  width: 15px;
  color: rgba(51,96,255,1);
  cursor: pointer;
  margin-right: 2px;

`
export const SendNotificationContainer = styled.div`
height: 14px;
  float: right;
  color: rgba(51,96,255,1);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  margin-left: 50px;
`

export const SendMessageModal = styled(Modal)`
  position: absolute;
  padding: 30px 20px 0 40px;
    top: 125px;
    left: 516px;
    right: 239px;
    bottom: 250px;
    border-radius: 10px;
    border: 1px solid rgba(211,215,230,1);
    background-color: #FFF;
`



export const MessageArea = styled.textarea`
  box-sizing: border-box;
  height: 100px;
  width: 450px;
  border-radius: 2px;
  background-color: rgba(255,255,255,1);
  border: 1px solid rgba(211,215,230,1);
  margin-top: 25px;
  opacity: 0.7;
  color: rgba(67,69,111,1);
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
`
export const MessageCount = styled.div`
 box-sizing: border-box;
  height: 30px;
  width: 450px;
  margin-bottom: 20px;
  border-radius: 2px;
  background-color: rgba(241,244,255,1);
  border: 0px solid rgba(211,215,230,1);
  color: rgba(67,69,111,1);
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
`
export const ModalContainer = styled.div`
display: flex;
flex-direction: column;
margin: 20px 0 20px 20px;
`


export const CloseIcon = styled(IoCloseOutline)`
  float: right;
  height: 20px;
  width: 20px;
  cursor: pointer;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  
  
  &>div{
    margin-right: 15px;
  }

`