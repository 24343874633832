import React, { useContext } from 'react'
import { SMSDetailsContext } from '../../../Contexts/AppContext';
import NavHeaderComponent from '../../NavHeaderComponent/NavHeaderComponent';
import { PageBgWrapperComponent, PageCardLargeComponent } from '../../SharedComponents/SharedComponent';
import { DataRowFlex, MessageLabel } from '../NewMessageComponent/NewMessageComponentElement';

function SMSDetails() {
    const params = useContext(SMSDetailsContext);
    console.log(params);

    return (
        // <div>
        //     hello word
        // </div>
        <PageBgWrapperComponent>
            <NavHeaderComponent pageTitle={'View Message'} />

            <PageCardLargeComponent>
                <div style={{paddingBottom:'5%'}}>
                    <DataRowFlex>
                        <div>
                            <MessageLabel>Sender</MessageLabel>
                        </div>

                        <div>
                            hello@creditlocus.com
                        </div>
                    </DataRowFlex>


                    <DataRowFlex>
                        <div>
                            <MessageLabel>Date</MessageLabel>
                        </div>

                        <div>
                            03 May 2021, 15:34
                        </div>
                    </DataRowFlex>


                    <DataRowFlex>
                        <div>
                            <MessageLabel>Message</MessageLabel>
                        </div>

                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam libero sem, fermentum a consequat sit amet, tincidunt quis metus. Suspendisse potenti. Morbi ex dui, faucibus eget arcu eget, interdum pharetra magna. Morbi non magna facilisis, cursus orci eget, tempor lorem. Pellentesque habitant morbi tristique senectus et
                        </div>
                    </DataRowFlex>


                    <DataRowFlex>
                        <div>
                            <MessageLabel>Recipients</MessageLabel>
                        </div>

                        <div>
                            Group: Loans above 10k
                        </div>
                    </DataRowFlex>


                    <DataRowFlex>
                        <div>
                            <MessageLabel>Sent</MessageLabel>
                        </div>

                        <div>
                            120
                        </div>
                    </DataRowFlex>


                    <DataRowFlex>
                        <div>
                            <MessageLabel>Delivered</MessageLabel>
                        </div>

                        <div>
                            108
                        </div>
                    </DataRowFlex>
                </div>

            </PageCardLargeComponent>

        </PageBgWrapperComponent>
    )
}

export default SMSDetails
