import React from 'react'
import SideBarComponent from '../../components/SidebarComponent/SideBarComponent'
import SMSandNotificationComponent from '../../components/SMSandNotificationComponent/SMSandNotificationComponent'

function SMSandNotificationPage() {
    return (
        <div style={{ display: 'flex' }}>
            <SideBarComponent />
            <SMSandNotificationComponent />
        </div>
    )
}

export default SMSandNotificationPage
