import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { LOAN_APPLICATIONS_DETAIL } from '../../types/types';
import { getLoanApplicationDetailFailure, getLoanApplicationDetailSuccess } from '../../actions/loanApplications/loanApplicationDetailAction';
import { getLoanApplicationDetailRequest } from '../../../requests/loanApplications/getLoanApplicationDetail';



function* getLoanApplicationDetailSaga({ payload }) {
    try {
        const response = yield call(getLoanApplicationDetailRequest, payload);
        console.log("LOAN DETAIL DATA \n ", response.data.data);

        yield put(getLoanApplicationDetailSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(getLoanApplicationDetailFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(getLoanApplicationDetailFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(getLoanApplicationDetailFailure(error.message));
        }
        else {
            yield put(getLoanApplicationDetailFailure(error.message));
        }
    }
}

export default function* watchGetLoanApplicationDetailSaga() {
    yield takeEvery(LOAN_APPLICATIONS_DETAIL.SEND_REQUEST, getLoanApplicationDetailSaga);
};