import ConnectivityException from '../exceptions/ConnectivityException';
import FetchErrorException from '../exceptions/FetchErrorException';
import TimeOutException from '../exceptions/TimeOutException';
import { axiosInstance } from './axios_config';



const sendMagicLinkRequest = async (requestData) => {
    
    return axiosInstance.get(`/admin/login?email=${requestData.email}&callback_url=${requestData.app_base_url}/dashboard`).then(res => {
        if (res.status === 200) {
            return res;
        }
    }).catch(error => {

        if (error.response) {
            console.log("RESPONSE ERROR")
            switch (error.response.data.error.title) {
                case 'ValidationException':
                    throw new Error(error.response.data.error.detail[0].message);
                default:
                    throw new Error(error.response.data.error.description);
            }
        } else if (error.request) {
            switch (error.toJSON().message) {
                case 'Network Error':
                    throw new ConnectivityException("please connect to an internet");
                case 'Request timeout':
                    throw new TimeOutException("please ensure you have a stable internet and try again");
                case 'Failed to fetch':
                    throw new FetchErrorException("your connection is weak");
                default:
                    throw new Error(error.toJSON().message);
            }

        } else {
            throw new Error(error.toJSON().message);
        }

    })
}

export { sendMagicLinkRequest };