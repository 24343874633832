import { GET_CUSTOMER_DETAIL } from '../../types/types';

export const startGetCustomerDetail = payload => ({
    type: GET_CUSTOMER_DETAIL.SEND_REQUEST,
    payload,
    error: null
});


export const getCustomerDetailSuccess = response => ({
    type: GET_CUSTOMER_DETAIL.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setGetCustomerDetailSentStatus = isSent => ({
    type: GET_CUSTOMER_DETAIL.RESPONSE_STATUS,
    payload: isSent,
});


export const getCustomerDetailFailure = error => ({
    type: GET_CUSTOMER_DETAIL.REQUEST_FAILURE,
    error
});