

export default function nearest(breakdowns, target) {
    // console.log("BREAKDOWNS ",breakdowns);
    if (!target) target = Date.now();
    else if (target instanceof Date) target = target.getTime();


    var nearest = Infinity;
    var nextNearest = -1;

    const datesFuture = breakdowns && breakdowns.filter(breakdown => (
        (new Date(target) < new Date(breakdown.date)) || sameDay(new Date(target), new Date(breakdown.date))
    ));

    if (datesFuture === undefined || datesFuture.length === 0) {
        return -1;
    }

    datesFuture.forEach(function (date, index) {
        let indexDate = date;

        if (sameDay(new Date(target), new Date(date.date))) {
            nextNearest = date;
        } else {
            if (new Date(date.date) instanceof Date) date = new Date(date.date).getTime();
            var distance = Math.abs(date - target);
            if (distance < nearest) {
                nearest = distance;
                nextNearest = indexDate;
            }
        }
    });
    return nextNearest;
}


function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}





