import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { connect } from 'react-redux';
import { CreateMarginBottom } from '../../SharedComponents/SharedComponentElement';
import { DataRowFlex, TabFlexWrapper } from '../CustomerDetail/CustomerDetailElement'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function PersonalDetailsTab({ getCustomerDetail }) {



    return (

        <>
            {getCustomerDetail.loading ? (<>
                <>
                    {[...Array(6)].map((x, i) =>
                        <div key={`loading1-${i}`}>
                            <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                                <p>
                                    <Skeleton count={1} height={25} />
                                </p>
                            </SkeletonTheme>
                            <CreateMarginBottom marginBottom='15px' />
                        </div>
                    )}
                </>
            </>) : (<>

                {getCustomerDetail.error !== null ? (<>

                    ERROR

                </>) : (<>

                    <TabFlexWrapper>

                        {getCustomerDetail.data && (<>


                            <DataRowFlex>
                                <div className='label'>
                                    First name
                                </div>
                                <div className='value'>
                                    {getCustomerDetail.data.customers[0].first_name}
                                </div>
                            </DataRowFlex>
                            <DataRowFlex>
                                <div className='label'>
                                    Last name
                                </div>
                                <div className='value'>
                                    {getCustomerDetail.data.customers[0].last_name}
                                </div>
                            </DataRowFlex>


                            <DataRowFlex>
                                <div className='label'>
                                    ID Type
                                </div>
                                <div className='value'>
                                    {getCustomerDetail.data.customers[0].identification_type.type}
                                </div>
                            </DataRowFlex>


                            <DataRowFlex>
                                <div className='label'>
                                    ID Number
                                </div>
                                <div className='value'>
                                    {getCustomerDetail.data.customers[0].identification_type.id_number}
                                </div>
                            </DataRowFlex>



                            <DataRowFlex>
                                <div className='label'>
                                    Phone number
                                </div>
                                <div className='value'>
                                    {getCustomerDetail.data.customers[0].phone_number}
                                </div>
                            </DataRowFlex>
                            <DataRowFlex>
                                <div className='label'>
                                    Gender
                                </div>
                                <div className='value'>
                                    {capitalizeFirstLetter(getCustomerDetail.data.customers[0].gender)}
                                </div>
                            </DataRowFlex>
                            <DataRowFlex>
                                <div className='label'>
                                    Verification status
                                </div>
                                <div className='value' style={{ color: 'rgba(255,51,102,1)' }}>
                                    <span style={{ color: getCustomerDetail.data.customers[0].is_valid_id ? 'rgba(21,149,141,1)' : "rgb(247, 74, 101)" }}>
                                        {getCustomerDetail.data.customers[0].is_valid_id ? "Verified" : "Unverified"}
                                    </span>
                                </div>
                            </DataRowFlex>

                        </>)}

                    </TabFlexWrapper>
                </>)}
            </>)}
        </>


    )
}

const mapStateToProps = (state) => ({
    getCustomerDetail: state.getCustomerDetail,
});


export default connect(
    mapStateToProps,
    null
)(PersonalDetailsTab);
// export default PersonalDetailsTab
