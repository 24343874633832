import React from 'react'
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async';
import { SelectDropdownIcon } from './DropDownElements';



export function CustomDropDownComponent({
    id,
    options,
    name,
    isAsync,
    value,
    placeholder = 'Select...',
    onChange,
    onBlur,
    isSearchable = false,
    menuStyles,
    controlStyles,
    defaultValue,
    singleValueStyles,
    optionStyles,
    valueContainerStyles,
    placeholderStyles,
    indicatorsContainerStyles,
    containerStyles,
    dropdownIndicatorStyles,
    clearIndicatorStyles,
    inputStyles,
    indicatorSeparatorStyles,
    height,
    width = 150,
    icon_color,
    containerBorder = "1.5px solid rgb(233, 235, 243)",
    dataFontSize = 11.5,
    //Alter this when you alter height
    valueContainerMarginTop,
    loadOptions,
    defaultOptions = true,
    noOptionsMessage,
    isDisabled,
    getOptionValue,
    iconContainerPadding = 8,
    getOptionLabel,
    key,
    isClearable,
    cacheOptions = true,
    textColor = "rgb(76, 79, 114)",
    menuListStyles
}) {

    const selectComponents = {
        async: AsyncSelect,
        normal: Select
    };
    const SelectDropDown = selectComponents[isAsync ? 'async' : 'normal'];

    const checkAsync = isAsync ? {
        key: key,
        defaultOptions: defaultOptions,
        cacheOptions: cacheOptions,
        getOptionLabel: getOptionLabel,
        getOptionValue: getOptionValue,
        loadOptions: loadOptions,
        noOptionsMessage: () => noOptionsMessage
    } : {
        options: options
    };

    return (
        <SelectDropDown

            isDisabled={isDisabled}
            isClearable={isClearable}
            defaultValue={defaultValue}
            isSearchable={isAsync ? !isSearchable : isSearchable}
            placeholder={placeholder}
            name={name}
            onBlur={onBlur}
            id={id}
            value={value}
            onChange={isAsync ? value => onChange(value) : onChange}

            styles={{
                clearIndicator: typeof clearIndicatorStyles === 'function' ? clearIndicatorStyles : (provided, _) => ({ ...provided, ...clearIndicatorStyles }),
                dropdownIndicator: typeof dropdownIndicatorStyles === 'function' ? dropdownIndicatorStyles : (provided, _) => ({ ...provided, ...dropdownIndicatorStyles, padding: iconContainerPadding }),
                control: typeof controlStyles === 'function' ? controlStyles : (provided, _) => ({ ...provided, backgroundColor: "white", boxShadow: 'none', border: containerBorder, width: width && width, minHeight: height && height, height: height && height, ...controlStyles }),
                input: typeof inputStyles === 'function' ? inputStyles : (provided, _) => ({ ...provided, ...inputStyles }),
                container: typeof containerStyles === 'function' ? containerStyles : (provided, _) => ({ ...provided, ...containerStyles, }),
                singleValue: typeof singleValueStyles === 'function' ? singleValueStyles : (provided, _) => ({ ...provided, fontSize: dataFontSize, color: textColor, ...singleValueStyles }),
                option: typeof optionStyles === 'function' ? optionStyles : (provided, state) => ({ ...provided, fontSize: dataFontSize, padding: 12, color: state.isSelected && 'inherit', backgroundColor: state.isFocused ? "rgb(229, 231, 234)" : null, ...optionStyles }),
                valueContainer: typeof valueContainerStyles === 'function' ? valueContainerStyles : (provided, _) => ({ ...provided, height: height && height, marginTop: valueContainerMarginTop, ...valueContainerStyles }),
                indicatorsContainer: typeof indicatorsContainerStyles === 'function' ? indicatorsContainerStyles : (provided, _) => ({ ...provided, height: height ? height : 'inherit', ...indicatorsContainerStyles, marginTop: valueContainerMarginTop }),
                placeholder: typeof placeholderStyles === 'function' ? placeholderStyles : (provided, _) => ({ ...provided, fontSize: 12, color: 'rgba(0,0,0,0.4)', ...placeholderStyles }),
                menu: typeof menuStyles === 'function' ? menuStyles : (provided, _) => ({ ...provided, ...menuStyles }),
                menuList: typeof menuListStyles === 'function' ? menuListStyles : (provided, _) => ({
                    ...provided, paddingTop: 0, paddingBottom: 0,
                    "::-webkit-scrollbar": {
                        width: "5px"
                    },
                    "::-webkit-scrollbar-track": {
                        background: "#ddd"
                    },
                    "::-webkit-scrollbar-thumb": {
                        background: "#888"
                    },
                    "::-webkit-scrollbar-thumb:hover": {
                        background: "#555"
                    },
                    ...menuListStyles
                }),
                indicatorSeparator: typeof indicatorSeparatorStyles === 'function' ? indicatorSeparatorStyles : (provided, _) => ({ ...provided, ...indicatorSeparatorStyles })
            }}
            components={{
                DropdownIndicator: (props) => (
                    components.DropdownIndicator && (
                        <components.DropdownIndicator {...props}>
                            <SelectDropdownIcon
                                icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}
                                fontSize={dataFontSize + 2}
                                icon_color={icon_color}
                            />
                        </components.DropdownIndicator>
                    )
                ),
                IndicatorSeparator: () => null
            }}
            {...checkAsync}
        />
    );
}


export default CustomDropDownComponent



