import { takeEvery, put, call, select } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { APPROVE_DISBURSEMENT } from '../../types/types';
import { approveDisbursementFailure } from '../../actions/loanApplications/approveDisbursementAction';
import { approveDisbursementRequest } from '../../../requests/loanApplications/approveDisbursementRequest';
import { getLoanApplicationDetailSuccess } from '../../actions/loanApplications/loanApplicationDetailAction';


const getLoanDetail = (state) => state.getLoanApplicationDetail.data;

function* approveDisbursementSaga({ payload }) {   
  
    try {
        const response = yield call(approveDisbursementRequest, payload);
        console.log("DISBURSED \n ", response.data.data);

        if (response.status === 200) {
            let loanDetail = yield select(getLoanDetail);
            loanDetail.loans[0].status = 'approved';
            yield put(getLoanApplicationDetailSuccess(loanDetail));
        }

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(approveDisbursementFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(approveDisbursementFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(approveDisbursementFailure(error.message));
        }
        else {
            yield put(approveDisbursementFailure(error.message));
        }
    }
}

export default function* watchApproveDisbursementSaga() {
    yield takeEvery(APPROVE_DISBURSEMENT.SEND_REQUEST, approveDisbursementSaga);
};