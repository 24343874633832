
import { GET_ALL_ADMINS, GET_ALL_CUSTOMERS, GET_ALL_LOANS, PIN_TO_TOP } from "../../types/types"

const initialState = {
    pinLoan: { type: { pin: GET_ALL_LOANS.PIN_LOAN, unpin: GET_ALL_LOANS.UNPIN_LOAN }, checked: new Set() },
    pinUsers: { type: { pin: GET_ALL_ADMINS.PIN_ADMIN, unpin: GET_ALL_ADMINS.UNPIN_ADMIN }, checked: new Set() },
    pinNotifications: { type: { pin: "PIN_NOTIFICATION", unpin: "UNPIN_NOTIFICATION" }, checked: new Set() },
    pinCustomers: { type: { pin: GET_ALL_CUSTOMERS.PIN_CUSTOMER, unpin: GET_ALL_CUSTOMERS.UNPIN_CUSTOMER }, checked: new Set() },

}

const pinToTopReducer = (state = initialState, action) => {
    switch (action.type) {
        case PIN_TO_TOP.ADD_CHECK_LOAN:
            return {
                ...state,
                pinLoan: { ...state.pinLoan, checked: new Set([...state.pinLoan.checked, action.payload]) }
            }

        case PIN_TO_TOP.REMOVE_CHECK_LOAN:
            return {
                ...state,
                pinLoan: { ...state.pinLoan, checked: new Set([...state.pinLoan.checked].filter(x => x !== action.payload)) }
            }

        case PIN_TO_TOP.CLEAR_CHECK_LOAN:
            return {
                ...state,
                pinLoan: { ...state.pinLoan, checked: new Set() }
            }






        case PIN_TO_TOP.ADD_CHECK_NOTIFICATION:
            return {
                ...state,
                pinNotifications: { ...state.pinNotifications, checked: new Set([...state.pinNotifications.checked, action.payload]) }
            }

        case PIN_TO_TOP.REMOVE_CHECK_NOTIFICATION:
            return {
                ...state,
                pinNotifications: { ...state.pinNotifications, checked: new Set([...state.pinNotifications].filter(x => x !== action.payload)) }
            }

        case PIN_TO_TOP.CLEAR_CHECK_NOTIFICATION:
            return {
                ...state,
                pinNotifications: { ...state.pinNotifications, checked: new Set() }
            }





            


        case PIN_TO_TOP.ADD_CHECK_CUSTOMER:
            return {
                ...state,
                pinCustomers: { ...state.pinCustomers, checked: new Set([...state.pinCustomers.checked, action.payload]) }
            }

        case PIN_TO_TOP.REMOVE_CHECK_CUSTOMER:
            return {
                ...state,
                pinCustomers: { ...state.pinCustomers, checked: new Set([...state.pinCustomers.checked].filter(x => x !== action.payload)) }
            }

        case PIN_TO_TOP.CLEAR_CHECK_CUSTOMER:
            return {
                ...state,
                pinCustomers: { ...state.pinCustomers, checked: new Set() }
            }








            

        case PIN_TO_TOP.ADD_CHECK_USER:
            return {
                ...state,
                pinUsers: { ...state.pinUsers, checked: new Set([...state.pinUsers.checked, action.payload]) }
            }

        case PIN_TO_TOP.REMOVE_CHECK_USER:
            return {
                ...state,
                pinUsers: { ...state.pinUsers, checked: new Set([...state.pinUsers.checked].filter(x => x !== action.payload)) }
            }

        case PIN_TO_TOP.CLEAR_CHECK_USER:
            return {
                ...state,
                pinUsers: { ...state.pinUsers, checked: new Set() }
            }


        default: return state;
    }
}

export default pinToTopReducer;