import { APPROVE_DISBURSEMENT } from '../../types/types';

export const startApproveDisbursement = payload => ({
    type: APPROVE_DISBURSEMENT.SEND_REQUEST,
    payload,
    error: null
});


export const approveDisbursementSuccess = response => ({
    type: APPROVE_DISBURSEMENT.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setApproveDisbursementSentStatus = isSent => ({
    type: APPROVE_DISBURSEMENT.RESPONSE_STATUS,
    payload: isSent,
});


export const approveDisbursementFailure = error => ({
    type: APPROVE_DISBURSEMENT.REQUEST_FAILURE,
    error
});