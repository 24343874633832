import styled from 'styled-components';


export const AdminAccountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 180px;
    width: 100%;

    &>div{
        min-width: 25%;
        display: flex;
        flex-direction: column;

        & button {
            font-weight: bold;
        }
    }
 
`


export const EmailVerifyWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    padding-top: 10%;

    &> h6{
        padding: 0;
        margin:0;
        padding: 40px 0 10px 0;
        color:  rgba(67,69,111,1);
        font-size: 15px;
    }

    & p{
        font-size: 14px;
        opacity: 0.6;
    }
    & div{
        margin-top: 10px;
        font-size: 14px;
        display: flex;

        & span{
            color: rgb(66, 121, 250);
            font-weight: bold;
            cursor: pointer;
            margin-left: 4px;
        }
    }
`

export const Header = styled.div`
padding-top: 10px;
`;

export const FormHeader = styled.h5`
  height: 33px;
  width: 300px;
  color: rgba(67,69,111,1);
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 33px;
  margin-bottom: 50px;
`
export const LogoContainer = styled.div`
float: left;
padding-top: 10px;
display: flex;
margin-left: 60px;
`