import { RETRY_COLLECTION } from '../../types/types';

export const startRetryCollection = payload => ({
    type: RETRY_COLLECTION.SEND_REQUEST,
    payload,
    error: null
});


export const retryCollectionSuccess = response => ({
    type: RETRY_COLLECTION.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setRetryCollectionSentStatus = isSent => ({
    type: RETRY_COLLECTION.RESPONSE_STATUS,
    payload: isSent,
});


export const retryCollectionFailure = error => ({
    type: RETRY_COLLECTION.REQUEST_FAILURE,
    error
});