
import { MANAGE_ADMINS_ACCOUNT } from "../../types/types";

const initialState = {
    loading: false,
    responseStatus: false,
    data: null,
    error: null
}

const manageAdminsAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case MANAGE_ADMINS_ACCOUNT.SEND_REQUEST:
            return {
                ...state,
                responseStatus: false,
                error: null,
                data:null,
                loading: true
            }

        case MANAGE_ADMINS_ACCOUNT.RESPONSE_RECEIVED:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
                responseStatus: true
            }

        case MANAGE_ADMINS_ACCOUNT.RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload
            }

        case MANAGE_ADMINS_ACCOUNT.REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.error
            }

        default: return state;
    }
}

export default manageAdminsAccountReducer;