import React, { useEffect, useState } from 'react'
import NavHeaderComponent from '../NavHeaderComponent/NavHeaderComponent'
import { CheckboxComponent, InputFieldOutlineComponent, PageBgWrapperComponent, PageCardLargeComponent } from '../SharedComponents/SharedComponent'
import { IoChevronDownOutline as ArrowDown } from 'react-icons/io5'
import { MdRefresh as Refresh } from 'react-icons/md'
import { UpperToolsSection, ChevronDown } from './LoanApplicationsElement'
import LoanApplicationTableComponent from './LoanApplicationTableComponent'
import { connect } from 'react-redux'
import { startGetAllLoans } from '../../redux/actions/loanApplications/getAllLoansAction'
import { ErrorDisplay, GoNext, GoPrev, RetryLoading } from '../SharedComponents/SharedComponentElement'
import TableLoadingComponent from '../SharedComponents/TableLoadingComponent';
import connError from '../../assets/no-conn.png'
import CustomDropDownComponent from '../SharedComponents/DropDownComponent/CustomDropDownComponent'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { addToCheckedList, clearCheckedList, removeFromCheckedList } from '../../redux/actions/pinToTopAction/pinToTopAction'
import { PIN_TO_TOP } from '../../redux/types/types'


function LoanApplicationsMainComponent({ getAllLoans, startGetAllLoans, ...rest }) {

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [paginationState, setPaginationState] = useState({
        from: 1,
        to: 0,
    });
    const [dataPerPage, setDataPerPage] = useState(null);


    const options = [
        { value: "Pending", label: "Pending" },
        { value: "two", label: "Two" },
        { value: "three", label: "Three" },
    ];


    const selectAll = (value) => {
        if (value) {
            setIsCheckAll(true);

            getAllLoans.data.loans.data.forEach((_, index) => {
                rest.addToCheckedList(index, PIN_TO_TOP.ADD_CHECK_LOAN)
            })
        } else {
            setIsCheckAll(false);
            rest.clearCheckedList(PIN_TO_TOP.CLEAR_CHECK_LOAN);
        }
    }

    const numbers = Array.from({ length: getAllLoans.data && getAllLoans?.data?.loans?.meta.total }, (_, i) => ({ value: i + 1, label: i + 1 }));

    const onNextPage = () => {
        setPaginationState({ ...paginationState, from: paginationState.from += getAllLoans.data.loans.meta.perPage, to: paginationState.to += getAllLoans.data.loans.meta.perPage })
        startGetAllLoans({ next: getAllLoans.data.loans.meta.next, perPage: dataPerPage && dataPerPage.value });
    }

    const onPrevPage = () => {
        setPaginationState({ ...paginationState, from: paginationState.from -= getAllLoans.data.loans.meta.perPage, to: paginationState.to -= getAllLoans.data.loans.meta.perPage })
        startGetAllLoans({ previous: getAllLoans.data.loans.meta.previous, perPage: dataPerPage && dataPerPage.value });
    }

    useEffect(() => {
        startGetAllLoans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageBgWrapperComponent>
            <NavHeaderComponent pageTitle={'Loan Applications'} />
            <PageCardLargeComponent>
                <>
                    <UpperToolsSection>
                        <div>
                            <div style={{ display: 'flex' }}>
                                <CheckboxComponent borderColor='#888' onChange={(value) => selectAll(value)} />
                                <ArrowDown style={{ fontSize: '13px', margin: '2px 4px' }} />
                            </div>
                            <Refresh onClick={() => window.location.reload()} style={{ fontSize: '20px', margin: '0 15px', transform: 'scale(1, -1)' }} />
                        </div>

                        <div>
                            <InputFieldOutlineComponent width='100%' />


                            <CustomDropDownComponent
                                placeholder={'filter by'}
                                value={selectedFilter}
                                options={options}
                                onChange={(value) => setSelectedFilter(value)}
                                optionStyles={{ padding: 5 }}
                                width={180}
                                height={28}
                                valueContainerMarginTop="-3px"
                            />

                        </div>

                        <div style={{ fontSize: '14px' }}>
                            <div><span>Rows per page</span></div>
                            <div>
                                {/* <span style={{ color: 'rgb(35, 120, 250)', marginRight: '5px' }}>25</span> */}
                                {/* <ChevronDown /> */}

                                <CustomDropDownComponent
                                    placeholder={''}
                                    value={dataPerPage !== null ? dataPerPage : getAllLoans.data && numbers.filter(number => number.label === getAllLoans.data.loans.meta.perPage)}
                                    options={numbers}
                                    onChange={(value) => { setDataPerPage(value); startGetAllLoans({ perPage: value.value }); }}
                                    optionStyles={{ padding: 5 }}
                                    valueContainerStyles={{ padding: '0' }}
                                    menuStyles={{ borderRadius: 'none', marginTop: "-3px" }}
                                    width={60}
                                    iconContainerPadding={'5px 0 0 0'}
                                    containerBorder="none"
                                    height={28}
                                    textColor="blue"
                                    icon_color="blue"
                                    dataFontSize={13.4}
                                    valueContainerMarginTop="-3px"
                                />

                            </div>

                            <div>

                                {/* <span>1-25 of 100</span> */}

                                <div>
                                    <span>

                                        {(getAllLoans.loading) ? (
                                            <>
                                                <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                                                    <Skeleton count={1} height={10} width={80} />
                                                </SkeletonTheme>
                                            </>) : (
                                            <>
                                                {paginationState.from}-{getAllLoans.data && ((getAllLoans.data.loans.meta.perPage + paginationState.to) > getAllLoans.data.loans.meta.total ? getAllLoans.data.loans.meta.total : (getAllLoans.data.loans.meta.perPage + paginationState.to))} of {getAllLoans.data && getAllLoans.data.loans.meta.total}
                                            </>)}
                                    </span>
                                </div>

                            </div>
                            <div>
                            {/* {console.log(rest.pinToTopList)} */}
                                <span>
                                    {getAllLoans.data && getAllLoans.data.loans.meta.previous ? (
                                        <GoPrev onClick={() => onPrevPage()} />
                                    ) : (
                                        <GoPrev style={{ color: '#ddd', pointerEvents: 'none' }} />
                                    )}
                                </span>

                                <span>
                                    {getAllLoans.data && getAllLoans.data.loans.meta.next ? (
                                        <GoNext onClick={() => onNextPage()} />
                                    ) : (
                                        <GoNext style={{ color: '#ddd', pointerEvents: 'none' }} />
                                    )}
                                </span>
                            </div>
                        </div>
                    </UpperToolsSection>



                    {(getAllLoans.loading) ? (
                        <>

                            <TableLoadingComponent />
                        </>) : (
                        <>
                            {getAllLoans.error !== null ? (
                                <>
                                    <ErrorDisplay>
                                        <div>
                                            <img src={connError} alt="Error" />
                                            <h1>{getAllLoans.error}</h1>
                                            <RetryLoading onClick={() => startGetAllLoans()}>Retry</RetryLoading>
                                        </div>
                                    </ErrorDisplay>
                                </>) : (
                                <>
                                    <LoanApplicationTableComponent loans={getAllLoans} checkedList={rest.pinToTopList} isCheckAll={isCheckAll} addToCheckedList={rest.addToCheckedList} removeFromCheckedList={rest.removeFromCheckedList} />
                                </>)}
                        </>)}
                </>

            </PageCardLargeComponent>
        </PageBgWrapperComponent>
    )
}


const mapStateToProps = (state) => ({
    getAllLoans: state.getAllLoans,
    pinToTopList: state.pinToTopList.pinLoan
});

const mapDispatchToProps = dispatch => ({
    startGetAllLoans: (data) => dispatch(startGetAllLoans(data)),
    addToCheckedList: (data, type) => dispatch(addToCheckedList(data, type)),
    removeFromCheckedList: (data, type) => dispatch(removeFromCheckedList(data, type)),
    clearCheckedList: (type) => dispatch(clearCheckedList(type)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoanApplicationsMainComponent);
