import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { GET_ALL_ADMINS } from '../../types/types';
import { getAllAdminsFailure, getAllAdminsSuccess } from '../../actions/userManagement/getAllAdminsAction';
import { getAllAdminsRequest } from '../../../requests/userManagement/getAllAdminsRequest';



function* getAllAdminsSaga({payload}) {
    try { 
        const meta = {perPage: "", next: "", previous: "", ...payload}
        const response = yield call(getAllAdminsRequest, meta);
        console.log("ADMIN ALL DATA \n ", response.data.data);
        yield put(getAllAdminsSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(getAllAdminsFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(getAllAdminsFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(getAllAdminsFailure(error.message));
        }
        else {
            yield put(getAllAdminsFailure(error.message));
        }
    }
}

export default function* watchGetAllAdminsSaga() {
    yield takeEvery(GET_ALL_ADMINS.SEND_REQUEST, getAllAdminsSaga);
};