import React, { useState } from 'react'
import AdminHomeComponent from '../../components/AdminHomeComponent/AdminHomeComponent'
import SideBarComponent from '../../components/SidebarComponent/SideBarComponent'

function HomePage(props) {
    const query = new URLSearchParams(props.location.search);
    const token = query.get('token');
    token && localStorage.setItem('token', token);

    useState(() => localStorage.getItem('token'));


    return (
        <div style={{ display: 'flex' }}>
            <SideBarComponent />
            <AdminHomeComponent />
        </div>
    )
}


export default HomePage
