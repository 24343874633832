import React, { useContext } from "react";
import {
  NavHeaderComponentWrapper,
  PageTitle,
  RightIcons,
  ProfileIcon,
  ProfileCardWrapper,
  Link,
  PinIcon,
  BtnPinToTop
} from "./NavHeaderElement";
import profile from "../../assets/profile.jpg";
import dropdown from "../../assets/dropdown.jpg";
import { DashboardGlobalContext } from "../../Contexts/GlobalContext";
import { connect } from "react-redux";
import { pinItem, unPinItem } from "../../redux/actions/pinToTopAction/pinToTopAction";

function NavHeaderComponent({ pageTitle, pinToTopList, pinItem }) {
  const [globalState, setGlobalState] = useContext(DashboardGlobalContext);
  const getPinnedListOrigin = Object.values(pinToTopList).filter((checkedList) => checkedList.checked?.size)[0];
  return (
    <NavHeaderComponentWrapper>
      <PageTitle >{pageTitle && pageTitle}</PageTitle>
      <RightIcons>
        {getPinnedListOrigin && (
          <BtnPinToTop onClick={() => pinItem(getPinnedListOrigin.checked, getPinnedListOrigin.type.pin)}><PinIcon />Pin to top</BtnPinToTop>
        )}
        <ProfileIcon onClick={() => { setGlobalState({ ...globalState, profileToggle: !globalState.profileToggle }) }}>
          <img src={profile} alt=""></img>
          <img src={dropdown} alt=""></img>
        </ProfileIcon>
        {/* PROFILE CARD */}
        {globalState.profileToggle && (
          <ProfileCardWrapper>
            <ul>
              <Link to='/' style={{ textDecoration: 'none' }}>
                <li>Sign out</li>
              </Link>
            </ul>
          </ProfileCardWrapper>
        )}

      </RightIcons>
    </NavHeaderComponentWrapper>
  )
}

const mapStateToProps = (state) => ({
  pinToTopList: state.pinToTopList
});

const mapDispatchToProps = dispatch => ({
  unPinItem: (data, type) => dispatch(unPinItem(data, type)),
  pinItem: (data, type) => dispatch(pinItem(data, type))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavHeaderComponent);
// export default NavHeaderComponent;
