import React from "react";
import Checkbox from "react-custom-checkbox";
// import * as Icon from "react-icons/fi";
import { IoSearchOutline as SearchIcon } from 'react-icons/io5';
import { IoClose as CloseIcon } from 'react-icons/io5';
import { FaCheck as CheckMarkIcon } from 'react-icons/fa';

import {
  CustomButtonComponent,
  InputField,
  SelectDropdownIcon,
  // PhoneInputWrapper,
  // PhoneInputField,
  OutlineInputField,
  ToggleCheckBox,
  // EditIcon,
  PageCardLargeWrapper,
  PageBgWrapper,
  BackgroundOverlay,
  NavHeaderComponent,
  OutlineInputWrapper,
  FormikError,
  CreateMarginBottom,
  ModalPackage,
  MessageCardComponentWrapper,
  BaseMessageComponent,
} from "./SharedComponentElement";
// import flag from "../../assets/Group.png";
import Select, { components } from "react-select";
import { ModalContainer } from "../CustomersComponent/CustomersComponentElement";
import { toast } from "react-toastify";

export function ButtonComponent({
  height,
  type,
  width,
  bgColor,
  marginTop,
  color,
  fontSize,
  marginBottom,
  onClick,
  hoverColor,
  bgColorDisabled,
  disabled = false,
  children,
  border
}) {
  return (
    <CustomButtonComponent
      disabled={disabled}
      border={border}
      type={type}
      height={height}
      width={width}
      hoverColor={hoverColor}
      fontSize={fontSize}
      bgColor={bgColor}
      bgColorDisabled={bgColorDisabled}
      marginBottom={marginBottom}
      onClick={onClick}
      marginTop={marginTop}
      color={color}
    >
      {children}
    </CustomButtonComponent>
  );
}

export function ModalComponent({
  Top,
  Bottom,
  Left,
  Right,
  isOpen,
  onRequestClose,
  onClick,
  children,
  BgColor = 'rgba(0, 0, 0, 0.2)'

}) {
  return (
    <ModalContainer>
      <ModalPackage
        BgColor={BgColor}
        style={{ overlay: { backgroundColor: BgColor, zIndex: '100' } }}
        Top={Top}
        Bottom={Bottom}
        Left={Left}
        Right={Right}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        onClick={onClick}
        children={children} />
    </ModalContainer>

  );

};

export function CloseButton({
  onClick
}) {
  return (
    <div style={{ textAlign: 'right', fontSize: '23px', margin: '5px 15px 0 0' }}>
      <CloseIcon
        style={{ cursor: 'pointer' }}
        onClick={onClick} />
    </div>
  );
};


export function InputFieldComponent({
  id,
  name,
  pattern,
  required = false,
  value,
  placeholder,
  marginBottom,
  color,
  fontSize,
  bgColor,
  padding,
  onChange,
  marginTop,
  height,
  width = '100%',
  type = 'text',
  minlength,
  className,
  border,
  placeholderColor,
  maxlength
}) {
  return (
    <InputField
      id={id}
      placeholderColor={placeholderColor}
      height={height}
      fontSize={fontSize}
      className={className}
      type={type}
      color={color}
      bgColor={bgColor}
      padding={padding}
      minlength={minlength}
      maxlength={maxlength}
      required={required}
      border={border}
      pattern={pattern}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      marginBottom={marginBottom}
      marginTop={marginTop}
      width={width}
    />
  );
}




export function CheckboxComponent({
  borderColor = "#bbb",
  onChange,
  name,
  checked = false,
  size = 15
}) {
  return (
    <div>
      <Checkbox
        icon={<CheckMarkIcon color='#444' />}
        name={name}
        checked={checked}
        onChange={onChange}
        borderColor={borderColor}
        borderRadius={3.5}
        style={{ cursor: "pointer" }}
        size={size}
      />

    </div>
  );
}



export function FormBackgroundOverlay({ children }) {
  return (
    <BackgroundOverlay>{children}</BackgroundOverlay>
  );
}

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SelectDropdownIcon />
      </components.DropdownIndicator>
    )
  );
};

const selectHeight = 28;
const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    height: selectHeight,
    minHeight: selectHeight,
    minWidth: 170,

  }),

  singleValue: (provided, state) => ({
    ...provided,
    fontSize: 13,

  }),

  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    borderBottom: '1px solid #eee',
    padding: 2,
    color: state.isSelected && 'inherit',
    margin: 0,
    backgroundColor: state.isFocused ? "rgb(229, 231, 234)" : null,
  }),


  valueContainer: (provided, state) => ({
    ...provided,
    height: selectHeight,
    // borderRadius: 5

    // backgroundColor: 'red'
  }),

  // Style icon section
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 'inherit',
  }),
};

const selectComponents = { DropdownIndicator, IndicatorSeparator: () => null };

export function SelectDropDownComponent({
  id,
  options,
  name,
  value,
  onChange,
  isSearchable = false,
}) {
  return (
    <Select
      isSearchable={isSearchable}
      id={id}
      onChange={onChange}
      value={value}
      styles={customSelectStyles}
      components={selectComponents}
      placeholder={null}
      options={options}
      name={name}
    />
  );
}





export function DropDownComponent({
  id,
  options,
  name,
  value,
  placeholder = null,
  onChange,
  onBlur,
  isSearchable = false,
  menuStyles,
  controlStyles,
  defaultValue,
  singleValueStyles,
  optionStyles,
  valueContainerStyles,
  placeholderStyles,
  indicatorsContainerStyles,
  containerStyles,
  dropdownIndicatorStyles,
  clearIndicatorStyles,
  inputStyles,
  indicatorSeparatorStyles
}) {
  return (
    <Select
      onBlur={onBlur}
      defaultValue={defaultValue}
      isSearchable={isSearchable}
      id={id}
      onChange={onChange}
      value={value}
      styles={{
        clearIndicator: clearIndicatorStyles,
        dropdownIndicator: dropdownIndicatorStyles,
        control: controlStyles,
        input: inputStyles,
        container: containerStyles,
        placeholder: placeholderStyles,
        menu: menuStyles,
        singleValue: singleValueStyles,
        option: optionStyles,
        valueContainer: valueContainerStyles,
        indicatorsContainer: indicatorsContainerStyles,
        indicatorSeparator: indicatorSeparatorStyles
      }}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null
      }}
      placeholder={placeholder}
      options={options}
      name={name}
    />
  );
}


export function InputFieldOutlineComponent({
  id,
  name,
  value,
  placeholder = 'Search',
  marginBottom,
  onChange,
  marginLeft,
  left,
  width,
}) {
  return (
    <OutlineInputWrapper style={{ display: "flex", alignItems: "center" }}>

      <div style={{ marginRight: "10px" }}>
        <SearchIcon style={{ fontSize: '18px' }} />
      </div>

      <OutlineInputField
        id={id}
        left={left}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        width={width}
      />
    </OutlineInputWrapper>
  );
}

export function ToggleCheckBoxComponent({
  id,
  checked,
  name,
  value,
  onChange,
}) {
  return (
    <ToggleCheckBox class="switch">
      <input
        type="checkbox"
        checked={checked}
        value={value}
        onChange={onChange}
        id={id}
        name={name}
      />
      <span class="slider round"></span>
    </ToggleCheckBox>
  );
}

export function NavHeaderComponentBlank({
  children
}) {
  return (
    <NavHeaderComponent>
      {children}
    </NavHeaderComponent>
  );
}

export function PageBgWrapperComponent({
  children
}) {
  return (
    <PageBgWrapper>
      {children}
    </PageBgWrapper>
  );
}

export function PageCardLargeComponent({
  children
}) {
  return (
    <PageCardLargeWrapper>
      {children}
    </PageCardLargeWrapper>
  );
}


export const FormikErrorComponent = ({ children, style }) => (
  <FormikError style={style}>{children}</FormikError>
);

export const CreateMarginBottomComponent = ({ marginBottom }) => (
  <CreateMarginBottom marginBottom={marginBottom} />
);


export const MessageCardComponent = ({ children, messageType, style }) => {

  return (<>
    <MessageCardComponentWrapper message={messageType} style={style}>
      {children}
    </MessageCardComponentWrapper>
  </>)
}



export const MessageComponent = ({ children, typeIndex, minWidth, onClick }) => {

  //success, error, warning, info
  const typeBg = { success: 'rgb(212, 237, 218)', error: 'rgb(248, 215, 218)', warning: 'rgb(255, 243, 205)', info: 'rgb(209, 236, 241)' };
  const typeTextColor = { success: 'rgb(56, 87, 58)', error: 'rgb(160, 45, 36)', warning: 'rgb(149, 100, 35)', info: 'rgb(48, 155, 213)' };
  return (
    <BaseMessageComponent minWidth={minWidth} color={typeTextColor[typeIndex]} bgColor={typeBg[typeIndex]}>
      {children}
    </BaseMessageComponent>
  )
}


export const notifyToast = config => toast(<MessageComponent typeIndex={config.message_type}>{config.message}</MessageComponent>, {
  closeButton: false,
  position: config.position
});