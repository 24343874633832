import { all } from 'redux-saga/effects'
import watchSendMagicLink from './auth/magicLinkSaga';
import watchGetAllCustomersSaga from './customers/getAllCustomersSaga';
import watchGetCustomerDetailSagaSaga from './customers/getCustomerDetailSaga';
import watchRefundVerificationChargeSaga from './customers/refundVerificationChargeSaga';
import watchRetryCollectionSaga from './customers/retryCollectionSaga';
import watchGetAdminDataSaga from './dashboard/getAdminDataSaga';
import watchGetLoanStatsSaga from './dashboard/loanStatsSaga';
import watchApproveDisbursementSaga from './loanApplications/approveDisbursementSaga';
import watchGetAllLoansSaga from './loanApplications/getAllLoansSaga';
import watchGetLoanApplicationDetailSaga from './loanApplications/getLoanApplicationDetailSaga';
import watchReadEstatementSaga from './loanApplications/readEstatementSaga';
import watchGetAllAdminsSaga from './userManagement/getAllAdminsSaga';
import watchManageAdminsAccountSaga from './userManagement/manageAdminsAccountSaga';

export default function* rootSaga() {
    yield all([
        watchSendMagicLink(),
        watchGetLoanStatsSaga(),
        watchGetAdminDataSaga(),
        watchGetAllAdminsSaga(),
        watchGetAllLoansSaga(),
        watchGetAllCustomersSaga(),
        watchGetCustomerDetailSagaSaga(),
        watchGetLoanApplicationDetailSaga(),
        watchManageAdminsAccountSaga(),
        watchRefundVerificationChargeSaga(),
        watchApproveDisbursementSaga(),
        watchReadEstatementSaga(),
        watchRetryCollectionSaga()
    ]);
}