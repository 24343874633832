import { combineReducers } from 'redux';
import sendMagicLinkReducer from './auth/magicLinkReducer';
import getAllCustomersReducer from './customers/getAllCustomersReducer';
import getCustomerDetailReducer from './customers/getCustomerDetailReducer';
import refundVerificationChargeReducer from './customers/refundVerificationChargeReducer';
import retryCollectionReducer from './customers/retryCollectionReducer';
import getAdminDataReducer from './dashboard/getAdminDataReducer';
import loanStatsReducer from './dashboard/loanStatsReducer';
import approveDisbursementReducer from './loanApplications/approveDisbursementReducer';
import getAllLoansReducer from './loanApplications/getAllLoansReducer';
import getLoanApplicationDetailReducer from './loanApplications/loanApplicationDetail';
import readEStatementReducer from './loanApplications/readEStatementReducer';
import pinToTopReducer from './pinToTopReducer/pinToTopReducer';
import getAllAdminsReducer from './userManagement/getAllAdminsReducer';
import manageAdminsAccountReducer from './userManagement/manageAdminsAccountReducer';


const rootReducer = combineReducers({
   sendMagicLink: sendMagicLinkReducer,
   getAdminData: getAdminDataReducer,
   getLoanStats: loanStatsReducer,
   getAllAdmins: getAllAdminsReducer,
   getAllLoans: getAllLoansReducer,
   getAllCustomers: getAllCustomersReducer,
   getCustomerDetail: getCustomerDetailReducer,
   getLoanApplicationDetail: getLoanApplicationDetailReducer,
   manageAdminsAccount: manageAdminsAccountReducer,
   refundVerificationCharge: refundVerificationChargeReducer,
   approveDisbursement: approveDisbursementReducer,
   readEstatement: readEStatementReducer,
   retryCollection: retryCollectionReducer,
   pinToTopList: pinToTopReducer
});

export default rootReducer;