import React from 'react'
import LoanApplicationsMainComponent from '../../components/LoanApplicationsComponent/LoanApplicationsMainComponent'
import SideBarComponent from '../../components/SidebarComponent/SideBarComponent'

function LoanApplicationsPage() {
    return (
        <div style={{ display: 'flex' }}>
            <SideBarComponent />
            <LoanApplicationsMainComponent />
        </div>
    )
}

export default LoanApplicationsPage