import styled from 'styled-components';
import { IoArrowUp } from 'react-icons/io5';


export const LoanApplicationDetailFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 30px;

    & div.loan-detail-flex-child:first-child {
        width: 55%;
    }

    & div.loan-detail-flex-child:last-child {
        width: 45%;
    }
`

export const DataRowFlex = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;

  & > div:first-child {
    color: rgb(66, 69, 111);
    font-size: 14px;
    width: 45%;
    margin-right: 10%;
  }

  & > div:last-child {
    font-size: 14px;
    width: 70%;
    display: flex;
    justify-content: space-between;

    &>p{
      color: rgba(51,96,255,1);
      margin: 0;
      cursor: pointer;
      padding: 0;
    }
  }
`


export const Header = styled.div`
    display: flex;
    margin-bottom: 40px;


 & > h6 {
    height: 18px;
    color: #43456F;
    margin-right: 10px;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 600;
 }
`


export const NorthEastArrow = styled(IoArrowUp)`
    font-size: 23px;
    padding: 0;
    margin: 0;
    color: rgb(35, 120, 250);
    transform: rotate(45deg);
  &:hover {
    cursor: pointer;
}
`