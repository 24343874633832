import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { GET_ALL_CUSTOMERS } from '../../types/types';
import { getAllCustomersFailure, getAllCustomersSuccess } from '../../actions/customers/getAllCustomersAction';
import { getAllCustomersRequest } from '../../../requests/customers/getAllCustomersRequest';



function* getAllCustomersSaga({payload}) {
    // console.log("hello")
    try {
        const meta = {perPage: "", next: "", previous: "", from:"", to:"", ...payload}
        const response = yield call(getAllCustomersRequest, meta);
        console.log("CUSTOMERS ALL DATA \n ", response.data.data);
        yield put(getAllCustomersSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(getAllCustomersFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(getAllCustomersFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(getAllCustomersFailure(error.message));
        }
        else {
            yield put(getAllCustomersFailure(error.message));
        }
    }
}

export default function* watchGetAllCustomersSaga() {
    yield takeEvery(GET_ALL_CUSTOMERS.SEND_REQUEST, getAllCustomersSaga);
};