import React, { useRef, useState } from 'react'
import { TableComponent } from './UserManagementElement';
import { CheckboxComponent, FormikErrorComponent, InputFieldComponent } from '../SharedComponents/SharedComponent';
import { CreateMarginBottom, PinIcon } from '../SharedComponents/SharedComponentElement';
import CustomModalComponent from '../SharedComponents/Modal/ModalComponent';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { startManageAdminsAccount } from '../../redux/actions/userManagement/manageAdminsAccountAction';
import CustomDropDownComponent from '../SharedComponents/DropDownComponent/CustomDropDownComponent';
import { PIN_TO_TOP } from '../../redux/types/types';


function UserManagementTable({ admins, isCheckAll, manageAdminsAccount, startManageAdminsAccount, addToCheckedList, removeFromCheckedList }) {
    const formRef = useRef();

    const [payload, setPayload] = useState({});

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const updateRoleHandler = () => {
        formRef.current.submitForm();
    }


    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Please enter a valid email'),
    });

    const options = [
        { value: "Admin", label: "Admin" },
        { value: "Super-Admin", label: "Super-Admin" },
    ];

    const onChangeRoleClick = (item) => {
        setPayload({ ...item });
        handleShow();
        formRef.current.setValues({ ...formRef.current.values, email: item.email, role: item.role })
    }

    return (
        <>
            <TableComponent className="table">
                <colgroup>
                    <col span="1" style={{ width: '5%' }} />
                </colgroup>

                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Member</th>
                        <th scope="col">Name</th>
                        <th scope="col">Role</th>
                        <th scope="col">Actions</th>
                        {admins.pinned.size > 0 && (
                            <th scope="col"></th>
                        )}
                    </tr>
                </thead>
                <tbody>

                    {admins.data && admins.data.admins.data.map((item, index) => (
                        <tr key={item.admin_id}>
                            <td>
                                <CheckboxComponent checked={isCheckAll} onChange={(value) => value ? addToCheckedList(index, PIN_TO_TOP.ADD_CHECK_USER) : removeFromCheckedList(index, PIN_TO_TOP.REMOVE_CHECK_USER)} />
                                {/* <CheckboxComponent checked={isCheckAll} className='select-admin' onChange={(value) => { setCheckedList(currentVal => value ? [...currentVal, item.admin_id] : [...currentVal.filter(e => e !== item.admin_id)]) }} /> */}
                            </td>
                            <td>
                                <div className='ellipses-overflow'>
                                    {item.email}
                                </div>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.role}</td>
                            <td><span onClick={() => onChangeRoleClick(item)}>Change Role</span></td>

                            {admins.pinned.size > 0 && (
                                <td >
                                    {index === [...Array(admins.pinned.size).keys()][index] && (
                                        <PinIcon />
                                    )}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </TableComponent>



            <Formik
                innerRef={formRef}
                validateOnChange
                initialValues={{ email: "", role: "" }}
                onSubmit={
                    data => {
                        
                        const newData = { ...payload, role: data.role, email: data.email !== "" ? data.email : payload.email };
                        console.log(newData);
                        startManageAdminsAccount({ data: newData, admin_id: payload.admin_id });
                    }}
                validationSchema={validationSchema}>
                {formik => (
                    <>
                        <CustomModalComponent
                            actionHandler={updateRoleHandler}
                            show={show} setShow={setShow} handleClose={handleClose}
                            actionBtnText="Change"
                            isLoading={manageAdminsAccount.loading}
                            titleText={"Change role"}>

                            <div style={{ width: '70%' }}>
                                <Form>
                                    <Field name='email' id='email' onBlur={formik.handleBlur} type='email' as={InputFieldComponent}
                                        bgColor={'white'}
                                        height={'40px'}
                                        placeholder={'hello@creditlocus.com'}
                                        border={"1.5px solid rgb(233, 235, 243)"}
                                        padding={"0 0 0 15px"}
                                        fontSize={'13px'}
                                        placeholderColor={'rgba(0,0,0,0.4)'}
                                        color={"rgb(76, 79, 114)"} />
                                    {(formik.errors.email && formik.touched['email']) && (<FormikErrorComponent style={{ marginTop: '6px' }}>{formik.errors.email}</FormikErrorComponent>)}

                                    <CreateMarginBottom marginBottom={'25px'} />

                                    <Field name='role'
                                        placeholder={'Select role'}
                                        value={options ? options.find(option => option.value === formik.values.role) : ''}
                                        onChange={value => formik.setFieldValue('role', value.value)}
                                        options={options}
                                        onBlur={(e) => { formik.setFieldTouched('role'); formik.handleBlur(e) }}
                                        width={'100%'}
                                        as={CustomDropDownComponent} />


                                </Form>
                            </div>
                        </CustomModalComponent>
                    </>
                )}</Formik>
        </>
    )
}


const mapStateToProps = (state) => ({
    manageAdminsAccount: state.manageAdminsAccount,
});

const mapDispatchToProps = dispatch => ({
    startManageAdminsAccount: (data) => dispatch(startManageAdminsAccount(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserManagementTable);