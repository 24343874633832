export const SEND_MAGIC_LINK = {
    SEND_REQUEST: 'SEND_MAGIC_LINK_LOADING',
    RESPONSE_RECEIVED: 'SEND_MAGIC_LINK_SUCCESS',
    REQUEST_FAILURE: 'SEND_MAGIC_LINK_FAILURE',
    RESPONSE_STATUS: "SEND_MAGIC_LINK_RESPONSE_STATUS",
}


export const LOAN_STATS = {
    SEND_REQUEST: 'LOAN_STATS_LOADING',
    RESPONSE_RECEIVED: 'LOAN_STATS_SUCCESS',
    REQUEST_FAILURE: 'LOAN_STATS_FAILURE',
    RESPONSE_STATUS: "LOAN_STATS_RESPONSE_STATUS",
}

export const GET_ADMIN_DATA = {
    SEND_REQUEST: 'GET_ADMIN_DATA_LOADING',
    RESPONSE_RECEIVED: 'GET_ADMIN_DATA_SUCCESS',
    REQUEST_FAILURE: 'GET_ADMIN_DATA_FAILURE',
    RESPONSE_STATUS: "GET_ADMIN_DATA_RESPONSE_STATUS",
}

export const GET_ALL_LOANS = {
    SEND_REQUEST: 'GET_ALL_LOANS_LOADING',
    RESPONSE_RECEIVED: 'GET_ALL_LOANS_SUCCESS',
    REQUEST_FAILURE: 'GET_ALL_LOANS_FAILURE',
    RESPONSE_STATUS: "GET_ALL_LOANS_RESPONSE_STATUS",

    PIN_LOAN: "PIN_LOAN",
    UNPIN_LOAN: "UNPIN_LOAN",
}


export const GET_ALL_CUSTOMERS = {
    SEND_REQUEST: 'GET_ALL_CUSTOMERS_LOADING',
    RESPONSE_RECEIVED: 'GET_ALL_CUSTOMERS_SUCCESS',
    REQUEST_FAILURE: 'GET_ALL_CUSTOMERS_FAILURE',
    RESPONSE_STATUS: "GET_ALL_CUSTOMERS_RESPONSE_STATUS",

    PIN_CUSTOMER: "PIN_CUSTOMER",
    UNPIN_CUSTOMER: "UNPIN_CUSTOMER",
}

export const GET_CUSTOMER_DETAIL = {
    SEND_REQUEST: 'GET_CUSTOMER_DETAIL_LOADING',
    RESPONSE_RECEIVED: 'GET_CUSTOMER_DETAIL_SUCCESS',
    REQUEST_FAILURE: 'GET_CUSTOMER_DETAIL_FAILURE',
    RESPONSE_STATUS: "GET_CUSTOMER_DETAIL_RESPONSE_STATUS",
}
export const LOAN_APPLICATIONS_DETAIL = {
    SEND_REQUEST: 'LOAN_APPLICATIONS_DETAIL_LOADING',
    RESPONSE_RECEIVED: 'LOAN_APPLICATIONS_DETAIL_SUCCESS',
    REQUEST_FAILURE: 'LOAN_APPLICATIONS_DETAIL_FAILURE',
    RESPONSE_STATUS: "LOAN_APPLICATIONS_DETAIL_RESPONSE_STATUS",
}


export const GET_ALL_ADMINS = {
    SEND_REQUEST: 'GET_ALL_ADMINS_LOADING',
    RESPONSE_RECEIVED: 'GET_ALL_ADMINS_SUCCESS',
    REQUEST_FAILURE: 'GET_ALL_ADMINS_FAILURE',
    RESPONSE_STATUS: "GET_ALL_ADMINS_RESPONSE_STATUS",


    PIN_ADMIN: "PIN_ADMIN",
    UNPIN_ADMIN: "UNPIN_ADMIN",
}

export const MANAGE_ADMINS_ACCOUNT = {
    SEND_REQUEST: 'MANAGE_ADMINS_ACCOUNT_LOADING',
    RESPONSE_RECEIVED: 'MANAGE_ADMINS_ACCOUNT_SUCCESS',
    REQUEST_FAILURE: 'MANAGE_ADMINS_ACCOUNT_FAILURE',
    RESPONSE_STATUS: "MANAGE_ADMINS_ACCOUNT_RESPONSE_STATUS",
}


export const REFUND_VERIFICATION_CHARGE = {
    SEND_REQUEST: 'REFUND_VERIFICATION_CHARGE_LOADING',
    RESPONSE_RECEIVED: 'REFUND_VERIFICATION_CHARGE_SUCCESS',
    REQUEST_FAILURE: 'REFUND_VERIFICATION_CHARGE_FAILURE',
    RESPONSE_STATUS: "REFUND_VERIFICATION_CHARGE_RESPONSE_STATUS",
}


export const APPROVE_DISBURSEMENT = {
    SEND_REQUEST: 'APPROVE_DISBURSEMENT_LOADING',
    RESPONSE_RECEIVED: 'APPROVE_DISBURSEMENT_SUCCESS',
    REQUEST_FAILURE: 'APPROVE_DISBURSEMENT_FAILURE',
    RESPONSE_STATUS: "APPROVE_DISBURSEMENT_RESPONSE_STATUS",
}


export const READ_E_STATEMENT = {
    SEND_REQUEST: 'READ_E_STATEMENT_LOADING',
    RESPONSE_RECEIVED: 'READ_E_STATEMENT_SUCCESS',
    REQUEST_FAILURE: 'READ_E_STATEMENT_FAILURE',
    RESPONSE_STATUS: "READ_E_STATEMENT_RESPONSE_STATUS",
    CLICKED_ID: "READ_E_STATEMENT_CLICKED_ID"
}


export const DASHBOARD_DATA = {
    SEND_REQUEST: 'DASHBOARD_DATA_LOADING',
    RESPONSE_RECEIVED: 'DASHBOARD_DATA_SUCCESS',
    REQUEST_FAILURE: 'DASHBOARD_DATA_FAILURE',
    RESPONSE_STATUS: "DASHBOARD_DATA_RESPONSE_STATUS",
}

export const RETRY_COLLECTION = {
    SEND_REQUEST: 'RETRY_COLLECTION_LOADING',
    RESPONSE_RECEIVED: 'RETRY_COLLECTION_SUCCESS',
    REQUEST_FAILURE: 'RETRY_COLLECTION_FAILURE',
    RESPONSE_STATUS: "RETRY_COLLECTION_RESPONSE_STATUS",
}

export const PIN_TO_TOP = {
    ADD_CHECK_LOAN: "ADD_CHECK_LOAN",
    REMOVE_CHECK_LOAN: "REMOVE_CHECK_LOAN",
    CLEAR_CHECK_LOAN: "CLEAR_CHECK_LOAN",

    ADD_CHECK_NOTIFICATION: "ADD_CHECK_NOTIFICATION",
    REMOVE_CHECK_NOTIFICATION: "REMOVE_CHECK_NOTIFICATION",
    CLEAR_CHECK_NOTIFICATION: "CLEAR_CHECK_NOTIFICATION",

    ADD_CHECK_USER: "ADD_CHECK_USER",
    REMOVE_CHECK_USER: "REMOVE_CHECK_USER",
    CLEAR_CHECK_USER: "CLEAR_CHECK_USER",


    ADD_CHECK_CUSTOMER: "ADD_CHECK_CUSTOMER",
    REMOVE_CHECK_CUSTOMER: "REMOVE_CHECK_CUSTOMER",
    CLEAR_CHECK_CUSTOMER: "CLEAR_CHECK_CUSTOMER",
    

}