import React, { useEffect, useState } from 'react'
import { CheckboxComponent, InputFieldOutlineComponent, PageBgWrapperComponent, PageCardLargeComponent } from '../SharedComponents/SharedComponent';
import NavHeaderComponent from '../NavHeaderComponent/NavHeaderComponent';
import { IoChevronDownOutline as ArrowDown } from 'react-icons/io5'
import { MdRefresh as Refresh } from 'react-icons/md'
import { UpperToolsSection } from './CustomersComponentElement';
import CustomersTable from './CustomersTable';
import { connect } from 'react-redux';
import { startGetAllCustomers } from '../../redux/actions/customers/getAllCustomersAction';
import { ErrorDisplay, GoNext, GoPrev, RetryLoading } from '../SharedComponents/SharedComponentElement';
import TableLoadingComponent from '../SharedComponents/TableLoadingComponent';
import connError from '../../assets/no-conn.png'
import CustomDropDownComponent from '../SharedComponents/DropDownComponent/CustomDropDownComponent';
import { addToCheckedList, clearCheckedList, removeFromCheckedList } from '../../redux/actions/pinToTopAction/pinToTopAction';
import { PIN_TO_TOP } from '../../redux/types/types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


function CustomersComponent({ getAllCustomers, startGetAllCustomers, ...rest }) {
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [dataPerPage, setDataPerPage] = useState(null);
    const [paginationState, setPaginationState] = useState({
        from: 1,
        to: 0,
    });


    const options = [
        { value: "registration_date", label: "Registration Date" },
        { value: "total_loans", label: "Total Loans" },
        { value: "loan_balance", label: "Loan Balance" },
    ];

    const numbers = Array.from({ length: getAllCustomers.data && getAllCustomers.data.customers.meta.total }, (_, i) => ({ value: i + 1, label: i + 1 }));


    useEffect(() => {
        startGetAllCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const selectAll = (value) => {
        if (value) {
            setIsCheckAll(true);

            getAllCustomers.data.customers.data.forEach((_, index) => {
                rest.addToCheckedList(index, PIN_TO_TOP.ADD_CHECK_CUSTOMER)
            })
        } else {
            setIsCheckAll(false);
            rest.clearCheckedList(PIN_TO_TOP.CLEAR_CHECK_CUSTOMER);
        }
    }

    const onNextPage = () => {

        // localStorage.setItem('cl-page-info', JSON.stringify({ ...JSON.parse(localStorage.getItem('cl-page-info')), currentNextPage: getAllCustomers.data.customers.meta.next }));

        setPaginationState({ ...paginationState, from: paginationState.from += getAllCustomers.data.customers.meta.perPage, to: paginationState.to += getAllCustomers.data.customers.meta.perPage })
        startGetAllCustomers({ next: getAllCustomers.data.customers.meta.next, perPage: dataPerPage && dataPerPage.value });
    }

    const onPrevPage = () => {
        setPaginationState({ ...paginationState, from: paginationState.from -= getAllCustomers.data.customers.meta.perPage, to: paginationState.to -= getAllCustomers.data.customers.meta.perPage })
        startGetAllCustomers({ previous: getAllCustomers.data.customers.meta.previous, perPage: dataPerPage && dataPerPage.value });
    }

    return (
        <PageBgWrapperComponent>
            <NavHeaderComponent pageTitle={'Customers'} />

            <PageCardLargeComponent>
                <UpperToolsSection>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <CheckboxComponent onChange={(value) => selectAll(value)} borderColor='#888' />
                            <ArrowDown style={{ fontSize: '13px', margin: '2px 4px' }} />
                        </div>
                        <Refresh onClick={() => window.location.reload()} style={{ cursor: 'pointer', fontSize: '20px', margin: '0 15px', transform: 'scale(1, -1)' }} />
                    </div>

                    <div>
                        {/* SEARCH INPUT */}
                        <InputFieldOutlineComponent width='100%' />

                        <CustomDropDownComponent
                            placeholder={'filter by'}
                            value={selectedFilter}
                            options={options}
                            onChange={(value) => setSelectedFilter(value)}
                            optionStyles={{ padding: 5 }}
                            width={180}
                            height={28}
                            valueContainerMarginTop="-3px"
                        />

                    </div>

                    <div style={{ fontSize: '14px' }}>
                        <div><span>Rows per page</span></div>
                        <div>
                            {/* <span style={{ color: 'rgb(35, 120, 250)', marginRight: '5px' }}>25</span>
                            <ChevronDown /> */}

                            <CustomDropDownComponent
                                placeholder={''}
                                value={dataPerPage !== null ? dataPerPage : getAllCustomers.data && numbers.filter(number => number.label === getAllCustomers.data.customers.meta.perPage)}
                                options={numbers}
                                onChange={(value) => { setDataPerPage(value); startGetAllCustomers({ perPage: value.value }); }}
                                optionStyles={{ padding: 5 }}
                                valueContainerStyles={{ padding: '0' }}
                                menuStyles={{ borderRadius: 'none', marginTop: "-3px" }}
                                width={60}
                                iconContainerPadding={'5px 0 0 0'}
                                containerBorder="none"
                                height={28}
                                textColor="blue"
                                icon_color="blue"
                                dataFontSize={13.4}
                                valueContainerMarginTop="-3px"
                            />
                        </div>

                        <div>
                            <span>

                                {(getAllCustomers.loading) ? (
                                    <>
                                        <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                                            <Skeleton count={1} height={10} width={80} />
                                        </SkeletonTheme>
                                    </>) : (
                                    <>
                                        {paginationState.from}-{getAllCustomers.data && ((getAllCustomers.data.customers.meta.perPage + paginationState.to) > getAllCustomers.data.customers.meta.total ? getAllCustomers.data.customers.meta.total : (getAllCustomers.data.customers.meta.perPage + paginationState.to))} of {getAllCustomers.data && getAllCustomers.data.customers.meta.total}
                                    </>)}
                            </span>
                        </div>



                        <div>
                            <span>
                                {getAllCustomers.data && getAllCustomers.data.customers.meta.previous ? (
                                    <GoPrev onClick={() => getAllCustomers.data && onPrevPage()} />
                                ) : (
                                    <GoPrev style={{ color: '#ddd', pointerEvents: 'none' }} />
                                )}
                            </span>
                            <span>
                                {getAllCustomers.data && getAllCustomers.data.customers.meta.next ? (
                                    <GoNext onClick={() => getAllCustomers.data && onNextPage()} />
                                ) : (
                                    <GoNext style={{ color: '#ddd', pointerEvents: 'none' }} />
                                )}
                            </span>
                        </div>


                    </div>
                </UpperToolsSection>


                {(getAllCustomers.loading) ? (
                    <>
                        <TableLoadingComponent />
                    </>) : (
                    <>
                        {getAllCustomers.error !== null ? (
                            <>
                                <ErrorDisplay>
                                    <div>
                                        <img src={connError} alt="Error" />
                                        <h1>{getAllCustomers.error}</h1>
                                        <RetryLoading onClick={() => startGetAllCustomers()}>Retry</RetryLoading>
                                    </div>
                                </ErrorDisplay>
                            </>) : (
                            <>
                                <CustomersTable customers={getAllCustomers} checkedList={rest.pinToTopList} isCheckAll={isCheckAll} addToCheckedList={rest.addToCheckedList} removeFromCheckedList={rest.removeFromCheckedList} />
                                {/* {console.log(rest.pinToTopList)} */}
                            </>)}
                    </>)}
            </PageCardLargeComponent>

        </PageBgWrapperComponent>
    );
}

const mapStateToProps = (state) => ({
    getAllCustomers: state.getAllCustomers,
    pinToTopList: state.pinToTopList.pinCustomers
});

const mapDispatchToProps = dispatch => ({
    startGetAllCustomers: (data) => dispatch(startGetAllCustomers(data)),
    addToCheckedList: (data, type) => dispatch(addToCheckedList(data, type)),
    removeFromCheckedList: (data, type) => dispatch(removeFromCheckedList(data, type)),
    clearCheckedList: (type) => dispatch(clearCheckedList(type)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersComponent);