import React from 'react'
import { useHistory } from 'react-router';
import { CheckboxComponent } from '../SharedComponents/SharedComponent'
import { TableComponent } from './SMSandNotificationElement'

function SMSandNotificationTable() {
    const history = useHistory();
    return (

        <TableComponent className="table">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Sender ID</th>
                    <th scope="col">Date</th>
                    <th scope="col">Recipients</th>
                    <th scope="col">Message</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <CheckboxComponent />
                    </td>
                    <td>
                        <div className='ellipses-overflow'>
                            hello@creditlocus.com
                        </div>
                    </td>
                    <td>20 Apr 2021, 15:34</td>
                    <td>Group</td>

                    <td>
                        <div className='ellipses-overflow'>
                            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.
                        </div>
                    </td>

                    <td><span onClick={() => history.push('/sms-and-notifications/435')}>View</span></td>

                </tr>
                <tr>
                    <td>
                        <CheckboxComponent />
                    </td>
                    <td className='ellipses-overflow'>
                        <div className='ellipses-overflow'>
                            hello@creditlocus.com
                        </div>
                    </td>
                    <td>20 Apr 2021, 15:21</td>
                    <td>Group</td>

                    <td>
                        <div className='ellipses-overflow'>
                            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.
                        </div>
                    </td>

                    <td><span onClick={() => history.push('/sms-and-notifications/435')}>View</span></td>

                </tr>
                <tr>
                    <td>
                        <CheckboxComponent />
                    </td>
                    <td>
                        <div className='ellipses-overflow'>
                            hello@creditlocus.com
                        </div>
                    </td>

                    <td>20 Apr 2021, 15:21</td>
                    <td>Group</td>

                    <td>
                        <div className='ellipses-overflow'>
                            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.
                        </div>
                    </td>

                    <td>
                        <span onClick={() => history.push('/sms-and-notifications/435')}>View</span>
                    </td>

                </tr>
            </tbody>
        </TableComponent>
    )
}

export default SMSandNotificationTable


