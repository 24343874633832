import React from 'react'
import Moment from 'react-moment'
import { CheckboxComponent } from '../SharedComponents/SharedComponent'
import { Link, OpenBankStatementText, TableComponent } from './LoanApplicationsElement'
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { setReadEstatementClickedId, startReadEstatement } from '../../redux/actions/loanApplications/readEstatementAction';
import { PinIcon } from '../SharedComponents/SharedComponentElement';
import { PIN_TO_TOP } from '../../redux/types/types';

function LoanApplicationTableComponent({ loans, startReadEstatement, setReadEstatementClickedId, addToCheckedList, checkedList, removeFromCheckedList, isCheckAll }) {

    // console.log(readEstatement);

    const loanStatusColor = (status) => {
        if (status === 'pending') return null;
        if (status === 'approved') return 'rgb(16, 237, 104)';
        if (status === 'declined') return 'rgb(160, 45, 36)';
    }

    return (

        <TableComponent className="table">

            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Business Name</th>
                    <th scope="col">Amount(GHS)</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Status</th>
                    <th scope="col">E-Statement</th>
                    <th scope="col">Actions</th>
                    {loans.pinned.size > 0 && (
                        <th scope="col"></th>
                    )}
                </tr>
            </thead>
            <tbody>

                {loans.data && loans.data.loans.data.map((item, index) => (

                    <tr key={item.loan_id}>
                        <td>
                            <CheckboxComponent checked={isCheckAll} onChange={(value) => value ? addToCheckedList(index, PIN_TO_TOP.ADD_CHECK_LOAN) : removeFromCheckedList(index, PIN_TO_TOP.REMOVE_CHECK_LOAN)} />

                            {/* <CheckboxComponent /> */}
                        </td>
                        <td>
                            <div className='ellipses-overflow'>
                                {item.company.name}
                            </div>
                        </td>
                        <td>
                            {/* {item.amount} */}
                            <CurrencyFormat decimalScale={2} fixedDecimalScale value={item.amount} displayType={'text'} thousandSeparator={true} />
                        </td>

                        <td>
                            <Moment format="D MMM YYYY, hh:mm">
                                {item.created_at}
                            </Moment>
                        </td>
                        <td> <span style={{ color: loanStatusColor(item.status) }}>{item.status}</span></td>
                        <td>
                            <div className='ellipses-overflow'>
                                <span>
                                    <OpenBankStatementText onClick={() => { setReadEstatementClickedId(item.loan_id); startReadEstatement(item.statement_file_path) }}>Bank Statement</OpenBankStatementText>
                                </span>
                            </div>
                        </td>
                        <td>
                            <span><Link to={`/loan-applications/${item.loan_id}`}>View</Link></span>
                        </td>


                        {loans.pinned.size > 0 && (
                            <td >
                                {index === [...Array(loans.pinned.size).keys()][index] && (
                                    <PinIcon />
                                )}
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </TableComponent>

    )
}


const mapStateToProps = (state) => ({
    readEstatement: state.readEstatement,
});
const mapDispatchToProps = dispatch => ({
    setReadEstatementClickedId: (data) => dispatch(setReadEstatementClickedId(data)),
    startReadEstatement: (data) => dispatch(startReadEstatement(data)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoanApplicationTableComponent);
