import React from 'react'
import { Modal } from 'react-bootstrap';
import { ButtonComponent } from '../SharedComponent';
import { BootStrapModalComponent } from './ModalComponentElement';
import Loader from "react-loader-spinner";



function CustomModalComponent({
    show,
    handleClose,
    children,
    actionBtnText,
    titleText,
    actionHandler,
    footermargintop,
    isLoading,
    dialogtop,
    hideFooter = false,
    cancelBtnText = 'Cancel' }) {
    return (
        <>
            <BootStrapModalComponent
                footermargintop={footermargintop}
                dialogtop={dialogtop}
                dialogClassName="modal-dialog"
                show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{titleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>


                {!hideFooter && (
                    <Modal.Footer>
                        
                        <ButtonComponent
                            type='submit'
                            width='100px' height='30px'
                            hoverColor={'rgb(27, 88, 229)'}
                            name='submit_btn' id='submit_btn'
                            marginTop='10px'
                            onClick={() => actionHandler()}
                            bgColor={'rgb(66, 121, 250)'}>
                            {!isLoading ? actionBtnText : (<Loader type="ThreeDots" color="white" height={30} width={30} />)}
                        </ButtonComponent>

                        <ButtonComponent
                            type='submit'
                            disabled={isLoading}
                            width='100px' height='30px'
                            hoverColor={'rgb(242, 242, 242)'}
                            name='submit_btn' id='submit_btn'
                            marginTop='10px'
                            color={'rgb(91, 92, 129)'}
                            border={'1px solid rgb(91, 92, 129)'}
                            onClick={handleClose}
                            bgColor={'white'}>
                            {cancelBtnText}
                        </ButtonComponent>
                    </Modal.Footer>
                )}


            </BootStrapModalComponent>
        </>
    )
}


export default CustomModalComponent
