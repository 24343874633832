import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { GET_ALL_LOANS } from '../../types/types';
import { getAllLoansFailure, getAllLoansSuccess } from '../../actions/loanApplications/getAllLoansAction';
import { getAllLoansRequest } from '../../../requests/loanApplications/getAllLoansRequest';



function* getAllLoansSaga({ payload }) {
    console.log(payload)
    try {
        const meta = {perPage: "", next: "", previous: "", ...payload}

        // const meta = payload === undefined ? { perPage: "", next: "", previous: "" } : payload;

        const response = yield call(getAllLoansRequest, meta)
        console.log("LOAN ALL DATA \n ", response.data.data);

        yield put(getAllLoansSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(getAllLoansFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(getAllLoansFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(getAllLoansFailure(error.message));
        }
        else {
            yield put(getAllLoansFailure(error.message));
        }
    }
}

export default function* watchGetAllLoansSaga() {
    yield takeEvery(GET_ALL_LOANS.SEND_REQUEST, getAllLoansSaga);
};