import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { READ_E_STATEMENT } from '../../types/types';
import { readEstatementRequest } from '../../../requests/loanApplications/readEstatementRequest';
import { readEstatementFailure, readEstatementSuccess } from '../../actions/loanApplications/readEstatementAction';


function* readEstatementSaga({ payload }) {
    try {
        const response = yield call(readEstatementRequest, payload);
        // console.log("READ E STATEMENT \n ", response.data);
        yield put(readEstatementSuccess(response.data));
        response.data && window.open(response.data, '_blank');


    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(readEstatementFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(readEstatementFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(readEstatementFailure(error.message));
        }
        else {
            yield put(readEstatementFailure(error.message));
        }
    }
}

export default function* watchReadEstatementSaga() {
    yield takeEvery(READ_E_STATEMENT.SEND_REQUEST, readEstatementSaga);
};