import styled from "styled-components";
import { DropdownButton } from "react-bootstrap";
import { RiPushpin2Line as Pin } from 'react-icons/ri';
import { Link as LinkRouter } from "react-router-dom";


export const Link = styled(LinkRouter)`
  cursor: pointer;
  color: rgba(0,0,0,1);
  &:hover {
    transition: 0.2s ease-in-out;
  }
`

export const NavHeaderComponentWrapper = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 5px 10px 0 10px;
  height: 70px;
  margin: 0px 5px 5px 0;
  position: sticky;
  z-index: 1000;
  /* top: 0; */
  width: 100% !important;
  border-radius: 5px;
  box-shadow: 0 0 2px -1px;
  display: flex;
  justify-content: space-between;
`;

export const PageTitle = styled.h1`
  float: left;
  font-weight: 700;
  line-height: 20px;
  font-size: 20px;
  color: rgb(67,69,111,1);
  display: flex;
  margin: 22px 0 23px 31px;
`;

export const RightIcons = styled.div`
  margin-top: 10px;
  align-items: center;
  padding-right: 20px;
`;

// export const Bar = styled.div`
//   box-sizing: border-box;
//   height: 30px;
//   width: 1px;
//   opacity: 0.2;
//   border: 1px solid #d4e0ec;
//   margin-left: 10px;
// `;



export const ProfileIcon = styled.button`
  padding: 0;
  background: #fff;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  margin-right: 15px;
`;

// export const Dropdown = styled(DropdownButton)`
//   height: 90px;
//   width: 140px;
//   border-radius: 5px;
//   background-color: #fff;
//   border: 1px solid #d4e0ec;
//   box-shadow: 0 2px 10px 0 rgba(212, 224, 236, 0.1);
// `;

// export const CardDescription = styled.p`
//   font-size: 15px;
//   padding-top: 5px;
//   margin-left: 10px;
//   font-weight: 700;
//   line-height: 16px;
// `;


// export const Hover = styled.div`
//   text-decoration: none;
//   color: inherit;
//   transition: 0.2s ease-in-out;
//   cursor: pointer;
//   &:hover {
//     color: rgb(2, 178, 201);
//     transition: 0.2s ease-in-out;
//   }
// `;

// export const NavBarLink = styled.div`
//   text-decoration: none;
//   color: inherit;
//   transition: 0.2s ease-in-out;
//   cursor: pointer;
//   &:hover {
//     color: rgb(2, 178, 201);
//     transition: 0.2s ease-in-out;
//   }
// `;


export const ProfileCardWrapper = styled.div`
  min-width: 150px;
  min-height: auto;
  right: 18px;
  border-radius: 5px;
  top: 60px;
  background-color: #fff;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  padding-top: 10px;
  font-weight: bold;
  font-size: 14px;

  & > ul {
    list-style: none;
  }

  & > ul li{
    margin-top: 3px;
    padding: 5px 15px 5px 15px;
    cursor: pointer;
  }

  & > ul li:hover{
    background-color: rgb(188, 213, 252)
  }
`;


export const PinIcon = styled(Pin)`
    /* color: #bbb; */
    font-size: 16px;
`;

export const BtnPinToTop = styled.button`
  border: none;
  background-color: rgb(241, 244, 254);
  color: rgb(66, 121, 250);
  margin-right: 35px;
  font-size: 12px;
  padding: 6px 12px;
  border-radius: 5px;
  
  &:hover{
    background-color: rgb(227, 232, 249);
  }
`;