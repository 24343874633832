import React from 'react'
import NavHeaderComponent from "../NavHeaderComponent/NavHeaderComponent";
import CardComponent from "./CardComponent";
import { FilterComponent, CardSectionWrapper, MetricTriangle } from './HomeComponentElement'
import Select from 'react-select';
import { PageBgWrapperComponent } from '../SharedComponents/SharedComponent';
// import ToastComponent from '../SharedComponents/ToastComponent/ToastComponent';
import { startGetLoanStats } from '../../redux/actions/dashboard/loanStatsAction';
import { connect } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import CurrencyFormat from 'react-currency-format';

function AdminHomeComponent({ getLoanStats, startGetLoanStats }) {
    console.log(getLoanStats)
    React.useEffect(() => {
        startGetLoanStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const options = [
        { value: 'lifetime', label: 'Lifetime' },
        { value: 'last_30_days', label: 'Last 30 Days' },
        { value: 'last_90_days', label: 'Last 90 Days' },
        { value: 'year_to_date', label: 'Year-to-Date' },
    ];


    /*
    average_loan_size
    application_to_decision_time
    application_approval_rate
    */
    const checkMetricPattern = (incomingFigure, cachedFigureKey) => {
        const lastCachedFigure = localStorage.getItem(cachedFigureKey);

        if (lastCachedFigure === null) {
            localStorage.setItem(cachedFigureKey, incomingFigure);
            return "--";
        } else {

            if (incomingFigure > lastCachedFigure) {
                localStorage.setItem(cachedFigureKey, incomingFigure);
                return <MetricTriangle icon_color="green" />;
            } else if (incomingFigure < lastCachedFigure) {
                localStorage.setItem(cachedFigureKey, incomingFigure);
                return <MetricTriangle icon_color="red" />;
            } else {
                localStorage.setItem(cachedFigureKey, incomingFigure);
                return "--";
            }
        }

    }



    return (
        <PageBgWrapperComponent>
            <NavHeaderComponent pageTitle={'Dashboard'} />
            {/* <ToastComponent/> */}

            {getLoanStats.loading ? (<>
                <FilterComponent>
                    <SkeletonTheme color="rgb(237, 241, 247)" highlightColor="rgb(247, 247, 249)">
                        <p>
                            <Skeleton width={180} count={1} height={30} />
                        </p>
                    </SkeletonTheme>
                </FilterComponent>

                <CardSectionWrapper>
                    {[...Array(3)].map((x, i) =>
                        <div key={`loading1-${i}`}>
                            <SkeletonTheme color="rgb(237, 241, 247)" highlightColor="rgb(247, 247, 249)">
                                <Skeleton width={350} count={1} height={130} />
                            </SkeletonTheme>
                        </div>
                    )}
                </CardSectionWrapper>


                <CardSectionWrapper style={{ marginTop: '40px' }}>
                    {[...Array(3)].map((x, i) =>
                        <div key={`loading1-${i}`}>
                            <SkeletonTheme color="rgb(237, 241, 247)" highlightColor="rgb(247, 247, 249)">
                                <Skeleton width={350} count={1} height={130} />
                            </SkeletonTheme>
                        </div>
                    )}
                </CardSectionWrapper>


            </>) : (<>
                {getLoanStats.error !== null ? (
                    <>
                        error

                    </>) :
                    (<>
                        <FilterComponent>
                            <Select
                                options={options}
                                placeholder={'Filter'}
                            />
                        </FilterComponent>

                        <CardSectionWrapper>
                            <CardComponent cardTitle='Registered Users' cardContent={getLoanStats.data?.loan_stats?.total_customers} />
                            <CardComponent cardTitle='Total Disbursed' cardContent={
                                <CurrencyFormat decimalScale={2} prefix="GHC " fixedDecimalScale value={getLoanStats.data?.loan_stats?.total_disbursed} displayType={'text'} thousandSeparator={true} />
                            } />
                            <CardComponent cardTitle='Total Repaid' cardContent={
                                <CurrencyFormat decimalScale={2} prefix="GHC " fixedDecimalScale value={getLoanStats.data?.loan_stats?.total_repaid} displayType={'text'} thousandSeparator={true} />
                            } />
                        </CardSectionWrapper>


                        <CardSectionWrapper style={{ marginTop: '40px' }}>
                            <CardComponent cardTitle='Average Loan Size' cardContent={
                                <CurrencyFormat decimalScale={2} prefix="GHC " fixedDecimalScale value={getLoanStats.data?.loan_stats?.average_loan_size} displayType={'text'} thousandSeparator={true} />
                            } cardFooter=' 5.03%' metricIcon={<MetricTriangle icon_color="green" />} />
                            <CardComponent cardTitle='Application Approval Rate' cardContent='86%' cardFooter=' 2.56%' metricIcon={<MetricTriangle icon_color="red" />} />
                            <CardComponent cardTitle='Application-to-Decision Time Cycle' cardContent='2h 43min' cardFooter=' No change' metricIcon={'-- '} />
                        </CardSectionWrapper>

                    </>)}

            </>)}




        </PageBgWrapperComponent>
    )
}



const mapStateToProps = (state) => ({
    getLoanStats: state.getLoanStats,
});
const mapDispatchToProps = dispatch => ({
    startGetLoanStats: (data) => dispatch(startGetLoanStats(data)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminHomeComponent);

// export default AdminHomeComponent
