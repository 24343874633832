import React from 'react'
import { DataRowFlex, Month, TabFlexWrapper } from '../CustomerDetail/CustomerDetailElement'

function ActivityTab() {
    return (
        <TabFlexWrapper>

            <section>
                <Month className='month'>
                    THIS MONTH
                </Month>

                <DataRowFlex>
                    <div className='label'>
                        Apr 2, 2021
                    </div>
                    <div className='value'>
                        Repayment: VISA ****4261
                        <p style={{ color: 'rgb(66, 69, 111)'}}>
                            312.00
                        </p>
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Apr 2, 2021
                    </div>
                    <div className='value'>
                        Repayment: VISA ****4261
                        <p style={{ color: 'rgb(66, 69, 111)'}}>
                            312.00
                        </p>
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Apr 2, 2021
                    </div>
                    <div className='value'>
                        Repayment: VISA ****4261
                        <p style={{ color: 'rgb(66, 69, 111)'}}>
                            312.00
                        </p>
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Apr 2, 2021
                    </div>
                    <div className='value'>
                        Repayment: VISA ****4261
                        <p style={{ color: 'rgb(66, 69, 111)'}}>
                            312.00
                        </p>
                    </div>
                </DataRowFlex>
            </section>



            <section>
                <Month className='month'>
                    LAST MONTH
                </Month>


                <DataRowFlex>
                    <div className='label'>
                        Apr 2, 2021
                    </div>
                    <div className='value'>
                        Repayment: VISA ****4261
                        <p style={{ color: 'rgb(66, 69, 111)'}}>
                            312.00
                        </p>
                    </div>
                </DataRowFlex>
                <DataRowFlex>
                    <div className='label'>
                        Apr 2, 2021
                    </div>
                    <div className='value'>
                        Repayment: VISA ****4261
                        <p style={{ color: 'rgb(66, 69, 111)'}}>
                            312.00
                        </p>
                    </div>
                </DataRowFlex>



                <DataRowFlex>
                    <div className='label'>
                        Apr 2, 2021
                    </div>
                    <div className='value'>
                        Repayment: VISA ****4261
                        <p style={{ color: 'rgb(66, 69, 111)'}}>
                            312.00
                        </p>
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Apr 2, 2021
                    </div>
                    <div className='value'>
                        Repayment: VISA ****4261
                        <p style={{ color: 'rgb(66, 69, 111)'}}>
                            312.00
                        </p>
                    </div>
                </DataRowFlex>
            </section>


        </TabFlexWrapper>
    )
}

export default ActivityTab
