import { LOAN_APPLICATIONS_DETAIL } from '../../types/types';

export const startGetLoanApplicationDetail = payload => ({
    type: LOAN_APPLICATIONS_DETAIL.SEND_REQUEST,
    payload,
    error: null
});


export const getLoanApplicationDetailSuccess = response => ({
    type: LOAN_APPLICATIONS_DETAIL.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setGetLoanApplicationDetailSentStatus = isSent => ({
    type: LOAN_APPLICATIONS_DETAIL.RESPONSE_STATUS,
    payload: isSent,
});


export const getLoanApplicationDetailFailure = error => ({
    type: LOAN_APPLICATIONS_DETAIL.REQUEST_FAILURE,
    error
});