
import { SEND_MAGIC_LINK } from "../../types/types";

const initialState = {
    loading: false,
    responseStatus: false,
    data: null,
    error: null
}

const sendMagicLinkReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_MAGIC_LINK.SEND_REQUEST:
            return {
                ...state,
                loading: true
            }

        case SEND_MAGIC_LINK.RESPONSE_RECEIVED:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
                responseStatus: true
            }

        case SEND_MAGIC_LINK.RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload
            }

        case SEND_MAGIC_LINK.REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.error
            }

        default: return state;
    }
}

export default sendMagicLinkReducer;