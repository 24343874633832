import { READ_E_STATEMENT } from '../../types/types';

export const startReadEstatement = payload => ({
    type: READ_E_STATEMENT.SEND_REQUEST,
    payload,
    error: null
});


export const readEstatementSuccess = response => ({
    type: READ_E_STATEMENT.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setReadEstatementSentStatus = isSent => ({
    type: READ_E_STATEMENT.RESPONSE_STATUS,
    payload: isSent,
});


export const setReadEstatementClickedId = id => ({
    type: READ_E_STATEMENT.CLICKED_ID,
    payload: id,
});


export const readEstatementFailure = error => ({
    type: READ_E_STATEMENT.REQUEST_FAILURE,
    error
});