export default function ConnectivityException(message) {
    this.message = message;
    // Use V8's native method if available, otherwise fallback
    if ("captureStackTrace" in Error)
        Error.captureStackTrace(this, ConnectivityException);
    else
        this.stack = (new Error()).stack;
}

ConnectivityException.prototype = Object.create(Error.prototype);
ConnectivityException.prototype.name = "ConnectivityException";
ConnectivityException.prototype.constructor = ConnectivityException;