import styled from 'styled-components';
import { IoChevronForwardOutline, IoChevronBackOutline, IoChevronDownOutline, IoPersonAddOutline, IoPersonRemoveOutline } from 'react-icons/io5';
import { RiPushpin2Line as Pin } from 'react-icons/ri'



export const UpperToolsSection = styled.div`
    /* background-color: greenyellow; */
    padding: 20px 15px 10px 15px;
    display: flex;
    justify-content: space-between;

    &>div{
        display: flex;     
    }

    &>div:nth-child(2){
        width: 45%;      
        position: relative;
        top: -5px;
    }
    &>div:first-child{
        width: 10%;      
    }

    &>div:last-child{
        width: 35%;      
    }
`;


// TABLE DESIGN
export const TabLabel = styled.h4`
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    color: rgb(46, 124, 249);
`;


// TABLE DESIGN
export const TableComponent = styled.table`
    width: 100%;
    table-layout: fixed;

    & th{
        border: none !important;
        color: rgb(66, 69, 111);
        /* color: red; */
        padding-bottom: 30px;
        font-size: 15px;
    }

    & tr{
        font-size: 13.5px;
        color: rgb(114, 116, 147);

         div.ellipses-overflow{
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 250px;
        }
    }

    & tr > td:nth-child(5){
        color: rgb(66, 121, 250);
        >span{
            cursor: pointer;

        }
    }

`;

export const ChevronRight = styled(IoChevronForwardOutline)`
  font-size: 15px;
  margin-left: 10px;
  color: rgb(35, 120, 250);
`;

export const PinIcon = styled(Pin)`
    color: #bbb;
    font-size: 16px;
`;

export const ChevronLeft = styled(IoChevronBackOutline)`
  font-size: 15px;
  color: rgb(35, 120, 250);
`;

export const ChevronDown = styled(IoChevronDownOutline)`
  font-size: 15px;
  color: rgb(35, 120, 250);
`;

export const RightIcons = styled.div`
text-align: center;
align-items: center;
display: flex;
flex-direction: row;
font-family: "Inter", sans-serif;
font-size: 14px;

`
export const AddUserIcon = styled(IoPersonAddOutline)`
height: 15px;
  width: 15px;
  color: rgba(51,96,255,1);
  cursor: pointer;
  margin-right: 5px;
`
export const RemoveUserIcon = styled(IoPersonRemoveOutline)`
height: 15px;
  width: 15px;
  color: rgba(67,69,111,1);
  cursor: pointer;
  margin-right: 5px;
  color: rgba(67,69,111,1);
`

export const UserManagementContainer = styled.div`
height: 14px;
  float: left;
  color: rgba(51,96,255,1);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
`