import { GET_ALL_ADMINS } from '../../types/types';

export const startGetAllAdmins = payload => ({
    type: GET_ALL_ADMINS.SEND_REQUEST,
    payload,
    error: null
});


export const getAllAdminsSuccess = response => ({
    type: GET_ALL_ADMINS.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setGetAllAdminsSentStatus = isSent => ({
    type: GET_ALL_ADMINS.RESPONSE_STATUS,
    payload: isSent,
});


export const getAllAdminsFailure = error => ({
    type: GET_ALL_ADMINS.REQUEST_FAILURE,
    error
});