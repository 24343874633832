import { LOAN_STATS } from '../../types/types';

export const startGetLoanStats = payload => ({
    type: LOAN_STATS.SEND_REQUEST,
    payload,
    error: null
});


export const getLoanStatsSuccess = response => ({
    type: LOAN_STATS.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setGetLoanStatsSentStatus = isSent => ({
    type: LOAN_STATS.RESPONSE_STATUS,
    payload: isSent,
});


export const getLoanStatsFailure = error => ({
    type: LOAN_STATS.REQUEST_FAILURE,
    error
});