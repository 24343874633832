import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { getAdminDataRequest } from '../../../requests/dashboard/getAdminDataRequest';
import { getAdminDataFailure, getAdminDataSuccess } from '../../actions/dashboard/getAdminDataAction';
import { GET_ADMIN_DATA } from '../../types/types';



function* getAdminDataSaga() {
    try {
        const response = yield call(getAdminDataRequest);
        // console.log("ADMIN DATA \n ", response.data.data);
        yield put(getAdminDataSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(getAdminDataFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(getAdminDataFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(getAdminDataFailure(error.message));
        }
        else {
            yield put(getAdminDataFailure(error.message));
        }
    }
}

export default function* watchGetAdminDataSaga() {
    yield takeEvery(GET_ADMIN_DATA.SEND_REQUEST, getAdminDataSaga);
};