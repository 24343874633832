import React, { useState } from 'react'
import LoanApplicationDetailComponent from '../../components/LoanApplicationsComponent/LoanApplicationsDetail/LoanApplicationDetailComponent'
import SideBarComponent from '../../components/SidebarComponent/SideBarComponent'
import { LoanApplicationDetailContext } from '../../Contexts/AppContext';

function LoanApplicationDetailPage({ match }) {
    const [params] = useState(match.params);

    return (
        <div style={{ display: 'flex' }}>
            <LoanApplicationDetailContext.Provider value={params}>
                <SideBarComponent />
                <LoanApplicationDetailComponent />
            </LoanApplicationDetailContext.Provider>
        </div>
    );
}

export default LoanApplicationDetailPage
