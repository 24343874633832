import { GET_ADMIN_DATA } from '../../types/types';

export const startGetAdminData = payload => ({
    type: GET_ADMIN_DATA.SEND_REQUEST,
    payload,
    error: null
});


export const getAdminDataSuccess = response => ({
    type: GET_ADMIN_DATA.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setGetAdminDataSentStatus = isSent => ({
    type: GET_ADMIN_DATA.RESPONSE_STATUS,
    payload: isSent,
});


export const getAdminDataFailure = error => ({
    type: GET_ADMIN_DATA.REQUEST_FAILURE,
    error
});