import React, { useState } from 'react';

export const DashboardGlobalContext = React.createContext();

const GlobalStateContext = ({ children }) => {
    const [globalState, setGlobalState] = useState({ payEarlyToggle: false, notificationToggle: false, profileToggle: false, viewAccountToggle: false });

    return (
        <DashboardGlobalContext.Provider value={[globalState, setGlobalState]}>
            {children}
        </DashboardGlobalContext.Provider>
    )
}

export default GlobalStateContext;