import { takeEvery, put, call } from 'redux-saga/effects';
import ConnectivityException from '../../../exceptions/ConnectivityException';
import TimeOutException from '../../../exceptions/TimeOutException';
import FetchErrorException from '../../../exceptions/FetchErrorException';
import { REFUND_VERIFICATION_CHARGE } from '../../types/types';
import { refundVerificationChargeFailure, refundVerificationChargeSuccess } from '../../actions/customers/refundVerificationChargeAction';
import { refundVerificationChargeRequest } from '../../../requests/customers/refundVerificationChargeRequest';



function* refundVerificationChargeSaga({ payload }) {

    try {
        if (payload === undefined) throw new Error("Repayment account ID does not exist");

        const response = yield call(refundVerificationChargeRequest, payload);
        console.log("REFUND VERIFICATION CHARGE \n ", response.data.data);
        yield put(refundVerificationChargeSuccess(response.data.data));

    } catch (error) {
        if (error instanceof ConnectivityException) {
            yield put(refundVerificationChargeFailure(error.message));
        } else if (error instanceof TimeOutException) {
            yield put(refundVerificationChargeFailure(error.message));
        }
        else if (error instanceof FetchErrorException) {
            yield put(refundVerificationChargeFailure(error.message));
        }
        else {
            yield put(refundVerificationChargeFailure(error.message));
        }
    }
}

export default function* watchRefundVerificationChargeSaga() {
    yield takeEvery(REFUND_VERIFICATION_CHARGE.SEND_REQUEST, refundVerificationChargeSaga);
};