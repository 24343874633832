import CurrencyFormat from 'react-currency-format';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import nearest from '../../../data/dateNearest';
import { startRefundVerificationCharge } from '../../../redux/actions/customers/refundVerificationChargeAction';
import { CreateMarginBottom, override } from '../../SharedComponents/SharedComponentElement';
import { DataRowFlex, TabFlexWrapper } from '../CustomerDetail/CustomerDetailElement'
import { PuffLoader as Loader } from 'react-spinners';
import { startRetryCollection } from '../../../redux/actions/customers/retryCollectionAction';


function LoanDetailsTab({ getCustomerDetail, refundVerificationCharge, startRefundVerificationCharge, retryCollection, startRetryCollection }) {
    // console.log(refundVerificationCharge);

    const numOr0 = n => isNaN(n) ? 0 : n;

    return (
        <>
            {getCustomerDetail.loading ? (<>
                <>
                    <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                        <Skeleton count={1} height={25} />
                    </SkeletonTheme>

                    <CreateMarginBottom marginBottom='15px' />

                    <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                        <Skeleton count={1} height={25} />
                    </SkeletonTheme>


                    <CreateMarginBottom marginBottom='15px' />

                    <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                        <Skeleton count={1} height={25} />
                    </SkeletonTheme>


                    <CreateMarginBottom marginBottom='15px' />

                    <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                        <Skeleton count={1} height={25} />
                    </SkeletonTheme>

                    <CreateMarginBottom marginBottom='15px' />

                    <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                        <Skeleton count={1} height={25} />
                    </SkeletonTheme>
                </>
            </>) : (<>

                {getCustomerDetail.error !== null ? (<>

                    ERROR

                </>) : (<>

                    <TabFlexWrapper>

                        {getCustomerDetail.data && (<>

                            <DataRowFlex>
                                <div className='label'>
                                    Total loans
                                </div>
                                <div className='value'>
                                    <CurrencyFormat decimalScale={2} fixedDecimalScale
                                        value={getCustomerDetail.data.customers[0].company.loans.map(({ amount }) => numOr0(parseFloat(amount))).reduce((accumulator, b) => accumulator + b, 0.00)}
                                        displayType={'text'} thousandSeparator={true} prefix='GHC ' />
                                </div>
                            </DataRowFlex>


                            <DataRowFlex>
                                <div className='label'>
                                    Loan balance
                                </div>
                                <div className='value'>
                                    <CurrencyFormat decimalScale={2} fixedDecimalScale value={getCustomerDetail.data.customers[0].company.loan_balance} displayType={'text'} thousandSeparator={true} prefix='GHC ' />
                                </div>
                            </DataRowFlex>


                            <DataRowFlex>
                                <div className='label'>
                                    Next repayment
                                </div>


                                {getCustomerDetail.data.customers[0].company.loans?.length ? (<>
                                    <div className='value'>
                                        <CurrencyFormat decimalScale={2} fixedDecimalScale
                                            value={nearest(getCustomerDetail.data.customers[0].company.loans[0].variants.breakdowns).total_monthly_repayment}
                                            displayType={'text'} thousandSeparator={true} prefix='GHC ' />
                                    </div>

                                </>) : (<>
                                    <div className='value'>
                                        <CurrencyFormat decimalScale={2} fixedDecimalScale
                                            value={'0'}
                                            displayType={'text'} thousandSeparator={true} prefix='GHC ' />
                                    </div>
                                </>)}
                            </DataRowFlex>


                            <DataRowFlex>
                                <div className='label'>
                                    Due date
                                </div>


                                {getCustomerDetail.data.customers[0].company.loans?.length ? (<>
                                    <div className='value'>
                                        <Moment format="D MMM YYYY" date={nearest(getCustomerDetail.data.customers[0].company.loans[0].variants.breakdowns).date} />
                                    </div>
                                </>) : (<>
                                    <div className='value'>
                                        No loan data
                                    </div>
                                </>)}
                            </DataRowFlex>

                            <DataRowFlex>
                                <div className='label'>
                                    Overdue notice
                                </div>

                                <div className='value' style={{ color: 'rgba(255,51,102,1)' }}>
                                    <div>30 Days</div>

                                    {retryCollection.loading ? (<>
                                        <p>
                                            <Loader color={'cornflowerblue'} css={override} size={35} />
                                        </p>
                                    </>) : (<>
                                        <p onClick={() => startRetryCollection(getCustomerDetail.data.customers[0].company.company_id)}>Retry collection</p>
                                    </>)}

                                </div>
                            </DataRowFlex>


                            <DataRowFlex>
                                <div className='label'>
                                    Verification charge
                                </div>

                                <div className='value' >
                                    <div>
                                        {(getCustomerDetail.data.customers[0]?.company?.transactions[0]?.repayment_account?.refunded_verification_charge_at !== null) || (refundVerificationCharge.data !== null)?
                                            "Refunded" : "GHC 1.00"}
                                    </div>

                                    {getCustomerDetail.data.customers[0]?.company?.transactions[0]?.repayment_account?.refunded_verification_charge_at !== null || (refundVerificationCharge.data !== null)?
                                        <p style={{ color: 'rgba(0,0,0,0.4)', cursor:'default' }}>
                                            Refund
                                        </p> :
                                        <>
                                            {refundVerificationCharge.loading ? (<>
                                                <p>
                                                    <Loader color={'cornflowerblue'} css={override} size={35} />
                                                </p>
                                            </>) : (<>
                                                <p onClick={() => startRefundVerificationCharge(getCustomerDetail.data.customers[0].company.loans[0]?.repayment_account_id)}>Refund</p>
                                            </>)}

                                        </>

                                    }



                                </div>
                            </DataRowFlex>
                        </>)}

                    </TabFlexWrapper>
                </>)}
            </>)}
        </>



    )
}


const mapStateToProps = (state) => ({
    getCustomerDetail: state.getCustomerDetail,
    refundVerificationCharge: state.refundVerificationCharge,
    retryCollection: state.retryCollection
});

const mapDispatchToProps = dispatch => ({
    startRefundVerificationCharge: (data) => dispatch(startRefundVerificationCharge(data)),
    startRetryCollection: (data) => dispatch(startRetryCollection(data)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoanDetailsTab);

// export default LoanDetailsTab
