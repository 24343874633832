import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { CreateMarginBottom } from '../../SharedComponents/SharedComponentElement';
import { DataRowFlex, TabFlexWrapper } from '../CustomerDetail/CustomerDetailElement'

function CompanyDetailsTab({ getCustomerDetail }) {

    return (
        <>
            {getCustomerDetail.loading ? (<>
                <>
                    {[...Array(6)].map((x, i) =>
                        <div key={`loading1-${i}`}>
                            <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                                <p>
                                    <Skeleton count={1} height={25} />
                                </p>
                            </SkeletonTheme>
                            <CreateMarginBottom marginBottom='15px' />
                        </div>
                    )}
                </>
            </>) : (<>

                {getCustomerDetail.error !== null ? (
                    <>
                        ERROR
                    </>) : (<>

                        <TabFlexWrapper>

                            {getCustomerDetail.data && (<>

                                <DataRowFlex>
                                    <div className='label'>
                                        Company name
                                    </div>
                                    <div className='value'>
                                        {getCustomerDetail.data.customers[0].company.name}
                                    </div>
                                </DataRowFlex>


                                <DataRowFlex>
                                    <div className='label'>
                                        Company address
                                    </div>
                                    <div className='value'>
                                        {getCustomerDetail.data.customers[0].company.address}
                                    </div>
                                </DataRowFlex>


                                <DataRowFlex>
                                    <div className='label'>
                                        Company email
                                    </div>
                                    <div className='value'>
                                        {getCustomerDetail.data.customers[0].company.email}
                                    </div>
                                </DataRowFlex>


                                <DataRowFlex>
                                    <div className='label'>
                                        Company TIN
                                    </div>
                                    <div className='value'>
                                        {getCustomerDetail.data.customers[0].company.tax_id ? getCustomerDetail.data.customers[0].company.tax_id : "N/A"}
                                    </div>
                                </DataRowFlex>


                                <DataRowFlex>
                                    <div className='label'>
                                        Type of business
                                    </div>
                                    <div className='value'>
                                        {getCustomerDetail.data.customers[0].company.business_type}
                                    </div>
                                </DataRowFlex>


                                <DataRowFlex>
                                    <div className='label'>
                                        Verification status
                                    </div>
                                    <div className='value'>
                                        <span style={{ color: getCustomerDetail.data.customers[0].company.is_valid_id ? 'rgba(21,149,141,1)' : "rgb(247, 74, 101)" }}>
                                            {getCustomerDetail.data.customers[0].company.is_valid_id ? "Verified" : "Unverified"}
                                        </span>
                                    </div>
                                </DataRowFlex>
                            </>)}
                        </TabFlexWrapper>
                    </>)}
            </>)}
        </>

    )
}


const mapStateToProps = (state) => ({
    getCustomerDetail: state.getCustomerDetail,
});


export default connect(
    mapStateToProps,
    null
)(CompanyDetailsTab);
