import styled from "styled-components";
import {
  IoArrowForwardCircleOutline,
  IoSettingsOutline,
  IoChevronDownOutline,
  IoChatboxEllipsesOutline
} from "react-icons/io5";
import { BiTransfer } from "react-icons/bi";


import { FaRegFolder as Folder } from "react-icons/fa";

import { RiHomeLine } from "react-icons/ri";
import { NavLink as LinkRouter } from "react-router-dom";

export const SideBarWrapper = styled.div`
  background-color: rgb(10, 33, 77);
  padding: 10px 10px 0 10px;
  min-height: 100vh;
  width: 16%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow-x: hidden;
`;

export const FadedTop = styled.div`
  background-color: rgb(26, 47, 95);
  height: ${({ isSignUp }) => (isSignUp ? "70px" : "auto")};
  border-radius: 5px;
  padding: 15px;
  color: white;
`;

export const SideBarMenu = styled.ul`
  color: rgb(54, 224, 250);
  margin-top: 40px;
`;

export const HomeSideBarMenu = styled.ul`
  color: #ffffff;
  margin-top: 40px;
  padding: 0;
  /* margin: 0; */
`;

export const ArrowNorthEast = styled(IoArrowForwardCircleOutline)`
  font-size: 1.4em;
  margin-right: 15px;
  transform: rotate(-45deg);
`;

export const ArrowDropdown = styled(IoChevronDownOutline)`
  height: 17px;
  width: 17px;
  float: right;
  border-right: 11px;

`

export const FadedTopBoldText = styled.div`
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  margin-bottom: 5px;
`;

export const FadedTopLightText = styled.p`
  font-size: 12px;
`;

export const Spacer = styled.div`
  margin-top: 20px;
`;

export const DashBoardIcon = styled(RiHomeLine)`
  font-size: 1.4em;
  margin-right: 15px;
`;


export const LoanApplicationsIcon = styled(Folder)`
  font-size: 1.4em;
  margin-right: 15px;
  
`;


export const CustomersIcon = styled(BiTransfer)`
  font-size: 1.4em;
  margin-right: 15px;
`;

export const SMSIcon = styled(IoChatboxEllipsesOutline)`
  font-size: 1.4em;
  margin-right: 15px;
`;

export const UserManagementIcon = styled(IoSettingsOutline)`
  font-size: 1.4em;
  margin-right: 15px;
`;


export const SideBarLink = styled(LinkRouter)`
  text-decoration: none;
  color: inherit;
  display: flex;
  transition: 0.2s ease-in-out;
  align-items: center;
  text-decoration: none !important;
  padding: 5px 0 5px 10px;
  font-size: 15px;
  cursor: pointer;

 &.active {
    background-color: rgb(26, 47, 95);
    color: rgb(54, 224, 250);
    border-radius: 5px;
  }

  &:hover {
    color: rgb(2, 178, 201);
    transition: 0.2s ease-in-out;
  }


`;
