import React, { useState } from 'react'
import CustomerDetailComponent from '../../components/CustomersComponent/CustomerDetail/CustomerDetailComponent'
import SideBarComponent from '../../components/SidebarComponent/SideBarComponent'
import { CustomerDetailContext } from '../../Contexts/AppContext';




function CustomerDetailPage({ match }) {
    const [params] = useState(match.params);

    return (
        <div style={{ display: 'flex' }}>
            <CustomerDetailContext.Provider value={params}>
                <SideBarComponent />
                <CustomerDetailComponent />
            </CustomerDetailContext.Provider>
        </div>

    )
}

export default CustomerDetailPage
