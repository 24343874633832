import React, { useState } from 'react'
import NavHeaderComponent from '../NavHeaderComponent/NavHeaderComponent'
import { Tab, Tabs } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles'
import { FaPlus } from 'react-icons/fa'
import { ButtonComponent, ModalComponent, PageBgWrapperComponent, PageCardLargeComponent } from '../SharedComponents/SharedComponent'
//import NotificationsTab from './Tabs/NotificationsTab';
import SMSTab from './Tabs/SMSTab';
import { TabLabel, BoxDiv, CalendarIcon, MessageArea, MessageContainer, MessageCount, PickDate, Query, DataRowFlex, RadioLabel, TimeIcon, SelectedRecipients } from './SMSandNotificationElement';
import { FilterComponent } from '../AdminHomeComponent/HomeComponentElement';
import Select from 'react-select/';
import { useHistory } from 'react-router';




function SMSandNotificationComponent({ classes, props }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChange = (e, newTab) => {
        setSelectedTab(newTab);
    };
    const [newMessageIsOpen, setNewMessageIsOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [previewMessage, setPreviewMessage] = useState(false);

    const options = [
        { value: "all_customers", label: "All customers" },
        { value: "create_new_recipients_group", label: "Create new recipient group" },
    ];

    const [values, setValues] = useState({
        message: '',
        charCount: 0,
        charLimit: 160,
        messageLimit: 5,
        count: ''
    });
    const handleWordCount = message => event => {
        setValues({ ...values, [message]: event.target.value });
    };

    values.charCount = values.message.length;
    if (values.charCount > values.charLimit) {
        values.count = Math.trunc(values.charCount / values.charLimit);
    } else {
        values.count = 1
    }
    const [scheduleSendIsHidden, setScheduleSendIsHidden] = useState(false)


    const history = useHistory();

    return (
        <PageBgWrapperComponent>
            <NavHeaderComponent pageTitle={'SMS & Notifications'} />

            <PageCardLargeComponent>

                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px 20px 0 20px' }}>
                    <Tabs TabIndicatorProps={{ style: { background: 'rgb(46, 124, 249)' } }} value={selectedTab} onChange={handleChange}>
                        <Tab classes={{ root: classes.tab }} label={<TabLabel>SMS</TabLabel>} />
                        {/*<Tab classes={{ root: classes.tab }} label={<TabLabel>Push Notifications</TabLabel>} />*/}
                    </Tabs>
                    <ButtonComponent
                        bgColor="rgb(46, 124, 249)"
                        width='130px'
                        height='30px'
                        onClick={() => history.push("/new-message")}>
                        <FaPlus style={{ margin: '-3px 10px 0 0' }} />
                        New Message
                    </ButtonComponent>
                </div>


                {selectedTab === 0 && <SMSTab />}
                {/*selectedTab === 1 && <NotificationsTab />*/}
            </PageCardLargeComponent>



            <ModalComponent
                isOpen={newMessageIsOpen}
                onRequestClose={() => setNewMessageIsOpen(true)}
                Top={'130px'}
                Left={'240px'}
                Right={'22px'}
                Bottom={'20px'}
                BgColor={'rgba(0, 0, 0, 0)'}>

                <DataRowFlex style={{ marginTop: '30px' }}>
                    <Query>
                        Compose Message
                    </Query>



                    <MessageContainer>
                        {/* style={{ paddingTop: '10px', paddingLeft: '20px', lineHeight: '20px' }} */}
                        <MessageArea maxLength='800' placeholder='Compose your message' id='message' value={values.message} onChange={handleWordCount('message')} required={true} />

                        <MessageCount style={{ paddingTop: '15px', paddingLeft: '20px', display: 'flex' }}>
                            {`${values.charCount}`}{' '}Characters, {`${values.count}`} of {`${values.messageLimit}`} Messages
                            {
                                (values.charCount / values.charLimit) === values.messageLimit &&
                                <p style={{ marginLeft: '30%', color: 'red' }}>Message Limit Reached</p>
                            }
                        </MessageCount>
                    </MessageContainer>



                </DataRowFlex>


                <DataRowFlex>
                    <Query style={{ paddingTop: '5px', marginBottom: '30px' }}>
                        Select Recipients
                    </Query>
                    <FilterComponent style={{ marginTop: '5px', marginBottom: '30px' }}>
                        <Select options={options} placeholder={'Select Recipients'} />
                    </FilterComponent>
                </DataRowFlex>


                <DataRowFlex>
                    <Query>
                        Sending Options
                    </Query>
                    <div style={{ display: 'flex', fontSize: '13px', textAlign: 'center' }}>
                        <input type='radio' name='send_now' value='send_now' style={{ marginRight: '5px' }} onClick={() => setScheduleSendIsHidden(false)} /><RadioLabel style={{ marginRight: '20px' }}>Send now</RadioLabel>
                        <input type='radio' name='send_now' value='send_now' style={{ marginRight: '5px' }} onClick={() => setScheduleSendIsHidden(true)} /><RadioLabel>Send later</RadioLabel>
                    </div>
                </DataRowFlex>

                {scheduleSendIsHidden &&
                    <DataRowFlex style={{ marginBottom: '35px' }}>
                        <Query>
                            {' '}
                        </Query>
                        <BoxDiv>
                            <CalendarIcon />
                            <PickDate
                                id="send_date"
                                placeholderText="Send Date"
                                name="send_date" selected={selectedDate}
                                onChange={date => setSelectedDate(date)}
                                required='true' />
                        </BoxDiv>
                        <BoxDiv>
                            <TimeIcon />

                            <PickDate
                                id="send_time"
                                placeholderText="Send Time"
                                name="send_time" selected={selectedTime}
                                onChange={time => setSelectedTime(time)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption='Time'
                                timeIntervals={30}
                                dateFormat='h:mm aa'
                                required='true' />
                        </BoxDiv>
                    </DataRowFlex>}
                <div style={{ float: 'left', height: '30px', width: '96px' }}>
                    <ButtonComponent
                        bgColor={"rgba(255,255,255,1)"}
                        color={"rgba(67,69,111,1)"}
                        border={'1px solid rgba(67,69,111,1)'}
                        onClick={() => setNewMessageIsOpen(false)}
                    >Cancel
                    </ButtonComponent>

                </div>
                <div style={{ float: 'right', height: '30px', width: '96px', position: 'relative', right: '80%' }}>
                    <ButtonComponent
                        bgColor={"rgba(51,96,255,1)"}
                        color={"rgba(255,255,255,1)"}
                        onClick={() => setPreviewMessage(true)}
                    //type={'submit'}
                    >Continue</ButtonComponent>
                </div>
            </ModalComponent>



            {/* <ModalComponent
                isOpen={previewMessage}
                onRequestClose={() => setPreviewMessage(true)}
                Top={'130px'}
                Left={'240px'}
                Right={'22px'}
                Bottom={'20px'}
                BgColor={'rgba(0, 0, 0, 0)'}>
                <DataRowFlex style={{ marginTop: '30px' }}>
                    <Query>
                        Preview Message
                    </Query>
                    <MessageContainer>
                        <MessageArea readOnly='true' style={{ paddingTop: '10px', paddingLeft: '20px', lineHeight: '20px' }}>
                            {`${values.message}`}
                        </MessageArea>
                        <MessageCount style={{ paddingTop: '15px', paddingLeft: '20px', display: 'flex' }}>
                            {`${values.message.length}`} Characters, {`${values.count}`} of {`${values.messageLimit}`} messages
                            <div style={{ marginLeft: '55%', cursor: 'pointer', color: 'rgba(46,172,164,1)' }}>
                                <span onClick={() => setPreviewMessage(false)}>
                                    Edit
                                </span>
                            </div>
                        </MessageCount>
                    </MessageContainer>
                </DataRowFlex>
                <DataRowFlex style={{ marginBottom: '120px' }}>
                    <Query style={{ paddingTop: '5px', marginBottom: '30px' }}>
                        Recipients
                    </Query>
                    <SelectedRecipients>
                        All customers
                    </SelectedRecipients>
                </DataRowFlex>

                <div style={{ float: 'left', height: '30px', width: '96px' }}>
                    <ButtonComponent
                        bgColor={"rgba(255,255,255,1)"}
                        color={"rgba(67,69,111,1)"}
                        border={'1px solid rgba(67,69,111,1)'}
                        onClick={() => {
                            setPreviewMessage(false);
                            setNewMessageIsOpen(false);
                        }}
                    >Cancel
                    </ButtonComponent>

                </div>
                <div style={{ float: 'right', height: '30px', width: '96px', position: 'relative', right: '80%' }}>
                    <ButtonComponent
                        bgColor={"rgba(46,172,164,1)"}
                        color={"rgba(255,255,255,1)"}>Send</ButtonComponent>
                </div>
            </ModalComponent> */}



        </PageBgWrapperComponent>
    )
}

const styles = {
    tab: {
        minWidth: 50,
        marginRight: 20,
        textTransform: 'none'
    }
};

export default withStyles(styles)(SMSandNotificationComponent);

