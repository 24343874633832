export const addToCheckedList = (payload, type) => ({
    type,
    payload,
});


export const removeFromCheckedList = (payload, type) => ({
    type,
    payload,
});


export const clearCheckedList = (type) => ({
    type
});



export const pinItem = (payload, type) => ({
    type,
    payload,
});

export const unPinItem = (payload, type) => ({
    type,
    payload,
});

