import React from 'react'
import CurrencyFormat from 'react-currency-format'
import Moment from 'react-moment'
import CustomModalComponent from '../../SharedComponents/Modal/ModalComponent'
import { DataRowFlex } from './LoanApplicationDetailElement'

function LoanDetailModal({ show, handleClose, loanData }) {
    return (
        <div>
            <CustomModalComponent
                show={show} handleClose={handleClose}
                hideFooter
                dialogtop={"0"}
                titleText={'View Company'} >


                <DataRowFlex>
                    <div className='label'>
                        Company Name:
                    </div>
                    <div className='value'>
                        {loanData && loanData.company.name}
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Company Address:
                    </div>
                    <div className='value'>
                        {loanData && loanData.company.address}
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Company Email:
                    </div>
                    <div className='value'>
                        {loanData && loanData.company.email}
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Company TIN:
                    </div>
                    <div className='value'>
                        {loanData && loanData.company.tax_id ? loanData && loanData.company.tax_id : 'N/A'}
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Type of Business:
                    </div>
                    <div className='value'>
                        {loanData && loanData.company.business_type}
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Name:
                    </div>
                    <div className='value'>
                        {loanData && `${loanData.company.customer.first_name} ${loanData.company.customer.last_name}`}
                    </div>
                </DataRowFlex>



                <DataRowFlex>
                    <div className='label'>
                        ID Type:
                    </div>
                    <div className='value'>
                        {loanData && loanData.company.customer.identification_type.type}
                    </div>
                </DataRowFlex>



                <DataRowFlex>
                    <div className='label'>
                        ID Number:
                    </div>
                    <div className='value'>
                        {loanData && loanData.company.customer.identification_type.id_number}
                    </div>
                </DataRowFlex>



                <DataRowFlex>
                    <div className='label'>
                        Phone Number:
                    </div>
                    <div className='value'>
                        {loanData && loanData.company.customer.phone_number}
                    </div>
                </DataRowFlex>



                <DataRowFlex>
                    <div className='label'>
                        Gender:
                    </div>
                    <div className='value'>
                        {loanData && loanData.company.customer.gender}
                    </div>
                </DataRowFlex>



                <DataRowFlex>
                    <div className='label'>
                        Account Created:
                    </div>
                    <div className='value'>


                        <Moment format="D MMM YYYY, hh:mm">
                            {loanData && loanData.company.created_at}
                        </Moment>
                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Total Borrowed:
                    </div>
                    <div className='value'>

                        {loanData && (
                            <CurrencyFormat decimalScale={2} fixedDecimalScale
                                value={loanData.company.total_loans}
                                displayType={'text'} thousandSeparator={true} prefix='GHC ' />
                        )}

                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Current Loan Balance:
                    </div>
                    <div className='value'>
                        {loanData && (
                            <CurrencyFormat decimalScale={2} fixedDecimalScale
                                value={loanData.company.loan_balance}
                                displayType={'text'} thousandSeparator={true} prefix='GHC ' />
                        )}

                    </div>
                </DataRowFlex>


                <DataRowFlex>
                    <div className='label'>
                        Status:
                    </div>
                    <div className='value'>
                        {loanData && (
                            <span style={{ color: loanData.company.is_valid_id ? 'rgba(21,149,141,1)' : "rgb(247, 74, 101)" }}>
                                {loanData.company.is_valid_id ? "Verified" : "Unverified"}
                            </span>
                        )}
                    </div>
                </DataRowFlex>



            </CustomModalComponent>
        </div>
    )
}

export default LoanDetailModal
