import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import AccountPage from "./pages/AdminAccount/Account";
import VerifyEmailPage from "./pages/AdminAccount/VerifyEmail";
import HomePage from "./pages/AdminHome/Home";

import GlobalStateContext from "./Contexts/GlobalContext";
import LoanApplicationsPage from "./pages/LoanApplications/LoanApplications";
import CustomersPage from "./pages/Customers/Customers";
import SMSandNotificationPage from "./pages/SMSandNotifications/SMSandNotifications";
import UserManagementPage from "./pages/UserManagement/UserManagement";
// import CustomerViewPage from "./pages/Customers/CustomerView";
import { isMobile, isTablet } from 'react-device-detect';

import { AnimatePresence } from "framer-motion";
import CustomerDetailPage from "./pages/Customers/CustomerDetail";
import LoanApplicationDetailPage from "./pages/LoanApplications/LoanApplicationDetail";
import SMSandNotificationDetailsPage from './pages/SMSandNotifications/SMSandNotificationDetailsPage';
import NewMessagePage from './pages/SMSandNotifications/NewMessagePage';


function App() {

  useEffect(() => {
    setTimeout(() => {
      if (isMobile || isTablet) {
        window.location.href = "https://creditlocus.com";
      }
    }, 3000);

  }, []);
  return (<>
    <Router>
      <AnimatePresence exitBeforeEnter>
        <Switch>

          <GlobalStateContext>
            <Route path="/" exact component={AccountPage} />
            <Route path="/verify-email" exact component={VerifyEmailPage} />
            <Route path="/dashboard" exact component={HomePage} />
            <Route path="/loan-applications" exact component={LoanApplicationsPage} />
            <Route path="/loan-applications/:loan_id" exact component={LoanApplicationDetailPage} />

            <Route path="/customers" exact component={CustomersPage} />
            <Route path="/customers/:customer_id" exact component={CustomerDetailPage} />
            
            <Route path="/sms-and-notifications" exact component={SMSandNotificationPage} />
            <Route path="/new-message" exact component={NewMessagePage} />
            <Route path="/sms-and-notifications/:sms_id" exact component={SMSandNotificationDetailsPage} />

            <Route path="/user-management" exact component={UserManagementPage} />
          </GlobalStateContext>

        </Switch>
      </AnimatePresence>

    </Router>
  </>)

}

export default App;
