import React, { useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { ButtonComponent, FormikErrorComponent, InputFieldComponent } from '../SharedComponents/SharedComponent'
import { AdminAccountWrapper, FormHeader, Header, LogoContainer } from './AdminAccountElement'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CreateMarginBottom, override } from '../SharedComponents/SharedComponentElement';
import { connect } from 'react-redux';
import { startSendMagicLink, setSendMagicLinkSentStatus } from '../../redux/actions/auth/magicLinkAction';
import { PulseLoader as Loading } from 'react-spinners';
import text_logo from '../../assets/text_logo.png';
import { BrowserView, MobileView, TabletView } from 'react-device-detect';



function AdminAccount({ setSendMagicLinkSentStatus, startSendMagicLink, sendMagicLink }) {
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    const formRef = useRef();

    useEffect(() => {
        if (sendMagicLink.responseStatus) {
            return history.push('/verify-email');

        }
        return () => {
            setSendMagicLinkSentStatus(false);
        }
    }, [history, sendMagicLink.responseStatus, setSendMagicLinkSentStatus])




    return (
        <AdminAccountWrapper>
            <MobileView style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'auto', position: 'relative', top: '-30px', margin: 'auto' }}>
                <Header style={{ marginBottom: '40px', marginRight: '50px' }}>
                    <LogoContainer>
                        <img src={text_logo} height='32px' width='150px' alt='Logo'></img>
                    </LogoContainer>
                </Header>
                <FormHeader style={{ textAlign: 'center' }}>
                    This page content is only available on Desktop.
                </FormHeader>
            </MobileView>
            <TabletView style={{ margin: 'auto', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: 'auto', position: 'relative', top: '-30px' }}>
                <Header style={{ marginBottom: '80px' }}>
                    <LogoContainer>
                        <img src={text_logo} height='32px' width='150px' alt='Logo'></img>
                    </LogoContainer>
                </Header>
                <FormHeader style={{ textAlign: 'center' }}>
                    This page content is only available on Desktop.
                </FormHeader>
            </TabletView>
            <BrowserView>
                <LogoContainer style={{ margin: 'auto', position: 'relative', top: '-30px' }}>
                    <img src={text_logo} height='32px' width='150px' alt='Logo'></img>
                </LogoContainer>
                <h6 style={{ color: 'color: rgba(67,69,111,1)', fontSize: '15px', marginTop: '40px', fontWeight: '600' }}>Admin Portal</h6>
                <div>
                    <Formik
                        validateOnChange
                        innerRef={formRef}
                        initialValues={{ email: '' }}
                        onSubmit={
                            data => {
                                if (typeof window !== 'undefined') {
                                    let url = window.location.href;
                                    url = url.slice(0, url.lastIndexOf('/'));
                                    startSendMagicLink({ ...data, app_base_url: url });
                                }
                            }}

                        validationSchema={validationSchema}>
                        {formik => (
                            <>
                                <Form>
                                    <Field name='email' placeholder='Email' id='email' onBlur={formik.handleBlur} type='email' as={InputFieldComponent} />

                                    {(formik.errors.email && formik.touched['email']) && (<FormikErrorComponent style={{ marginTop: '6px' }}>{formik.errors.email}</FormikErrorComponent>)}
                                    {(sendMagicLink.error !== null && !('email' in formik.errors)) && (<FormikErrorComponent>{sendMagicLink.error}</FormikErrorComponent>)}

                                    <CreateMarginBottom marginBottom='15px' />
                                    {/* <ButtonComponent type='submit' marginTop='10px' bgColor='rgb(66, 69, 111)'>
                                    Submit
                                </ButtonComponent> */}

                                    <ButtonComponent
                                        type='submit'
                                        hoverColor={'rgb(50, 52, 89)'}
                                        name='submit_btn' id='submit_btn'
                                        marginTop='10px' height='34px'
                                        bgColor={'rgb(66, 69, 111)'}>

                                        {!sendMagicLink.loading === true ?
                                            "Send Link" :
                                            (<Loading color='white' loading={true} css={override} size={6} />)}
                                    </ButtonComponent>


                                </Form>
                            </>
                        )}</Formik>

                </div>
            </BrowserView>
        </AdminAccountWrapper>
    )
}

const mapStateToProps = (state) => ({
    sendMagicLink: state.sendMagicLink,
});

const mapDispatchToProps = dispatch => ({
    startSendMagicLink: (data) => dispatch(startSendMagicLink(data)),
    setSendMagicLinkSentStatus: (data) => dispatch(setSendMagicLinkSentStatus(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminAccount);