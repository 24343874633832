
import array_move from "../../../data/array_move";
import { GET_ALL_LOANS } from "../../types/types";

const initialState = {
    loading: false,
    responseStatus: false,
    pinned: new Set(),
    data: null,
    error: null
}

const getAllLoansReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_LOANS.SEND_REQUEST:
            return {
                ...state,
                responseStatus: false,
                error: null,
                data: null,
                loading: true
            }

        case GET_ALL_LOANS.RESPONSE_RECEIVED:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
                responseStatus: true
            }

        case GET_ALL_LOANS.RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload
            }

        case GET_ALL_LOANS.REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.error
            }


        //PIN TO TOP
        case GET_ALL_LOANS.PIN_LOAN:
            let start = 0;
            action.payload.forEach(function (value) {
                array_move(state.data.loans.data, value, start);

                state.pinned.add(value);
                start++;
            });
            action.payload.clear();

            return {
                ...state,
            }


        //UNPIN
        case GET_ALL_LOANS.UNPIN_LOAN:
            state.pinned.forEach(function (value, index) {
                array_move(state.data.loans.data, index, value);
                state.pinned.pop();
            })
            // state.pinned = [];

            return {
                ...state,
            }



        default: return state;
    }
}

export default getAllLoansReducer;