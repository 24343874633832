import React from 'react'
import { EmailVerifyWrapper } from './AdminAccountElement'
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';



function EmailVerify() {
    return (
        <EmailVerifyWrapper style={{marginTop: '30px'}}>
            <img src={logo} alt="Logo" />
            <h6>Check your e-mail for a sign-in link</h6>
            <p style={{ width: '40%', textAlign: 'center' }}>
                To keep your account secure, we've sent you an email to confirm its really you.
                Please sign in using the personalize link in your email.
            </p>
            <div>
                <p style={{padding:'0', margin: '0'}}>Haven't received yet?</p>
                <Link to='/'><span>Resend</span></Link>
            </div>
        </EmailVerifyWrapper>
    )
}

export default EmailVerify
