import React, { useContext, useEffect, useState } from 'react'
import NavHeaderComponent from '../../NavHeaderComponent/NavHeaderComponent'
import { MessageCardComponent, PageBgWrapperComponent, PageCardLargeComponent } from '../../SharedComponents/SharedComponent'
import { Tab, Tabs, withStyles } from "@material-ui/core";
import CompanyDetailsTab from '../Tabs/CompanyDetailsTab';
import PersonalDetailsTab from '../Tabs/PersonalDetailsTab';
import LoanDetailsTab from '../Tabs/LoanDetailsTab';
import ActivityTab from '../Tabs/ActivityTab';
import { CustomerDetailFlexChildRight, CustomerDetailTabFlex, TabLabel } from './CustomerDetailElement';
import { connect } from 'react-redux';
import { startGetCustomerDetail } from '../../../redux/actions/customers/getCustomerDetailAction';
import { CustomerDetailContext } from '../../../Contexts/AppContext';
import { ErrorDisplay } from '../../SharedComponents/SharedComponentElement';
import Chat from '../../../assets/chat.png';
import Bell from '../../../assets/bell.png';


function CustomerDetailComponent({ classes, startGetCustomerDetail, getCustomerDetail }) {
    const params = useContext(CustomerDetailContext);


    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (e, newTab) => {
        setSelectedTab(newTab);
    }

    useEffect(() => {
        startGetCustomerDetail(params.customer_id)
    }, [params.customer_id, startGetCustomerDetail]);





    const userOnboardingComplete = () => {
        if ((getCustomerDetail.data !== null) && (getCustomerDetail.data.customers[0].first_name === null
            || getCustomerDetail.data.customers[0].last_name === null
            || getCustomerDetail.data.customers[0].company === null
            || getCustomerDetail.data.customers[0].identification_type === null
            || getCustomerDetail.data.customers[0].gender === null)
        ) {
            return false;
        } else {
            return true;
        }
    }


    return (
        <PageBgWrapperComponent>
            <NavHeaderComponent pageTitle={'Customers'} />
            <PageCardLargeComponent>


                {userOnboardingComplete() === false ? (<>
                    <ErrorDisplay>
                        <div>
                            <h1>No data</h1>
                        </div>
                    </ErrorDisplay>
                </>) : (<>

                    <CustomerDetailTabFlex>
                        <div className='customer-detail-tab-flex-child'>
                            <Tabs classes={{ indicator: classes.indicator }} value={selectedTab} onChange={handleTabChange}>
                                <Tab classes={{ root: classes.tab, selected: classes.selected }} label={<TabLabel color={selectedTab === 0 ? 'rgb(46, 124, 249)' : 'rgb(66, 69, 111)'}>Company Details</TabLabel>} />
                                <Tab classes={{ root: classes.tab, selected: classes.selected }} label={<TabLabel color={selectedTab === 1 ? 'rgb(46, 124, 249)' : 'rgb(66, 69, 111)'}>Personal Details</TabLabel>} />
                                <Tab classes={{ root: classes.tab, selected: classes.selected }} label={<TabLabel color={selectedTab === 2 ? 'rgb(46, 124, 249)' : 'rgb(66, 69, 111)'}>Loan Details</TabLabel>} />
                                <Tab classes={{ root: classes.tab, selected: classes.selected }} label={<TabLabel color={selectedTab === 3 ? 'rgb(46, 124, 249)' : 'rgb(66, 69, 111)'}>Activity</TabLabel>} />
                            </Tabs>


                            <div style={{ marginTop: '40px' }}>
                                {selectedTab === 0 && <CompanyDetailsTab />}
                                {selectedTab === 1 && <PersonalDetailsTab />}
                                {selectedTab === 2 && <LoanDetailsTab />}
                                {selectedTab === 3 && <ActivityTab />}
                            </div>
                        </div>

                        <div className='customer-detail-tab-flex-child'>
                            <CustomerDetailFlexChildRight >
                                <div className='upper-buttons-flex'>
                                    <p style={{cursor:'pointer'}}><span style={{ marginRight: '5px' }}><img src={Chat} alt='message icon' /></span> Send message</p>
                                </div>

                                <div className='message-section'>
                                    <p style={{cursor:'pointer'}}><span><img width="20" height="20" src={Bell} alt='message icon' /></span> Send notification</p>
                                </div>
                            </CustomerDetailFlexChildRight>

                            <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <MessageCardComponent style={{ width: '40px' }} messageType={'error'}>
                                    Collection unsuccessful
                                </MessageCardComponent>
                            </div>
                        </div>


                    </CustomerDetailTabFlex>

                </>)}



            </PageCardLargeComponent>
        </PageBgWrapperComponent>
    )
}

const styles = theme => ({
    tab: {
        minWidth: 50,
        marginRight: 20,
        textTransform: 'none',
    },

    selected: {
        color: 'rgb(66, 69, 111)'
    },

    indicator: {
        backgroundColor: 'rgb(46, 124, 249)',
        height: "2.1px",
        top: "37px"
    }
});


const mapStateToProps = (state) => ({
    getCustomerDetail: state.getCustomerDetail,
});

const mapDispatchToProps = dispatch => ({
    startGetCustomerDetail: (data) => dispatch(startGetCustomerDetail(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CustomerDetailComponent));


// export default withStyles(styles)(CustomerDetailComponent)
