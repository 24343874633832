import React, { useState } from 'react'
import SideBarComponent from '../../components/SidebarComponent/SideBarComponent';
import SMSDetails from '../../components/SMSandNotificationComponent/SMSandNotificationDetails/SMSDetails';
import { SMSDetailsContext } from '../../Contexts/AppContext';

function SMSandNotificationDetailsPage({ match }) {
    const [params] = useState(match.params);

    return (

        <div style={{ display: 'flex' }}>
            <SMSDetailsContext.Provider value={params}>
                <SideBarComponent />
                <SMSDetails />
            </SMSDetailsContext.Provider>
        </div>
    );
}

export default SMSandNotificationDetailsPage
