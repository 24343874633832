import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { FiCalendar, FiClock } from 'react-icons/fi';
import 'react-datepicker/dist/react-datepicker.css';



export const DataRowFlex = styled.div`
display: flex;
flex-direction: row;
margin-top: 30px;
justify-content: space-around;
color: #43456F;

& > div:first-child {
  color: rgb(66, 69, 111);
  font-size: 14px;
  width: 15%;
}

& > div:last-child {
  font-size: 14px;
  width: 70%;
  /* background-color: grey; */
  &>p{
    color: rgba(51,96,255,1);
    margin: 0;
    cursor: pointer;
    padding: 0;
  }
}
`

export const MessageLabel = styled.p`
    height: 16px;
    width: 164px;
    color: #43456F;
    font-size: 15px;
    font-weight: 700;
    margin-right: 30px;
`;


export const MessageArea = styled.textarea`
  height: 150px;
  width:100% !important;
  border-radius: 2px 2px 0 0;
  resize: none;
  background-color: rgba(255,255,255,1);
  border: 1px solid rgba(211,215,230,1);
  opacity: 0.7;
  color: rgba(67,69,111,1);
  font-size: 14px;
  padding: 10px 0 0 20px;

  & + div{
    box-sizing: border-box;
    height: 50px;
    border-radius: 2px;
    background-color: rgba(241,244,255,1);
    border: 1px solid rgba(211,215,230,1);
    border-top: none;
    color: rgba(67,69,111,1);
    font-size: 13px;
    font-weight: 500;
    position: relative;
    top: -5px;
    padding: 0 20px;
    & > span{
        display: inline-block;
        vertical-align: middle;
        line-height: 50px;
    }
  }
`

export const RadioLabel = styled.span`
  height: 16px;
  width: 58px;
  color: rgba(67,69,111,1);
  font-size: 13px;
  font-weight: 500;
  position: relative;
  top: -1px;
`

export const PickDate = styled(DatePicker)`
width: 95%;
height: 35px;
margin-left: 4px;
outline: none;
font-size: 12px;
border: none;
background-color:   "rgb(251, 253, 255)"; 
`;


export const DobWrapper = styled.div`
display: flex;
outline: none;
width: ${({ width }) => (width != null ? width : "100%")};
font-size: 12px;
height: 35px;
border: 1px solid rgba(0, 0, 0, 0.1);
margin-top: ${({ marginTop }) => (marginTop != null ? marginTop : "0px")};
margin-bottom: ${({ marginBottom }) => marginBottom != null ? marginBottom : "0"};
border-radius: 5px;
background-color:  ${({ disabled }) => (disabled ? 'rgb(246, 246, 246)' : "rgb(251, 253, 255)")};
padding-left: 5px;
&.director_dob, 
&.director_dob  div.react-datepicker-wrapper, 
&.director_dob  div  div.react-datepicker__input-container
&.director_dob  div  div.react-datepicker__input-container input {
   width: 80%;
   height: 100%;
}
`;

export const DateIcon = styled(FiCalendar)`
  font-size: 17px;
  color: rgba(0, 0, 0, 0.1);
  position: relative;
  top: 6px;
`;

export const TimeIcon = styled(FiClock)`
  font-size: 17px;
  color: rgba(0, 0, 0, 0.1);
  position: relative;
  top: 8px;
`;