import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


function TableLoadingComponent() {
    return (
        <div style={{ margin: "0 20px 0 20px" }}>
          <table className="table table-borderless">
            <thead>
              <tr>
                {[...Array(4)].map((x, i) =>
                  <th key={`loading1-${i}`} scope="col" style={{ backgroundColor: 'white' }}>
                    <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                      <p>
                        <Skeleton count={1} height={25} />
                      </p>
                    </SkeletonTheme>
                  </th>
                )}
              </tr>
            </thead>
          </table>

          {[...Array(6)].map((x, i) =>
            <div key={`loading2-${i}`} style={{ padding: '0 10px', marginTop: '5px' }}>
              <SkeletonTheme color="rgb(234, 234, 239)" highlightColor="rgb(247, 247, 249)">
                <p>
                  <Skeleton count={1} height={26} />
                </p>
              </SkeletonTheme>
            </div>
          )}
        </div>
    )
}

export default TableLoadingComponent
