import React, { useRef, useState } from 'react'
import NavHeaderComponent from '../../NavHeaderComponent/NavHeaderComponent'
import { ButtonComponent, DropDownComponent, PageBgWrapperComponent, PageCardLargeComponent } from '../../SharedComponents/SharedComponent'
import { DataRowFlex, MessageLabel, MessageArea, RadioLabel, DobWrapper, PickDate, DateIcon, TimeIcon } from './NewMessageComponentElement'
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';



function NewMessageComponent() {
    const formRef = useRef();
    const [date, setDate] = useState(null);

    const selectHeight = 28;

    const validationSchema = Yup.object().shape({
        // email: Yup.string().email('Invalid email').required('Email is required'),
    });
    const options = [
        { value: "all-customers", label: "All Customers" },
        { value: "new-recipient-group", label: "Create new recipient group" },
    ];


    return (
        <PageBgWrapperComponent>
            <NavHeaderComponent pageTitle={'New Message'} />
            <PageCardLargeComponent>

                <Formik
                    validateOnChange
                    innerRef={formRef}
                    initialValues={{ message: '', recipient: '', send_option: '', date: '', time: '' }}
                    onSubmit={
                        data => {
                            console.log(data);
                        }}

                    validationSchema={validationSchema}>
                    {formik => (
                        <Form>
                            <DataRowFlex>
                                <div>
                                    <MessageLabel>Compose Message</MessageLabel>
                                </div>

                                <div>
                                    <MessageArea maxLength='800' placeholder='Compose your message' id='message' required={true} />

                                    <div>
                                        <span>0 Characters 1 of 5 messages</span>
                                    </div>
                                </div>
                            </DataRowFlex>


                            <DataRowFlex>
                                <div>
                                    <MessageLabel>Select Recipients</MessageLabel>
                                </div>

                                <div>
                                    <div style={{ width: '30%' }}>
                                        <Field name='recipient'
                                            value={options ? options.find(option => option.value === formik.values.recipient) : ''}
                                            onChange={value => formik.setFieldValue('recipient', value.value)}
                                            options={options}
                                            onBlur={(e) => { formik.setFieldTouched('recipient'); formik.handleBlur(e) }}
                                            optionStyles={(provided, state) => ({ ...provided, fontSize: 13, padding: 5, color: state.isSelected && 'inherit', backgroundColor: state.isFocused ? "rgb(229, 231, 234)" : null })}
                                            controlStyles={(provided, _) => ({ ...provided, backgroundColor: "white", minHeight: selectHeight, height: selectHeight, border: "1.5px solid rgb(233, 235, 243)" })}
                                            singleValueStyles={(provided, _) => ({ ...provided, fontSize: 13, color: 'rgb(76, 79, 114)' })}
                                            valueContainerStyles={(provided, _) => ({ ...provided, top: -1.5, height: selectHeight })}
                                            indicatorsContainerStyles={(provided, _) => ({ ...provided, height: selectHeight })}
                                            inputStyles={(provided, _) => ({ ...provided, margin: '0px' })}
                                            menuStyles={(provided, _) => ({ ...provided, })}
                                            as={DropDownComponent} />
                                    </div>

                                </div>
                            </DataRowFlex>


                            <DataRowFlex>
                                <div>
                                    <MessageLabel>Sending Options</MessageLabel>
                                </div>

                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>

                                        <div>
                                            <Field
                                                name="send_option"
                                                type='radio'
                                                style={{ marginRight: '5px' }}
                                            />
                                            <RadioLabel>Send now</RadioLabel>
                                        </div>

                                        <div>
                                            <Field
                                                name="send_option"
                                                type='radio'
                                                style={{ marginRight: '5px' }}
                                            /> <RadioLabel>Send later</RadioLabel>
                                        </div>

                                    </div>
                                </div>
                            </DataRowFlex>



                            <DataRowFlex>
                                <div />

                                <div>
                                    <div style={{ display: 'flex', width: '60%', justifyContent: 'space-between' }}>

                                        <div>
                                            <DobWrapper
                                                className='director_dob'>
                                                <PickDate
                                                    wrapperClassName="datepicker"
                                                    id="dob"
                                                    placeholderText="Send Date"
                                                    maxDate={new Date("11-31-2003")}
                                                    // onKeyDown={(date) => console.log(date)}
                                                    selected={date}
                                                    dateFormat='dd/MM/yyyy'
                                                    onChange={date => { setDate(date); return formik.setFieldValue('dob', moment(date).format('YYYY-MM-DD')) }} />
                                                <label htmlFor='dob'><DateIcon /></label>
                                            </DobWrapper>
                                        </div>


                                        <div>
                                            <DobWrapper className='director_dob'>
                                                <PickDate
                                                    wrapperClassName="datepicker"
                                                    id="dob"
                                                    placeholderText="Send Time"
                                                    maxDate={new Date("11-31-2003")}
                                                    // onKeyDown={(date) => console.log(date)}
                                                    selected={date}
                                                    dateFormat='dd/MM/yyyy'
                                                    onChange={date => { setDate(date); return formik.setFieldValue('dob', moment(date).format('YYYY-MM-DD')) }} />
                                                <label htmlFor='dob'><TimeIcon /></label>
                                            </DobWrapper>
                                        </div>

                                    </div>
                                </div>
                            </DataRowFlex>



                            <DataRowFlex>
                                <div style={{ display: 'flex', width: '20%', justifyContent: 'space-between', margin:'50px 0 50px 0' }}>
                                    <div>
                                        <ButtonComponent
                                            width='100px' height='30px'
                                            hoverColor={'rgb(242, 242, 242)'}
                                            name='submit_btn' id='submit_btn'
                                            marginTop='10px'
                                            color={'rgb(91, 92, 129)'}
                                            border={'1px solid rgb(91, 92, 129)'}
                                            bgColor={'white'}>
                                            Cancel
                                        </ButtonComponent>
                                    </div>

                                    <div>
                                        <ButtonComponent
                                            type='submit'
                                            width='100px' height='30px'
                                            hoverColor={'rgb(27, 88, 229)'}
                                            name='submit_btn' id='submit_btn'
                                            marginTop='10px'
                                            onClick={() => null}
                                            bgColor={'rgb(66, 121, 250)'}>
                                            Continue
                                        </ButtonComponent>
                                    </div>
                                </div>

                                <div/>
                            </DataRowFlex>


                        </Form>
                    )}</Formik>








            </PageCardLargeComponent>
        </PageBgWrapperComponent>
    )
}

export default NewMessageComponent
