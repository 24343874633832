import styled from 'styled-components';
import { Modal } from 'react-bootstrap';



export const BootStrapModalComponent = styled(Modal)`

& .modal-dialog{
    top: ${({ dialogtop }) => dialogtop != null ? dialogtop : '10%' }
}
& .modal-content{
    padding: ${({ contentPadding }) => contentPadding != null ? contentPadding : '15px 20px' };

}

& .modal-header{
    border: none;
    padding-top: 0;
    padding-right: 0;

    & .modal-title{
        font-size: ${({ titleFontSize }) => titleFontSize != null ? titleFontSize : '16px' };
        margin-top: ${({ titleMarginTop }) => titleMarginTop != null ? titleMarginTop : '10px' };
    }

    & span, &.close{
        font-weight: lighter;
        margin-top: -102px;
        padding: 0;    
    }
}

& .modal-body{}

& .modal-footer{
    /* margin-top: 50px; */
    margin-top: ${({ footermargintop }) => footermargintop != null ? footermargintop : '50px' };
    border: none;
    justify-content: flex-start;
    & > button{
        background-color: ${({ bgColor }) => bgColor} !important;
    }

    /* & > span{
        margin: 0;
        margin-right: 40px;
        top: 2px;
    } */
    
}

`