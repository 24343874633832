import { GET_ALL_CUSTOMERS } from '../../types/types';

export const startGetAllCustomers = payload => ({
    type: GET_ALL_CUSTOMERS.SEND_REQUEST,
    payload,
    error: null
});


export const getAllCustomersSuccess = response => ({
    type: GET_ALL_CUSTOMERS.RESPONSE_RECEIVED,
    payload: response,
    error: null,
});


export const setGetAllCustomersSentStatus = isSent => ({
    type: GET_ALL_CUSTOMERS.RESPONSE_STATUS,
    payload: isSent,
});


export const getAllCustomersFailure = error => ({
    type: GET_ALL_CUSTOMERS.REQUEST_FAILURE,
    error
});
