import styled from 'styled-components';
import { IoChevronDownOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { Link as LinkRouter } from "react-router-dom";
// import { IoCloseCircleOutline } from "react-icons/io5";


export const ModalComponentWrapper = styled.div`
  width: 420px;
  /* max-width: 450px; */
  background-color: white;
  margin-top: 90px;
  margin-bottom: auto;
  padding: 0 20px 20px 20px;
  border-radius: 5px;
`;

export const ModalComponentHeader = styled.h1`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 5px 0 10px 0;
`;

export const ModalComponentCloseIcon = styled(IoCloseCircleOutline)`
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.3);
  &:hover {
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1 0px;
`;



export const Link = styled(LinkRouter)`
  cursor: pointer;
  color: rgb(66, 121, 250);
  &:hover {
    transition: 0.2s ease-in-out;
  }
`


export const OpenBankStatementText = styled.p`
  cursor: pointer;
  margin: 0;
  padding: 0;
  color: rgb(66, 121, 250);
  &:hover {
    text-decoration: underline;
    transition: 0.2s ease-in-out;
  }
`

export const UpperToolsSection = styled.div`
    /* background-color: greenyellow; */
    padding: 20px 15px 10px 15px;
    display: flex;
    justify-content: space-between;

    &>div{
        display: flex;
        justify-content: space-between;        
    }

    &>div:nth-child(2){
        width: 45%;      
        position: relative;
        top: -5px;
    }
    &>div:first-child{
        width: 10%;      
    }

    &>div:last-child{
        width: 35%;      
    }
`;


// TABLE DESIGN
export const TableComponent = styled.table`
    width: 100%;
    /* table-layout: fixed; */

    & th{
        border: none !important;
        color: rgb(66, 69, 111);
        font-size: 15px;
    }

    & tr{
        font-size: 13.5px;
        color: rgb(114, 116, 147);

         div.ellipses-overflow{
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100px;
        }
    }

    & tr > td:nth-child(6), & tr > td:nth-child(7){
        color: rgb(66, 121, 250);
        >span{
            cursor: pointer;
            

        }
    }

`;





export const ChevronDown = styled(IoChevronDownOutline)`
  font-size: 15px;
  color: rgb(35, 120, 250);
  cursor: pointer;
`;



export const ApprovedButtonAction = styled.div`
box-sizing: border-box;
  height: 43px;
  width: 252px;
  border-radius: 5px;
  background-color: rgba(239,255,254,1);
  border: 1px solid rgba(55,206,197,1);
  color: rgba(55,206,197,1);
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  position: absolute;
  right: 15%;
  top: 10%;
  padding-top: 13px;
`
export const DeclinedButtonAction = styled.div`
box-sizing: border-box;
  height: 43px;
  width: 252px;
  border-radius: 5px;
  background-color: rgba(255,244,246,1);
  border: 1px solid rgba(255,51,102,1);
  color: rgba(255,51,102,1);
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  position: absolute;
  right: 15%;
  top: 10%;
  padding-top: 13px;
`


