import styled from "styled-components";
// import { IoChevronDownOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import { css } from "@emotion/react";
import Modal from 'react-modal';
import { Link as LinkRouter } from "react-router-dom";
// import { IoCloseCircleOutline } from 'react-icons/io5'
import { IoChevronForwardOutline, IoChevronBackOutline, IoChevronDownOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { RiPushpin2Line as Pin } from 'react-icons/ri'




export const CustomButtonComponent = styled.button`
  background-color: ${({ bgColor, disabled, bgColorDisabled }) => !disabled ? bgColor : bgColorDisabled};
  margin-top: ${({ marginTop }) => (marginTop != null ? marginTop : "5px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom != null ? marginBottom : "5px"};
  height: ${({ height }) => (height != null ? height : "38px")};
  width: ${({ width }) => (width != null ? width : "100%")};
  border-radius: 5px;
  border: ${({ border }) => (border != null ? border : "none")};;
  color: ${({ color }) => (color != null ? color : "white")};
  font-weight: ${({ fontWeight }) => (fontWeight != null ? fontWeight : "550")};
  font-size: ${({ fontSize }) => (fontSize != null ? fontSize : "13px")};
  &:hover {
    cursor: pointer;
    background-color: ${({ disabled, hoverColor }) => (disabled ? null : hoverColor)};
  }
`;

export const InputField = styled.input`
  outline: none;
  width: ${({ width }) => (width != null ? width : 'inherit')};
  height: ${({ height }) => (height != null ? height : '35px')};
  font-size: ${({ fontSize }) => fontSize != null ? fontSize : '12px'};
  border: ${({ border }) => (border != null ? border : "1px solid rgba(0, 0, 0, 0.1)")};
  margin-top: ${({ marginTop }) => (marginTop != null ? marginTop : "0px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom != null ? marginBottom : "0"};
  border-radius: 5px;
  background-color: ${({ bgColor }) => (bgColor != null ? bgColor : "rgb(251, 253, 255)")};
  color: ${({ color }) => color};
  padding-left: 7px;
  padding:  ${({ padding }) => padding};

  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  ::placeholder { 
    color: ${({ placeholderColor }) => placeholderColor};
  }

  :-ms-input-placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }

  ::-ms-input-placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }

`;

export const SelectDropdownIcon = styled(IoChevronDownOutline)`
  font-size: ${({ fontSize }) => (fontSize != null ? fontSize : "1em")};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  color: ${({ color }) => (color != null ? color : "#555")};
`;

export const OutlineInputWrapper = styled.div`
  outline: none;
  width: ${({ width }) => (width != null ? width : "50%")};
  font-size: 12px;
  height: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: ${({ marginLeft }) => (marginLeft != null ? marginLeft : "0px")};
  margin-bottom: ${({ marginBottom }) => marginBottom != null ? marginBottom : "0px"};
  background-color: ${({ bgColor }) => (bgColor != null ? bgColor : "white")};
  padding-left: 5px;
  /* position: relative;
  top: -5px; */
  /* left: ${({ left }) => (left != null ? left : "0px")}; */

  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

export const OutlineInputField = styled.input`
  outline: none;
  width: ${({ width }) => (width != null ? width : "100%")};
  /* padding-left: ${({ paddingLeft }) =>
    paddingLeft != null ? paddingLeft : "0"}; */
  /* padding-left: 6px; */
  font-size: 12px;
  border: none;
  border-radius: 5px;
  background-color: ${({ bgColor }) => (bgColor != null ? bgColor : "inherit")};
`;

export const EditIcon = styled(FaRegEdit)`
  color: rgb(61, 133, 250);
  font-size: 17px !important;
  margin-right: 15px;
`;

export const ToggleCheckBox = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(144, 154, 187);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 10.5px;
    width: 10.5px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: rgb(32, 191, 163);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px rgba(32, 191, 163, 0.2);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const Link = styled(LinkRouter)`
  cursor: pointer;
  &:hover {
    transition: 0.2s ease-in-out;
  }
`


export const BackgroundOverlay = styled.div`
  position: fixed; //Needs some fixing, position: absolute causes some issues
  left: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  z-index: 100;
`;

export const NavHeaderComponent = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 5px 10px 0 10px;
  height: 70px;
  margin: 0px 5px 5px 0;
  position: sticky;
  z-index: auto;
  top: 0;
  width: inherit !important;
  border-radius: 5px;
  box-shadow: 0 0 2px -1px;
`;

export const PageCardLargeWrapper = styled.div`
  box-sizing: border-box;
  min-height: 210px;
  width: 100%;
  margin: auto;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ecf3ff;
  margin-top: 40px;
  overflow-y: auto;
`;


export const PageBgWrapper = styled.div`
    width: 84%;    
    background-color: rgb(247, 250, 254);
    position: absolute;
    right: 0;
    min-height: 100vh;
    padding: 20px 20px 10px 20px;
`;

export const FormikError = styled.div`
  color: red;
  font-size: 13px;
`;

export const CreateMarginBottom = styled.div`
   margin-bottom: ${({ marginBottom }) =>
    marginBottom != null ? marginBottom : "12px"};
`

export const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const ModalContainer = styled.div`
display: flex;
flex-direction: column;
`

export const ModalPackage = styled(Modal)`
 position: absolute;
  padding: 10px 20px 20px 40px;
    top: ${({ Top }) => (Top != null ? Top : "125px")};
    left: ${({ Left }) => (Left != null ? Left : "516px")};
    right: ${({ Right }) => (Right != null ? Right : "239px")};
    bottom: ${({ Bottom }) => (Bottom != null ? Bottom : "230px")};
    border: 1px solid rgba(211,215,230,1);
    color: rgba(67,69,111,1);
    font: "FInter";
    border-radius: 5px;
    
    background-color: #FFF;

    &::overlay{
      background-color:${({ BgColor }) => (BgColor != null ? BgColor : 'rgba(0, 0, 0, 0.2)')};

    }
`

export const ErrorDisplay = styled.div`
  color: white;
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:rgba(0,0,0,0.3);
& div{
  /* background: #06c; */
  flex: 0 0 50%;
  text-align: center;
  h1{
    font-size:15px;
    }
  & img{
    width: 100px;
    height: 100px;
  }
}
`;

export const RetryLoading = styled.p`
  color: cornflowerblue;
  cursor: pointer;
  &:active{
    transform: scale(0.9);
  }
`;

export const MessageCardComponentWrapper = styled.div`
  background-color: ${({ message }) => (message === 'success' ? "rgb(239, 254, 254)" : "rgb(254, 244, 246)")};
  border: 1px solid ${({ message }) => (message === 'success' ? "rgb(63, 206, 197)" : "rgb(247, 121, 154)")};
  color: ${({ message }) => (message === 'success' ? "rgb(63, 206, 197)" : "rgb(246, 97, 137)")};
  text-align: center;
  vertical-align: middle;
  line-height: 35px;
  font-size: 10px;
  font-weight: bold;
  min-width: 230px;
  height: 35px;
  border-radius: 5px;
`;


export const BaseMessageComponent = styled.div`
    color:  ${({ color }) => color};
    background-color: ${({ bgColor }) => bgColor};   
    padding: 8px;
    font-size: 13px;
    border-radius: 5px;
`;


export const MessageComponentClose = styled(IoCloseCircleOutline)`
    font-size: 1.2em;
    color: rgba(0,0,0,0.3);
    &:hover{
        color: rgba(0,0,0,0.8);
        cursor: pointer;
    }
`;


const pageNavigationStyles = `
  font-size: 16px;
  color: rgb(35, 120, 250);
  cursor: pointer;
  
  &:hover{
    background: rgb(247, 250, 254);
  }

  &:active{
    color: red;
    transform: scale(0.8);
  }
`

export const GoPrev = styled(IoChevronBackOutline)`
  ${pageNavigationStyles}
`;

export const GoNext = styled(IoChevronForwardOutline)`
  margin-left: 10px;
  ${pageNavigationStyles}
`;


export const PinIcon = styled(Pin)`
    color: #bbb;
    font-size: 16px;
    cursor: pointer;
    &:hover{
      color: #111;
    }
`;