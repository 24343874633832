import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { startGetAdminData } from "../../redux/actions/dashboard/getAdminDataAction";
import {
  SideBarWrapper,
  FadedTop,
  SideBarMenu,
  Spacer,
  LoanApplicationsIcon,
  CustomersIcon,
  UserManagementIcon,
  HomeSideBarMenu,
  SideBarLink,
  ArrowNorthEast,
  FadedTopBoldText,
  FadedTopLightText,
  DashBoardIcon,
  ArrowDropdown,
  SMSIcon,
} from "./SideBarElement";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';




function SideBarComponent({ isSignUp, startGetAdminData, getAdminData }) {

  useEffect(() => {
    startGetAdminData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SideBarWrapper>
      {!isSignUp ? (
        <div>
          <FadedTop isSignUp={isSignUp}>
            {getAdminData.loading ? (<>
              <SkeletonTheme color="rgb(34, 59, 112)" highlightColor="rgb(71, 97, 153)">
                <p>
                  <Skeleton count={2} />
                </p>
              </SkeletonTheme>
            </>) : (<>
              {getAdminData.error !== null ? (
                <>
                  Error
                </>) : (
                <>
                  <FadedTopBoldText>Admin Account
                    <ArrowDropdown />
                  </FadedTopBoldText>
                  <FadedTopLightText>
                    {getAdminData.data && getAdminData.data.admin.email}
                  </FadedTopLightText>
                </>)}

            </>)}



          </FadedTop>


          <HomeSideBarMenu>
            <SideBarLink to="/dashboard" id="dashboard" activeClassName="active">
              <DashBoardIcon /> <span>Dashboard</span>
            </SideBarLink>
            <Spacer />

            <SideBarLink to="/loan-applications" id="loan-applications">
              <LoanApplicationsIcon /> <span>Loan Applications</span>
            </SideBarLink>

            <Spacer />

            <SideBarLink to="/customers" id="customers">
              <CustomersIcon /> <span>Customers</span>
            </SideBarLink>

            <Spacer />

            <SideBarLink to="/sms-and-notifications" id="sms-and-notifications">
              <SMSIcon /> <span>SMS {'&'} Notifications</span>
            </SideBarLink>

            <Spacer />

            <SideBarLink to="/user-management" id="user-management">
              <UserManagementIcon /> <span>User Management</span>
            </SideBarLink>

            <Spacer />

          </HomeSideBarMenu>
        </div>
      ) : (
        <div>
          <FadedTop isSignUp={isSignUp} />
          <SideBarMenu>
            <SideBarLink to="/">
              <ArrowNorthEast /> Get started
            </SideBarLink>
          </SideBarMenu>
        </div>
      )}
    </SideBarWrapper>
  );
}

const mapStateToProps = (state) => ({
  getAdminData: state.getAdminData
});

const mapDispatchToProps = dispatch => ({
  startGetAdminData: (data) => dispatch(startGetAdminData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideBarComponent));
